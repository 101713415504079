import React from 'react';
import { FormFieldData } from 'components/UserForm';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextField, TextFieldProps } from '@mui/material';

interface DateFieldProps {
  readonly fieldItem: FormFieldData;
}

const DateField: React.FC<DateFieldProps> = ({ fieldItem }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
      <DatePicker
        disableFuture
        label={fieldItem.label}
        openTo="year"
        inputFormat="MM/dd/yyyy"
        views={['year', 'month', 'day']}
        value={fieldItem.dateValue}
        onChange={newValue => {
          fieldItem.onDateChange?.(newValue || null);
        }}
        disabled={fieldItem.isDisabled}
        renderInput={(params: TextFieldProps) => {
          const { inputProps, ...other } = params;
          return (
            <TextField
              fullWidth
              required={fieldItem.isRequired}
              variant="standard"
              {...other}
              placeholder={inputProps?.placeholder || 'mm/dd/yyyy'}
              value={inputProps?.value}
              onChange={inputProps?.onChange}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
export default DateField;

import React from 'react';
import { Checkbox, FormControl, Grid, Tooltip } from '@mui/material';
import { FormFieldData } from 'components/UserForm';
import styled from 'styled-components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface SelectFieldProps {
  readonly fieldItem: FormFieldData;
}

const CheckboxField: React.FC<SelectFieldProps> = ({ fieldItem }) => {
  return (
    <FormControl fullWidth required={fieldItem.isRequired}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={2}>
          <Checkbox
            defaultValue={fieldItem.defaultValue}
            disabled={fieldItem.isDisabled}
            placeholder={fieldItem.label}
            required={fieldItem.isRequired}
            value={fieldItem.value}
            onChange={e => {
              fieldItem.onChange?.(String(e.target.checked));
            }}
            inputProps={{ maxLength: fieldItem.maxLength }}
            onBlur={fieldItem.onBlur}
          />
        </Grid>
        <Grid item xs={4}>
          <LabelWrapper>{fieldItem.label}</LabelWrapper>
        </Grid>
        <TooltipWrapper item xs={1}>
          <Tooltip title={fieldItem.tooltip ? fieldItem.tooltip : ''}>
            <HelpOutlineIcon fontSize="small" color="primary" />
          </Tooltip>
        </TooltipWrapper>
      </Grid>
    </FormControl>
  );
};
export default CheckboxField;

const LabelWrapper = styled.div`
  padding-top: 9px;
  margin-left: -5px;
`;

const TooltipWrapper = styled(Grid)`
  padding-top: 7px;
  margin-left: -5px;
`;

import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  position: relative;
  .table,
  .tableSM,
  .tableSX {
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: 16px;
    &.tableFixed {
      .p-datatable-table {
        table-layout: fixed;
      }
    }
    &:not(.autoHeight):not(.fixedToParentHeight)
      .p-virtualscroller
      .p-datatable-wrapper {
      display: flex;
    }
    .p-datatable-wrapper {
      background: lightgray;
    }
    .p-datatable-table {
      width: 100%;
      background: white;
      border-collapse: separate;
      border-spacing: 0;
    }
    .p-datatable-table .p-checkbox {
      margin: 0 auto;
    }
    .p-datatable-table td.p-selection-column {
      text-align: center !important;
    }
    .p-datatable-table {
      tr {
        background: white;
        color: black;
        &.row-expanded,
        &.p-datatable-row-expansion {
          background: #fbfcfe;
        }
        &.p-datatable-row-expansion > td {
          padding: 15px 15px;
        }
        &.row-expanded .channelTagBg {
          background: white;
        }
        &.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
          background: white;
          color: black;
        }
      }
    }
    .p-datatable-table .p-datatable-thead th {
      height: 50px;
      font-weight: 700;
      font-family: inherit;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: white;
      padding: 15px 10px;
      background: #90caf9;
      font-family: inherit;
      text-align: left;
      position: initial;
      letter-spacing: unset;
      &.p-sortable-column {
        background: white;
        box-shadow: none;
        &:focus {
          background: white;
          box-shadow: none;
        }
        &:not(.p-highlight):not(.p-sortable-disabled):hover {
          color: gray;
        }
      }
      &.p-highlight {
        color: gray;
      }
      .p-sortable-column-icon {
        width: 12px;
        height: 12px;
        line-height: 4px;
        text-align: center;
        font-size: 14px;
        color: inherit !important;
      }
      .pi-sort-alt {
        display: none;
      }
      .pi-sort-amount-up-alt:before {
        content: '▲';
      }
      .pi-sort-amount-down:before {
        content: '▼';
      }
    }
    .p-datatable-table .p-datatable-tbody td {
      background: transparent;
      color: black;
      padding: 16px 10px;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      font-family: inherit;
      text-align: left;
      vertical-align: middle;
      position: initial;
      letter-spacing: unset;
      border: 1px solid #f1f1f1;
      &.expandCollapseCell {
        text-align: left;
      }
    }
    .p-datatable-table .p-datatable-emptymessage td {
      text-align: center;
      padding: 25px 10px;
    }
  }
  .tableSM .p-datatable-table .p-datatable-thead th {
    height: 50px;
    padding: 12px 10px;
  }
  .tableSM .p-datatable-table .p-datatable-tbody td,
  .tableSX .p-datatable-table .p-datatable-tbody td {
    padding: 10px;
  }
  .tableSX .p-datatable-table .p-datatable-thead th {
    height: 40px;
    padding: 8px 10px;
  }
  .cellToUpperCase {
    text-transform: uppercase;
  }
  .cellToCapitalize {
    text-transform: capitalize;
  }
  .table.autoHeight {
    height: auto;
    .p-datatable-wrapper {
      min-height: auto;
    }
  }
  /* .fixedToParentHeight {
    .p-datatable-wrapper {
      height: 100%;
    }
  } */
  .stitchedTable .p-rowgroup-footer {
    display: none;
  }
  .p-datatable-wrapper .p-datatable-table .p-rowgroup-header td {
    white-space: nowrap;
    padding: 20px 20px 20px 50px;
  }
  .p-datatable-wrapper .p-rowgroup-header-name {
    display: inline-flex;
    font-family: 'DMSans';
    font-size: 16px;
  }
`;

export const ArrowBottomIcon: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 8L0.803847 0.5L11.1962 0.500001L6 8Z" fill="#848DA3" />
  </svg>
);

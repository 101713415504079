import React from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  GetUserListResponse,
  UpdateAlertStatusRequest,
  UserDataResponse,
} from 'api/SharedTypes';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import NotificationsIcon from '@mui/icons-material/Notifications';
import 'components/NetworkInfo/NetworkInfo.css';
import { UserRole } from 'services/Utils';

interface ActivateMenuProps {
  readonly anchorElActivate: null | HTMLElement;
  readonly isActivateOpen: boolean;
  readonly handleClose: () => void;
  readonly handleNetworkStatusUpdate: (
    networkMemberId: number,
    patientId: number,
    status: UpdateAlertStatusRequest,
  ) => void;
  readonly userData: UserDataResponse;
  readonly item: GetUserListResponse;
  readonly handleUserDelete: (userId: string) => void;
}

const ACTIVATE_ALERT_STATUS: UpdateAlertStatusRequest = {
  alert_receiver_status: 1,
};

const ActivateMenu: React.FC<ActivateMenuProps> = ({
  anchorElActivate,
  isActivateOpen,
  handleClose,
  handleNetworkStatusUpdate,
  handleUserDelete,
  userData,
  item,
}) => {
  const intl = useIntl();

  return (
    <Menu
      id="more-menu-activate"
      aria-labelledby="more-button"
      anchorEl={anchorElActivate}
      open={isActivateOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem onClick={() => handleUserDelete(`${item.id}_${item.name}`)}>
        <ListItemIcon>
          <DeleteIcon fontSize="medium" />
        </ListItemIcon>
        <ListItemText className="networkUserNameText">
          {intl.formatMessage({
            id: I18nKey.USER_NETWORK_ACTION_REMOVE,
          })}
        </ListItemText>
      </MenuItem>
      {item.role !== UserRole.caregiver && (
        <MenuItem
          onClick={() => {
            handleNetworkStatusUpdate(
              item.network_id!,
              userData.id!,
              ACTIVATE_ALERT_STATUS,
            );
          }}
        >
          <ListItemIcon>
            <NotificationsIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText className="networkUserNameText">
            {intl.formatMessage({
              id: I18nKey.USER_NETWORK_ACTION_ACTIVATE_ALERTS,
            })}
          </ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
};
export default ActivateMenu;

import React from 'react';
import Patients from 'pages/Patients';
import { Routes, Route } from 'react-router-dom';
import Caregivers from 'pages/Caregivers';
import Physicians from 'pages/Physicians';
import Nurses from 'pages/Nurses';
import CaseManagers from 'pages/CaseManagers';
import Archived from 'pages/Archived';
import CaregemNavBar from 'components/CaregemNavBar';
import CallRecords from 'pages/CallRecords';
import Others from 'pages/Others';
import Exceptions from 'pages/Exceptions';
import RPMDeviceOrders from 'pages/RPMDeviceOrders/RPMDeviceOrders';
import CallLogs from 'pages/CallLogs';
import SocialWorkers from 'pages/SocialWorkers';
import Dieticians from 'pages/Dieticians';
import MessagesLog from 'pages/MessagesLog';
import BillingLogs from 'pages/BillingLogs';
import RemoteMonitoringReport from 'pages/RemoteMonitoringReports';
import ChangesLog from 'pages/ChangesLog';
import SurveyReport from 'pages/SurveyReport';

interface NavRouterProps {
  readonly handleLogout: () => void;
  readonly username: string;
}

export enum NavRoutes {
  patients = '/patients',
  caregivers = '/caregivers',
  physicians = '/physicians',
  nurses = '/nurses',
  caseManagers = '/casemanagers',
  archived = '/archived',
  socialworkers = '/socialworkers',
  dietician = '/dietician',
  reports = '/reports',
  others = '/others',
  exceptions = '/exceptions',
  callRecords = '/call_records',
  callLogs = '/call_logs',
  changesLog = '/changeslog',
  remoteMonitoringReports = '/remoteMonitoringReports',
  billingReports = '/billingReports',
  surveyreport = '/surveyreport',
  admin = '/',
  messagesLog = '/messages/patient',
  RPMDeviceOrders = '/RPM_Device_Orders',
}

const NavRouter: React.FC<NavRouterProps> = ({ handleLogout, username }) => {
  return (
    <Routes>
      <Route
        path={NavRoutes.patients}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Patients />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.caregivers}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Caregivers />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.physicians}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Physicians />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.nurses}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Nurses />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.caseManagers}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <CaseManagers />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.archived}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Archived />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.others}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Others />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.socialworkers}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <SocialWorkers />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.dietician}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Dieticians />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.exceptions}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <Exceptions />
          </CaregemNavBar>
        }
      />
      <Route
        path={NavRoutes.RPMDeviceOrders}
        element={
          <CaregemNavBar handleLogout={handleLogout} username={username}>
            <RPMDeviceOrders />
          </CaregemNavBar>
        }
      />
      <Route
        path={`${NavRoutes.callRecords}/:userId/:internalId`}
        element={<CallRecords />}
      />
      <Route
        path={`${NavRoutes.callLogs}/:userId/:internalId`}
        element={<CallLogs />}
      />
      <Route path={`${NavRoutes.billingReports}`} element={<BillingLogs />} />
      <Route path={`${NavRoutes.messagesLog}/:id`} element={<MessagesLog />} />
      <Route
        path={`${NavRoutes.changesLog}/:role/:id`}
        element={<ChangesLog />}
      />
      <Route
        path={`${NavRoutes.remoteMonitoringReports}`}
        element={<RemoteMonitoringReport />}
      />
      <Route path={`${NavRoutes.surveyreport}`}
        element={<SurveyReport/>}/>
    </Routes>
  );
};
export default NavRouter;

import React from 'react';
import { Grid } from '@mui/material';
import { FormFieldType } from 'services/Utils';
import { FormFieldObject } from 'components/UserForm';
import DateField from './DateField';
import MaskedField from './MaskedField';
import PlainTextField from './PlainTextField';
import SelectField from './SelectField';
import CheckboxField from './CheckboxField';

interface UserFormFieldsProps {
  readonly formFields: FormFieldObject;
}

export const UserFormFields: React.FC<UserFormFieldsProps> = ({
  formFields,
}) => {
  const filteredFormFields = Object.keys(formFields).filter(
    field => !formFields[field].hide,
  );
  return (
    <Grid container spacing={2}>
      {filteredFormFields.map(field => {
        const fieldItem = formFields[field];
        if (fieldItem.type === FormFieldType.date) {
          return (
            <Grid key={fieldItem.label} item xs={6}>
              <DateField fieldItem={fieldItem} />
            </Grid>
          );
        }
        if (fieldItem.type === FormFieldType.mask) {
          return (
            <Grid key={fieldItem.label} item xs={6}>
              <MaskedField fieldItem={fieldItem} />
            </Grid>
          );
        }
        if (fieldItem.type === FormFieldType.select) {
          return (
            <Grid key={fieldItem.label} item xs={6}>
              <SelectField fieldItem={fieldItem} />
            </Grid>
          );
        }
        if (fieldItem.type === FormFieldType.checkbox) {
          return (
            <Grid key={fieldItem.label} item xs={6}>
              <CheckboxField fieldItem={fieldItem} />
            </Grid>
          );
        }
        return (
          <Grid key={fieldItem.label} item xs={6}>
            <PlainTextField fieldItem={fieldItem} />
          </Grid>
        );
      })}
    </Grid>
  );
};

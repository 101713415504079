import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FormFieldData } from 'components/UserForm';

interface SelectFieldProps {
  readonly fieldItem: FormFieldData;
}

const SelectField: React.FC<SelectFieldProps> = ({ fieldItem }) => {
  return (
    <FormControl fullWidth required={fieldItem.isRequired}>
      <InputLabel id={fieldItem.label} sx={{ ml: -1.5 }}>
        {fieldItem.label}
      </InputLabel>
      <Select
        labelId={fieldItem.label}
        value={fieldItem.value || ''}
        defaultValue={fieldItem.defaultValue}
        required={fieldItem.isRequired}
        variant="standard"
        label={fieldItem.label}
        placeholder={fieldItem.label}
        onChange={e => {
          fieldItem.onChange?.(e.target.value);
        }}
        fullWidth
        disabled={fieldItem.isDisabled}
      >
        {fieldItem.selectOptions
          ? fieldItem.selectOptions.map(option => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
};
export default SelectField;

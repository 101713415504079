import { useEffect, useMemo } from 'react';
import './ChangesLog.css';
import { CircularProgress } from '@mui/material';
import ChangesLogContent from './ChangesLogContent';
import { createApiClient } from 'api/apiClient';
import { useParams } from 'react-router';
import './ChangesLog.css';
import { useQuery } from 'react-query';

const ChangesLog = () => {
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const { id, role } = useParams();

  const {
    isFetching: isChangesLogLoading,
    refetch: getChangesLogList,
    isError,
    data: changesLogResp,
  } = useQuery('get-changes-logs', () => apiClient.getChangeLogs(id!, role!), {
    enabled: false,
  });

  useEffect(() => {
    getChangesLogList();
    return () => {
      controller.abort();
    };
  }, []);

  const changes = useMemo(() => {
    if (changesLogResp) {
      return changesLogResp;
    }
    return [];
  }, [changesLogResp]);

  return (
    <div className="container" style={{ padding: '20px' }}>
      {isChangesLogLoading ? (
        <div className="changesLogListCenterLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="changesLogListCenterLoading">
          <div className="red">Failed to load Changes Log</div>
        </div>
      ) : (
        <ChangesLogContent changesInfo={changes} />
      )}
    </div>
  );
};

export default ChangesLog;

import React, { useContext, useState } from 'react';
// import { ExceptionsListData } from 'api/SharedTypes';
import IconWrapper from 'components/IconWrapper';
import { ExpandedException } from './ExpandedException';
import { UserContext, UserContextState } from 'contexts/userContext';
import { ArrowBottomIcon } from './styles';

interface ExceptionListItemProps {
  readonly exceptionItem: any;
  readonly getExceptionList: () => void;
}

export const ExceptionListItem: React.FC<ExceptionListItemProps> = ({
  exceptionItem,
  getExceptionList,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const onExpandedChange = () => setExpanded(!expanded);
  return (
    <div>
      <div
        style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}
        onClick={onExpandedChange}
      >
        <IconWrapper
          styles={{
            transform: `${expanded ? 'rotate(0deg)' : 'rotate(-90deg)'}`,
            transition: `transform 0.3s linear`,
          }}
          width="12px"
          height="12px"
          icon={<ArrowBottomIcon />}
        />
        <div style={{ marginLeft: 20 }}>
          {`${exceptionItem.user_data.last_name}, ${exceptionItem.user_data.first_name}`}
        </div>
      </div>
      {expanded && (
        <>
          <ExpandedException
            rowItem={exceptionItem}
            exceptionFromSameOrg={
              userContext.userData?.userOrg ===
              Number(exceptionItem.user_data.org_id)
            }
            getExceptionList={getExceptionList}
          />
        </>
      )}
    </div>
  );
};

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import UsersList from '../../components/UsersList';
import { CircularProgress } from '@mui/material';
import isArray from 'lodash/isArray';
import { createApiClient } from 'api/apiClient';
import TabTopButton from 'components/TabTopButton';
import { GetUserListResponse, UpdateUserResponse, User } from 'api/SharedTypes';
import { FormFieldObject } from 'components/UserForm';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  FormFieldType,
  SubmitFormData,
  UserRole,
} from 'services/Utils';
import { UpdateDialog } from 'components/UpdateDialog';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/I18nKeys';
import { ExceptionDialog } from '../../components/ExceptionDialog';
import { NavRoutes } from '../NavRouter';
import {
  sortByName,
  validateEmail,
  validateName,
} from '../../services/utilsFunctions';
import { usePhysicianData } from './usePhysicianData';
import { useMutation } from 'react-query';
import './style.css';
import { UserContextState, UserContext } from 'contexts/userContext';
import { useProviderDegrees } from 'services/useProviderDegrees';

const Physicians: React.FC = () => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const accessToken = localStorage.getItem('access_token');
  const controller = useMemo(() => new AbortController(), []);
  const apiClient = createApiClient(accessToken!, controller);
  const {
    clearForm,
    getFormSubmitData,
    physicianFormActions,
    physicianFormData,
    physicianUserData,
    setPhysicianFormData,
  } = usePhysicianData();

  const providerDegreeOptions = useProviderDegrees();

  const intl = useIntl();

  const [providerId, setProviderId] = useState<number | null>(null);

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [isExceptionDialogOpen, setIsExceptionDialogOpen] =
    useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [existingUserData, setExistingUserData] = useState<User>({});

  const {
    isLoading,
    mutate: getPhysicianList,
    isError,
    data: physicianListResp,
  } = useMutation(
    'physician-list',
    async (loadUpdatedData?: boolean) =>
      apiClient.getPhysiciansList(loadUpdatedData),
    {
      onError: error => {
        console.log('error ', error);
      },
    },
  );

  const { isLoading: isLoadingPhysicianData, mutate: getPhysisicanData } =
    useMutation(
      'get-physician-data',
      async (id: number) => apiClient.getUserData(id, UserRole.provider),
      {
        onSuccess: response => {
          setPhysicianFormData(response);
          physicianFormActions.setPhysicianData(response);
        },
        onError: () => {
          handleUpdateDialogClose();
        },
      },
    );

  const { isLoading: isUpdatingPhysicianData, mutate: updatePhysisicanData } =
    useMutation(
      'update-physician-data',
      async (params: { details: SubmitFormData; id: number }) =>
        apiClient.updateProviderData(params.details, params.id),
      {
        onSuccess: response => {
          if (response.errorCode) {
            setExceptionDialogData(response);
          } else {
            handleUpdateDialogClose();
            getPhysicianList(false);
          }
        },
      },
    );

  const physicians = useMemo(() => {
    if (physicianListResp) {
      if (isArray(physicianListResp)) {
        return sortByName(physicianListResp) as GetUserListResponse[];
      }
    }
    return [];
  }, [physicianListResp]);

  const handleExceptionDialogClose = () => setIsExceptionDialogOpen(false);

  const handleExceptionDialogOpen = () => setIsExceptionDialogOpen(true);

  const setExceptionDialogData = (response: UpdateUserResponse) => {
    handleExceptionDialogOpen();
    setErrorCode(response.errorCode!);
    setExistingUserData(response.existing_data!);
  };

  const handleUpdateDialogOpen = () => {
    setIsUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    clearPhysicianForm();
    setProviderId(null);
    physicianFormActions.setPhysicianData(EMPTY_USER_RESPONSE_DATA);
    setIsUpdateDialogOpen(false);
  };

  const onPhysicianClick = (id: number) => {
    handleUpdateDialogOpen();
    setProviderId(id);
    getPhysisicanData(id);
  };

  const updatePhysician = async () => {
    const data = getFormSubmitData();
    updatePhysisicanData({
      details: { ...data, role: UserRole.physician },
      id: providerId!,
    });
    physicianFormActions.setVerifyEmailChecked(false);
  };

  const fetchPhysicians = useCallback(
    (loadUpdatedData?: boolean) => getPhysicianList(loadUpdatedData),
    [getPhysicianList],
  );

  const clearPhysicianForm = () => {
    clearForm();
    setProviderId(null);
  };

  const getUserName = () => {
    if (
      physicianFormData.firstName &&
      physicianFormData.lastName &&
      !physicianFormData.userName
    ) {
      apiClient
        .getUserName(physicianFormData.firstName, physicianFormData.lastName)
        .then(response => physicianFormActions.setUserName(response));
    }
  };

  const handleRemoteMonitoring = () => {
    physicianFormData.remoteMonitoring === 'Y'
      ? physicianFormActions.setRemoteMonitoring('N')
      : physicianFormActions.setRemoteMonitoring('Y');
  };

  const physicianFields: FormFieldObject = {
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.firstName,
      onChange: value => physicianFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      onBlur: getUserName,
      isError: physicianFormData.firstName
        ? !validateName(physicianFormData.firstName)
        : false,
      errorText:
        !validateName(physicianFormData.firstName) &&
        physicianFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.lastName,
      onChange: value => physicianFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      onBlur: getUserName,
      isError: physicianFormData.lastName
        ? !validateName(physicianFormData.lastName)
        : false,
      errorText:
        !validateName(physicianFormData.lastName) && physicianFormData.lastName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      isDisabled: true,
      value: physicianFormData.userName,
      onChange: value => physicianFormActions.setUserName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_USERNAME,
      }),
    },
    degree: {
      type: FormFieldType.select,
      selectOptions: providerDegreeOptions,
      isRequired: true,
      value: physicianFormData.degree,
      onChange: value => physicianFormActions.setDegree(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DEGREE,
      }),
      isDisabled: isReadOnly,
    },
    email: {
      type: FormFieldType.email,
      isRequired: true,
      value: physicianFormData.email.toLowerCase(),
      onChange: value => physicianFormActions.setEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: physicianFormData.email
        ? !validateEmail(physicianFormData.email)
        : false,
      errorText:
        !validateEmail(physicianFormData.email) && physicianFormData.email
          ? 'Please enter a valid email'
          : '',
      isDisabled: isReadOnly,
    },
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        physicianFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
      hide: true,
    },
    speciality: {
      type: FormFieldType.select,
      isRequired: true,
      value: physicianFormData.speciality,
      onChange: value => physicianFormActions.setSpeciality(value),
      selectOptions: ExportConstants.specialtyOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_SPECIALITY,
      }),
      isDisabled: isReadOnly,
    },
    office_tel_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: physicianFormData.officeTelCountryCode,
      onChange: value => physicianFormActions.setOfficeTelCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    office_tel: {
      type: FormFieldType.mask,
      isRequired: true,
      value: physicianFormData.landline,
      onChange: value => physicianFormActions.setLandline(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE,
      }),
      isDisabled: isReadOnly,
    },
    cell_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: physicianFormData.cellCountryCode,
      onChange: value => physicianFormActions.setCellCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_CELL_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    cell: {
      type: FormFieldType.mask,
      isRequired: true,
      value: physicianFormData.mobile,
      onChange: value => physicianFormActions.setMobile(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_MOBILE_NUMBER,
      }),
      isDisabled: isReadOnly,
    },
    group_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.groupName,
      onChange: value => physicianFormActions.setGroupName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_GROUP_NAME,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    office_addr_1: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.officeAddr1,
      onChange: value => physicianFormActions.setOfficeAddr1(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_OFFICE_ADDRESS_1,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    office_addr_2: {
      type: FormFieldType.text,
      isRequired: false,
      value: physicianFormData.officeAddr2,
      onChange: value => physicianFormActions.setOfficeAddr2(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_OFFICE_ADDRESS_2,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },

    address_city: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.city,
      onChange: value => physicianFormActions.setCity(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADDRESS_CITY,
      }),
      maxLength: 20,
      isDisabled: isReadOnly,
    },

    state: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.stateOptions,
      value: physicianFormData.state,
      onChange: value => physicianFormActions.setState(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_STATE,
      }),
      isDisabled: isReadOnly,
    },

    address_zip: {
      type: FormFieldType.mask,
      isRequired: true,
      mask: '######',
      value: physicianFormData.zipCode,
      onChange: value => physicianFormActions.setZipCode(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ZIP_CODE,
      }),
      isDisabled: isReadOnly,
    },
    licenseNumber: {
      type: FormFieldType.text,
      isRequired: false,
      value: physicianFormData.licenseNumber,
      onChange: value => physicianFormActions.setLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LICENSE_NUMBER,
      }),
      maxLength: 25,
      isDisabled: isReadOnly,
    },
    year_grad_med_school: {
      type: FormFieldType.mask,
      mask: '####',
      isRequired: false,
      value: physicianFormData.graduationYear,
      onChange: value => physicianFormActions.setGraduationYear(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_GRADUATION_YEAR,
      }),
      isDisabled: isReadOnly,
    },
    dea_number: {
      type: FormFieldType.text,
      isRequired: false,
      value: physicianFormData.deaNumber,
      onChange: value => physicianFormActions.setDeaNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DEA_NUMBER,
      }),
      isDisabled: isReadOnly,
    },
    npi: {
      type: FormFieldType.text,
      isRequired: false,
      value: physicianFormData.npi,
      onChange: value => physicianFormActions.setNpi(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_NPI,
      }),
      isDisabled: isReadOnly,
    },
  };

  const physicianUpdateFields: FormFieldObject = {
    ...physicianFields,
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        physicianFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
    },
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.firstName,
      onChange: value => physicianFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      maxLength: 16,
      isError: physicianFormData.firstName
        ? !validateName(physicianFormData.firstName)
        : false,
      errorText:
        !validateName(physicianFormData.firstName) &&
        physicianFormData.firstName
          ? 'Please do not use spaces'
          : '',
      isDisabled: isReadOnly,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.lastName,
      onChange: value => physicianFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      maxLength: 16,
      isError: physicianFormData.lastName
        ? !validateName(physicianFormData.lastName)
        : false,
      errorText:
        !validateName(physicianFormData.lastName) && physicianFormData.lastName
          ? 'Please do not use spaces'
          : '',
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      value: physicianFormData.userName,
      label: intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_USERNAME }),
      isDisabled: true,
    },
  };

  useEffect(() => {
    fetchPhysicians();
    return () => {
      controller.abort();
    };
  }, [controller, fetchPhysicians]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <TabTopButton
        role={UserRole.physician}
        getFormSubmitData={getFormSubmitData}
        clearForm={clearPhysicianForm}
        formFields={physicianFields}
        fetchNewDataList={fetchPhysicians}
        handleRemoteMonitoring={handleRemoteMonitoring}
        remoteMonitoring={physicianFormData.remoteMonitoring!}
      />
      {isLoading ? (
        <div className="physicianListCenterLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="physicianListCenterLoading">
          <div className="red">Failed to Fetch Physician List</div>
        </div>
      ) : (
        <UsersList
          users={physicians}
          routes={NavRoutes.physicians}
          onUserClick={onPhysicianClick}
          fetchNewDataList={fetchPhysicians}
        />
      )}
      <UpdateDialog
        handleClose={handleUpdateDialogClose}
        open={isUpdateDialogOpen}
        userData={physicianUserData}
        role={UserRole.physician}
        handleUpdate={updatePhysician}
        formFields={physicianUpdateFields}
        remoteMonitoring={physicianFormData.remoteMonitoring!}
        handleRemoteMonitoring={handleRemoteMonitoring}
        userDataLoading={isLoadingPhysicianData}
        isUserDataUpdating={isUpdatingPhysicianData}
      />
      <ExceptionDialog
        open={isExceptionDialogOpen}
        handleClose={handleExceptionDialogClose}
        errorCode={errorCode}
        handleAddUserDialogClose={handleUpdateDialogClose}
        enteredFormData={getFormSubmitData()}
        existingUserData={existingUserData}
        role={UserRole.physician}
        fetchNewDataList={fetchPhysicians}
      />
    </div>
  );
};

export default Physicians;

import { createContext, useState } from 'react';
import { UserData } from 'api/SharedTypes';

export type UserContextState = {
  readonly userData: UserData | undefined;
  readonly isReadOnly: boolean;
  updateClick: boolean;
  readonly setLoggedInUserData: (userData: UserData) => void;
  readonly setIsReadOnlyUser: (isReadOnlyUser: boolean) => void;
  setUpdateClick: (value: boolean) => void;
  readonly setPdfByteString: React.Dispatch<React.SetStateAction<string>>;
  readonly pdfByteString: string;
  readonly setConsentName: React.Dispatch<React.SetStateAction<string>>;
  readonly consent_name: string;
  readonly setSignerName: React.Dispatch<React.SetStateAction<string>>;
  readonly signer_name: string;
  readonly setSignerRelationship: React.Dispatch<React.SetStateAction<string>>;
  readonly signer_relationship: string;
  readonly userListingData: any;
  readonly setUserListingData: (flag: any) => void;
};

const initialValue: UserContextState = {
  userData: undefined,
  isReadOnly: false,
  updateClick: false,
  setLoggedInUserData: () => null,
  setIsReadOnlyUser: () => null,
  setUpdateClick: (value: boolean) => false,
  setPdfByteString: () => null,
  pdfByteString: '',
  setConsentName: () => null,
  consent_name: '',
  setSignerName: () => null,
  signer_name: '',
  setSignerRelationship: () => null,
  signer_relationship: '',
  userListingData: [],
  setUserListingData: () => null,
};
export const UserContext = createContext<UserContextState>(initialValue);
UserContext.displayName = 'UserContext';

export const UserProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [updateClick, setUpdateClick] = useState(false);
  const [pdfByteString, setPdfByteString] = useState<string>('');
  const [consent_name, setConsentName] = useState<string>('');
  const [signer_name, setSignerName] = useState<string>('');
  const [signer_relationship, setSignerRelationship] = useState<string>('');

  const setLoggedInUserData = (userData: UserData) => setUserData(userData);

  const setIsReadOnlyUser = (isReadOnlyUser: boolean) =>
    setIsReadOnly(isReadOnlyUser);

  const [userListingData, setUserListingData] = useState<any[]>([]);

  return (
    <UserContext.Provider
      value={{
        isReadOnly,
        userData,
        updateClick,
        setLoggedInUserData,
        setIsReadOnlyUser,
        setUpdateClick,
        pdfByteString,
        setPdfByteString,
        setConsentName,
        consent_name,
        setSignerName,
        signer_name,
        setSignerRelationship,
        signer_relationship,
        userListingData,
        setUserListingData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationComponent = ({
  totalItems,
  itemsPerPage,
  isLoading,
  onPageChange,
  setCurrentPage,
  routes,
}) => {
  const [page, setPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const validatedCount = Number.isInteger(totalPages) ? totalPages : 0;

  const handleChange = (event: any, value: any) => {
    setPage(value);
    onPageChange(value);
    setCurrentPage(value);
  };

  const isValidRoute = routes === '/patients';

  if (isLoading || !isValidRoute) {
    return <></>;
  }

  return (
    <Stack
      spacing={2}
      style={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        padding: 10,
      }}
    >
      <Pagination count={validatedCount} page={page} onChange={handleChange} />
    </Stack>
  );
};

export default PaginationComponent;

import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { UserContext, UserContextState } from 'contexts/userContext';

interface CareGemConsentFormProps {
  pName: string;
  pDob: string | undefined;
  sign: string | undefined;
  // relative: string | null;
  pEmail: string | undefined;
  signer: string | null;
  clientLogo: string | null | undefined;
  consentName: string | null;
  orgName: string | null | undefined;
  userInitial: string;
}

const CareGemConsentForm: React.FC<CareGemConsentFormProps> = ({
  pName,
  pDob,
  sign,
  // relative,
  pEmail,
  signer,
  clientLogo,
  consentName,
  orgName,
  userInitial,
}) => {
  const userContext = useContext<UserContextState>(UserContext);

  const [patientName, SetPatientName] = useState(pName);
  const [patientDob, SetPatientDob] = useState(pDob);
  const [patientRealatedPersonName, setPatientRealatedPersonName] =
    useState(signer);
  const [patientInitial, setPatientInitial] = useState(userInitial);
  // const [relation, setRelation] = useState<string | null>(relative);
  const [patientEmail, SetPatientEmail] = useState(pEmail);
  const [clientLogoCaregem, setClientLogoCaregem] = useState(
    process.env['REACT_APP_ASSET_ENDPOINT'] + '' + clientLogo,
  );
  const [practiceLogoCaregem, setPracticeLogoCaregem] = useState(
    process.env['REACT_APP_ASSET_ENDPOINT'] + 'logo/CareGemHealthLogo.png',
  );

  useEffect(() => {
    // userTypedSignature();
    setPatientInitial(userInitial);
  }, [userInitial]);

  useEffect(() => {
    // setRelation(relative);
    setPatientRealatedPersonName(signer);
  }, [signer]);

  const date = new Date();

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const formattedTodaysDate = `${day}/${month}/${year}`;

  useEffect(() => {
    if (sign) {
      userContext.setPdfByteString(sign);
    } else {
      userContext.setPdfByteString(patientInitial);
    }
    consentName && userContext.setConsentName(consentName);
    patientName && userContext.setSignerName(patientName);
    patientRealatedPersonName &&
      userContext.setSignerRelationship(patientRealatedPersonName);
  }, [sign, patientRealatedPersonName, patientInitial]);

  return (
    <div className="consesntContainer">
      <div className="form-container" id="divToExport">
        <div className="header">
          <img
            className="logo-left"
            src={clientLogoCaregem}
            alt="NephrologySpeaciallistsLogo"
            width={300}
          />
          <img
            className="logo-right"
            src={practiceLogoCaregem}
            alt="CareGemHealthLogo"
            width={180}
          />
        </div>
        <div className="consentFormHeader">
          <p style={{ fontWeight: 650, fontSize: 16 }}>CAREGEM Consent Form</p>
          <p className="paragraph">
            For (Patient): <span className="patientData">{patientName}</span>{' '}
            DOB:<span className="patientData">{patientDob}</span>
          </p>
          <p className="paragraph">
            I, <span className="patientData">{patientName}</span>(Patient/ Power
            of Attorney /Guardian / Responsible party), have received and
            reviewed the document entitled Empowering Coordinated Healthcare by
            CareGem, and the CareGem Privacy Policy. I understand the
            information and instructions in the document, and have had the
            opportunity to have my questions answered about the CareGem program
            (‘Program’).
          </p>
          <p className="paragraph">
            I understand that use of CareGem platform (‘Platform’) is intended
            to only supplement, and not replace my current health treatment
            plan. I agree to continue to use the current systems available to me
            to access care, and not rely on the Program/Platform to deal with
            any urgent or non-urgent health-care needs.
            <br /> I UNDERSTAND THAT THE PLATFORM IS NOT INTENDED FOR EMERGENCY
            USE, AND IS NOT MONITORED 24/7. I WILL CALL 911 FOR IMMEDIATE
            MEDICAL EMERGENCIES.
          </p>
          <p className="paragraph">
            I understand that by enrolling in the Program, my personal health
            information will be shared with CareGem and its affiliate Althea.ai.
            The information collected about me may only be used in accordance
            with the current privacy policy of {orgName?.toUpperCase()} and
            CareGem. I understand that my information will be kept confidential
            and secure on a network compliant with HIPAA, and will only be made
            available to Program staff, and my health-care team: (i) for
            billing, treatment and health care operations; (ii) for improvement
            of the Program or Platform; (iii) evaluation of Program’s impact on
            my care; and (iv) for health research activities (through the use of
            de-identified data).
          </p>
          <p className="paragraph">
            I understand that by signing this consent I agree to the Terms and
            Conditions and End User License Agreement of CareGem, available at{' '}
            <a href="http://www.caregem.com/legal">www.caregem.com/legal</a>.{' '}
            <br />I have reviewed and agree to the Privacy Policy of{' '}
            {orgName?.toUpperCase()} and CareGem.
          </p>
          <p className="paragraph">
            {' '}
            Use of CareGem involves SMS messages for alerts and collaboration. I
            agree to be responsible for Message and Data rates that may apply to
            SMS messages sent to my phone.
          </p>
          <p className="paragraph">
            I have read and understood the information above and consent to
            enroll in the Program.
          </p>
          <p className="paragraph">
            Date:<span className="patientData">{formattedTodaysDate}</span>
            (dd/mm/yyyy)
          </p>
          <p className="paragraph">
            Signature of Patient / Authorized Person :
            <span className="patientData" style={{ marginLeft: 18 }}>
              {/* {!sign ? (
                <>{userInitial}</>
              ) : (
                <img
                  src={sign}
                  width={70}
                  height={35}
                  style={{ marginBottom: 25 }}
                  alt=""
                />
              )} */}
            </span>
          </p>
          <p className="paragraph" style={{ marginTop: sign ? 20 : 20 }}>
            Patient Email address :
            <span className="patientData" style={{ marginLeft: 103 }}>
              {patientEmail}
            </span>
          </p>
          <p className="paragraph">
            (Relationship of Authorized Person)
            <span className="patientData" style={{ marginLeft: 45 }}>
              {patientRealatedPersonName}
            </span>
          </p>
          <p className="paragraph">
            Consent taken digitally by CareGem on behalf of{' '}
            {orgName?.toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CareGemConsentForm;

{
  /* 

<CareGemConsentForm
key={'Caregem-Dev'}
orgName={'Caregem-Dev'}
consentName={'caregem'}
pName={'${patientFullName.firstName} ${patientFullName.lastName}'}
pDob={'consentData.patient_details.dob'}
sign={'PdfValue'}
pEmail={'consentData.patient_details.email'}
signer={'signerFilter'}
clientLogo={'logo/6/logo.png'}
userInitial={'userInitial'}
/> 

*/
}

// <style>
// .main {
//   margin: 0 auto;
//   display: grid;
//   place-items: center;
//   margin-top: 10px;
//   margin-bottom: 50px;
// }
// .container {
//   background-color: rgb(255, 255, 255);
//   width: 640px;
//   display: grid;
//   place-items: center;
//   padding-top: 10px;
//   padding-bottom: 20px;
//   margin-top: 10px;
// }

// .consesntContainer {
//   background-color: rgb(255, 255, 255);
//   width: 640px;
//   height: 980px;
//   display: grid;
//   place-items: center;
//   padding-top: 10px;
//   padding-bottom: 20px;
//   margin-top: 10px;
// }

// .form-container {
//   border: 1px solid black;
//   width: 600px;
//   height: 100%;
//   padding: 20px 50px;
//   box-sizing: border-box;
// }

// .header {
//   display: flex;
//   justify-content: space-between;
// }

// .paragraph {
//   font-size: 0.8em;
//   font-weight: 400;
//   margin-top: 17;
// }

// .patientData {
//   font-weight: 500;
//   border-bottom: 1px solid black;
//   padding: 0px 60px;
// }

// .signature-container {
//   margin-top: 20px;
//   position: relative;
//   width: 100%;
//   padding-bottom: 150px;
//   border: 1px solid lightgray;
//   border-radius: 10px;
// }

// .signature-container .sigCanvas {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }

// .signature-container-text {
//   margin-top: 20px;
//   position: relative;
//   width: 100%;
//   padding-bottom: 50px;
//   border: 1px solid lightgray;
//   border-radius: 10px;
// }

// .consentFormHeader {
//   margin-top: 40px;
// }

// .formField {
//   margin-left: 103px;
// }

// .mobile-consent-modal {
//   width: 90vw;
//   overflow-y: auto;
// }

// @media only screen and (max-width: 768px) {
//   .logo-left {
//     width: 100%;
//   }

//   .consentFormHeader {
//     margin-top: 20px;
//   }

//   .form-container {
//     border: 1px solid black;
//     width: 77vw;
//     height: 100%;
//     padding: 5px 10px;
//     box-sizing: border-box;
//   }

//   .patientData {
//     font-weight: 500;
//     border-bottom: 1px solid black;
//     padding: 0px 10px;
//     margin-left: 0px;
//   }

//   .consesntContainer {
//     margin-top: 0;
//     padding-top: 0;
//     width: 100%;
//   }

//   .header {
//     flex-wrap: wrap;
//   }

//   .formField {
//     margin-left: 0px;
//   }
// }

// </style>

import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import I18nKey from 'lib/I18nKeys';
import { useIntl } from 'react-intl';
import { createApiClient } from 'api/apiClient';
import { useMutation } from 'react-query';
import './style.css';

interface RequestChangePasswordProps {
  readonly setResetPage: (value: boolean) => void;
}

const RequestChangePassword: React.FC<RequestChangePasswordProps> = ({
  setResetPage,
}) => {
  const intl = useIntl();
  const accessToken = localStorage.getItem('access_token');

  const [requestSuccess, setRequestSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [userName, setUserName] = useState<string>('');

  const { isLoading, mutate: resetUserPassword } = useMutation(
    'reset-password',
    async (userName: string) =>
      createApiClient(accessToken!, new AbortController()).resetPassword(
        userName,
      ),
    {
      onSuccess: data => {
        if (data.message && data.statusCode === 200) {
          setRequestSuccess(true);
          setUserName('');
          setError('');
        }
        if (data.statusCode === 400) {
          setError('The user does not exist!');
        }
      },
      onError: () => {
        setError('Failed to reset user password');
      },
    },
  );

  const handleSubmit = () => {
    if (userName) {
      resetUserPassword(userName);
    }
  };

  const handleBack = () => setResetPage(false);

  return (
    <div
      style={{ marginTop: '200px', display: 'flex', justifyContent: 'center' }}
    >
      {requestSuccess ? (
        <span>
          {intl.formatMessage({ id: I18nKey.RESET_PASSWORD_SUCCESS })}
        </span>
      ) : (
        <div style={{ display: 'grid', placeItems: 'center', width: '20vw' }}>
          <TextField
            fullWidth
            variant="standard"
            type={'text'}
            value={userName}
            required
            helperText={error}
            placeholder={intl.formatMessage({
              id: I18nKey.USER_FORM_FIELD_USERNAME,
            })}
            error={!!error}
            name={'username'}
            onChange={e => setUserName(e.target.value)}
          />
          {isLoading && (
            <div className="red">
              <CircularProgress />
            </div>
          )}
          <div className="flexContainer">
            <Button sx={{ marginTop: '10px' }} onClick={handleBack}>
              Back
            </Button>
            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {intl.formatMessage({ id: I18nKey.SEND })}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestChangePassword;

import React, { useContext, useMemo, useState } from 'react';
import { createApiClient } from 'api/apiClient';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { DiagnosisListResponse } from 'api/SharedTypes';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import './ClinicalDiagnosis.css';
import isEmpty from 'lodash/isEmpty';
import { TabName } from 'components/UpdateDialog';
import { CircularProgress } from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';
import { UserContext, UserContextState } from 'contexts/userContext';
import { UserRole } from 'services/Utils';

interface ClinicalDiagnosisProps {
  readonly patientInternalId: number;
  readonly tabName: TabName;
  readonly userRole: string;
}

interface DiagnosisData {
  readonly id: number;
  readonly abbreviated_desc: string;
  readonly assigned_code: string;
}

const columns: GridColDef[] = [
  {
    field: 'abbreviated_desc',
    headerName: 'Diagnoses',
    width: 300,
  },
  {
    field: 'assigned_code',
    headerName: '	ICD10',
  },
];

const EMPTY_SELECTED_DIAGNOSIS: DiagnosisListResponse = {
  category: '',
  code: '',
  abbreviated_desc: '',
};

export const ClinicalDiagnosis: React.FC<ClinicalDiagnosisProps> = ({
  patientInternalId,
  userRole,
  tabName,
}) => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [selectedDiagnosis, setSelectedDiagnosis] =
    useState<DiagnosisListResponse>(EMPTY_SELECTED_DIAGNOSIS);
  const [selectedRows, setSelectedRows] = useState<DiagnosisData[]>([]);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const intl = useIntl();

  const { isLoading: isDiagnosisListLoading, data: diagnosisListResp } =
    useQuery('get-diagnosis-list', () => apiClient.getDiagnosisList(), {
      enabled: userRole === UserRole.patient,
    });

  const {
    isLoading: isPatientDiagnosisLoading,
    refetch: getPatientDiagnosis,
    data: diagnosisDataResp,
  } = useQuery(
    'get-patient-diagnosis',
    () => apiClient.getPatientDiagnoses(patientInternalId),
    {
      enabled: userRole === UserRole.patient,
    },
  );

  const { isLoading: isAddingDiagnosis, mutate: addDiagnosis } = useMutation(
    'add-patient-diagnosis',
    () => apiClient.addDiagnosis(patientInternalId, selectedDiagnosis.code),
    {
      onSuccess() {
        getPatientDiagnosis();
        setSelectedDiagnosis(EMPTY_SELECTED_DIAGNOSIS);
      },
    },
  );

  const { isLoading: isDeletingDiagnosis, mutate: deleteDiagnosis } =
    useMutation(
      'add-patient-diagnosis',
      (diagnosisCode: string) =>
        apiClient.deleteDiagnosis(patientInternalId, diagnosisCode),
      {
        onSuccess() {
          getPatientDiagnosis();
          setSelectedRows([]);
          setSelectionModel([]);
        },
        onError: () => {
          setSelectedRows([]);
          setSelectionModel([]);
        },
      },
    );

  const diagnosisList = useMemo(() => {
    if (diagnosisListResp) {
      return diagnosisListResp;
    }
    return [];
  }, [diagnosisListResp]);

  const diagnosisData = useMemo(() => {
    if (diagnosisDataResp) {
      const diagnosis: DiagnosisData[] = diagnosisDataResp.map(
        (item, index) => ({
          id: index,
          ...item,
        }),
      );
      return diagnosis;
    }
    return [];
  }, [diagnosisDataResp]);

  const addPatientDiagnosis = () => addDiagnosis();

  const deletePatientDiagnosis = () => {
    const diagnosisCodes = selectedRows.map(item => item.assigned_code);
    deleteDiagnosis(diagnosisCodes.join());
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const diagnosis = diagnosisList.find(
      item => item.code === event.target.value,
    );
    setSelectedDiagnosis(diagnosis!);
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const handleSelectedRowsChange = (newSelectionModel: GridSelectionModel) => {
    const selectedRows = diagnosisData.filter((_item, index) =>
      newSelectionModel.includes(index),
    );
    setSelectedRows(selectedRows);
    setSelectionModel(newSelectionModel);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="selectFlexContainer">
        <div>
          <FormControl className="diagnosisSelect">
            <InputLabel id="diagnosis-list-select-label">
              {intl.formatMessage({
                id: I18nKey.PATIENT_DIAGNOSIS_SELECT_LABEL,
              })}
            </InputLabel>
            <Select
              variant="standard"
              labelId="diagnosis-list-select-label"
              id="diagnosis-list-select"
              open={isSelectOpen}
              label={intl.formatMessage({
                id: I18nKey.PATIENT_DIAGNOSIS_SELECT_LABEL,
              })}
              disabled={isReadOnly}
              onClose={handleSelectClose}
              onOpen={handleSelectOpen}
              value={selectedDiagnosis.code}
              onChange={handleSelectChange}
            >
              {isDiagnosisListLoading ? (
                <div className="centerSpinnerContainer">Loading...</div>
              ) : (
                diagnosisList.map(item => (
                  <MenuItem
                    key={item.code}
                    value={item.code}
                  >{`${item.abbreviated_desc} (${item.category})`}</MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </div>
        <div className="selectFlexContainer">
          <div className="button">
            <Button
              variant="contained"
              disabled={
                isEmpty(selectedDiagnosis.code) ||
                isAddingDiagnosis ||
                isReadOnly
              }
              disableElevation
              onClick={addPatientDiagnosis}
            >
              {isAddingDiagnosis ? (
                <CircularProgress size={30} />
              ) : (
                intl.formatMessage({ id: I18nKey.PATIENT_DIAGNOSIS_ADD_LABEL })
              )}
            </Button>
          </div>
          <div className="button">
            <Button
              variant="contained"
              disabled={
                isEmpty(selectedRows) || isDeletingDiagnosis || isReadOnly
              }
              onClick={deletePatientDiagnosis}
              disableElevation
            >
              {isDeletingDiagnosis ? (
                <CircularProgress size={30} />
              ) : (
                intl.formatMessage({
                  id: I18nKey.PATIENT_DIAGNOSIS_REMOVE_LABEL,
                })
              )}
            </Button>
          </div>
        </div>
      </div>
      {isPatientDiagnosisLoading ? (
        <div className="spinnerContainer">
          <CircularProgress />
        </div>
      ) : (
        <div style={{ flexGrow: 1, display: 'flex' }}>
          <DataGrid
            rows={diagnosisData}
            columns={columns}
            checkboxSelection
            pageSize={5}
            autoHeight
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            onSelectionModelChange={handleSelectedRowsChange}
            selectionModel={selectionModel}
          />
        </div>
      )}
    </div>
  );
};

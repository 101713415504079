import React, { useState, useEffect, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import {
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { createApiClient } from 'api/apiClient';
import { useQuery, useMutation } from 'react-query';
import { UserContext } from 'contexts/userContext';
import RPMDeviceOrdersHistory from 'pages/RPMDeviceOrdersHistory/RPMDeviceOrdersHistory';

function RPMDeviceOrders() {
  const [selectedOrgLength, setSelectedOrgLength] = useState(true);
  const [selectedDevicesLength, setSelectedDevicesLength] = useState(true);
  const [deviceNumTextLength, setDeviceNumTextLength] = useState(true);
  const [orderSuccess, setOrderSuccess] = useState<null | boolean>(null);
  const [hide, setHide] = useState<null | boolean>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationData, setConfirmationData] = useState<{
    orgId: number;
    placedOrderData: {
      device_type: string;
      kit_id: string;
      device_provider_name: string;
      no_of_devices: string;
    };
  } | null>(null);

  const { userData } = useContext(UserContext);
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();

  const OrgID = userData?.userOrg;

  const apiClient = createApiClient(accessToken!, controller);

  const { isLoading, refetch, data } = useQuery('get-rpm-device-order', () =>
    apiClient.getRpmDeviceOrder(OrgID as number),
  );

  const PlaceOrder = withStyles({
    root: {
      margin: '10px',
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);

  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [deviceNumText, setDeviceNumText] = useState('');

  const handleSelectORG = (event: SelectChangeEvent) => {
    setSelectedOrg(event.target.value);
    setSelectedDevice(''); // Reset selected device when organization changes
  };

  const handleSelectTOD = (event: SelectChangeEvent) => {
    setSelectedDevice(event.target.value);
  };

  const noOfDeviceInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    let numberValue = parseInt(inputValue);
    if (isNaN(numberValue) || numberValue < 0) {
      numberValue = 0;
    }
    setDeviceNumText(numberValue.toString());
  };

  const { isLoading: isPlacingOrder, mutate: placeOrder } = useMutation(
    'place-order',
    ({
      orgId,
      placedOrderData,
    }: {
      orgId: number;
      placedOrderData: {
        device_type: string;
        kit_id: string;
        device_provider_name: string;
        no_of_devices: string;
      };
    }) => apiClient.postRpmDeviceOrder(orgId, placedOrderData),
    {
      onSuccess: response => {
        // Perform actions after successful order placement
        setSelectedOrg('');
        setSelectedDevice('');
        setDeviceNumText('');
        setOrderSuccess(true);
        setHide(true);
      },
      onError: error => {
        // Handle errors during order placement
        setSelectedOrg('');
        setSelectedDevice('');
        setDeviceNumText('');
        setOrderSuccess(false);
        setHide(true);
      },
    },
  );

  const handlePlaceOrder = () => {
    if (selectedOrg && selectedDevice && deviceNumText && data) {
      const selectedOrgData = data.find(
        item => item.organizationId === selectedOrg,
      );
      if (selectedOrgData) {
        const selectedDeviceObject = selectedOrgData.Device.find(
          device => device.kitId === selectedDevice,
        );
        if (selectedDeviceObject) {
          const { device_type, kitId } = selectedDeviceObject;
          const device_provider_name = selectedOrgData.device_provider_name;
          const no_of_devices = deviceNumText;
          const placedOrderData = {
            device_type,
            kit_id: kitId,
            device_provider_name,
            no_of_devices,
          };
          setConfirmationData({
            orgId: OrgID as number,
            placedOrderData,
          });
          setOpenDialog(true);
        }
      }
    }
  };

  const handleConfirmOrder = () => {
    if (confirmationData) {
      placeOrder(confirmationData);
      setOpenDialog(false);
    }
  };

  const handleCancelOrder = () => {
    setOpenDialog(false);
    setConfirmationData(null);
  };

  useEffect(() => {
    if (Number(deviceNumText) > 0) {
      setDeviceNumTextLength(false);
    }
    if (Number(deviceNumText) === 0) {
      setDeviceNumTextLength(true);
    }

    selectedOrg ? setSelectedOrgLength(false) : setSelectedOrgLength(true);

    selectedDevice
      ? setSelectedDevicesLength(false)
      : setSelectedDevicesLength(true);
  }, [deviceNumText, selectedOrg, selectedDevice]);

  useEffect(() => {
    if (hide !== null) {
      const timer = setTimeout(() => {
        setHide(false);
      }, 2700);
      return () => clearTimeout(timer);
    }
  }, [hide]);

  return (
    <>
      {!isLoading && !isPlacingOrder ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            <FormControl sx={{ m: 3, minWidth: 290 }} size="small">
              <InputLabel>Device Provider Organization</InputLabel>
              <Select
                value={selectedOrg}
                label="Device Provider Organization"
                onChange={handleSelectORG}
              >
                {data &&
                  data.map((item, index) => (
                    <MenuItem key={index} value={item.organizationId}>
                      {item.device_provider_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 4, minWidth: 290 }} size="small">
              <InputLabel>Type of Device</InputLabel>
              <Select
                value={selectedDevice}
                label="Type of Device"
                onChange={handleSelectTOD}
              >
                {data &&
                  data
                    .find(item => item.organizationId === selectedOrg)
                    ?.Device.map((device, index) => (
                      <MenuItem key={index} value={device.kitId}>
                        {device.device_type}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            <TextField
              style={{
                width: 290,
                marginLeft: 11,
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minWidth: 290,
                },
              }}
              type="number"
              id="outlined-basic"
              label={'Number of Device'}
              variant="outlined"
              onChange={noOfDeviceInput}
              value={deviceNumText}
            />

            <PlaceOrder
              style={{
                width: 190,
                marginLeft: 47,
                position: 'relative',
              }}
              variant="contained"
              color="primary"
              disabled={
                deviceNumTextLength ||
                selectedDevicesLength ||
                selectedOrgLength
              }
              onClick={handlePlaceOrder}
            >
              {'Place Order'}
            </PlaceOrder>
          </div>
        </>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
      <div
        style={{
          margin: 'auto',
          width: '47%',
          padding: 47,
        }}
      >
        {hide ? (
          <>
            {orderSuccess === true && (
              <Alert severity="success">ORDER PLACED SUCCESSFULLY</Alert>
            )}
            {orderSuccess === false && (
              <Alert severity="error">PLEASE TRY AGAIN AFTER SOMETIME</Alert>
            )}
          </>
        ) : null}
      </div>
      <Dialog open={openDialog} onClose={handleCancelOrder}>
        <DialogTitle>Confirm Order</DialogTitle>
        <DialogContent>
          Are you sure you want to place this order?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelOrder} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmOrder} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <RPMDeviceOrdersHistory />
    </>
  );
}

export default RPMDeviceOrders;

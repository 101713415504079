import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  GetUserListResponse,
  UpdateAlertStatusRequest,
  UserDataResponse,
} from 'api/SharedTypes';
import 'components/NetworkInfo/NetworkInfo.css';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import PatientNetworkItem from './PatientNetworkItem';
import I18nKey from 'lib/I18nKeys';
import { createApiClient } from 'api/apiClient';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import { TabContext } from 'components/UpdateDialog';
import { useMutation, useQuery } from 'react-query';
import { UserContext, UserContextState } from 'contexts/userContext';

interface PatientNetworkListProps {
  readonly userData: UserDataResponse;
  readonly handleNetworkMemberAlertStatusUpdate?: (
    networkMemberId: number,
    patientId: number,
    status: UpdateAlertStatusRequest,
  ) => void;
  readonly hasChange?: boolean;
  readonly patientNetworkList?: GetUserListResponse[];
  readonly patientNetworkRefresh?: (id: number) => void;
}

const submitData = false;

enum Submit {
  UPDATE = 'update',
  CANCEL = 'cancel',
}

const reducer = (state: boolean, action: Submit) => {
  switch (action) {
    case Submit.CANCEL:
      return false;
    case Submit.UPDATE:
      return true;
    default:
      throw new Error('Unexpected action');
  }
};

const PatientNetwork: React.FC<PatientNetworkListProps> = ({
  userData,
  handleNetworkMemberAlertStatusUpdate,
  patientNetworkList,
  patientNetworkRefresh,
}) => {
  const intl = useIntl();
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<GetUserListResponse[]>([]);
  const [value, setValue] = useState<GetUserListResponse | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [patientNetwork, setPatientNetwork] = useState<GetUserListResponse[]>(
    [],
  );
  const [isSubmit, dispatch] = useReducer(reducer, submitData);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const tabValue = useContext(TabContext);

  const { isFetching } = useQuery(
    'get-provider-options',
    () => apiClient.getProviderOptions(),
    {
      onSuccess: data => {
        const patientNetworkIds = patientNetwork.map(user => user.id) || [];
        const filteredData = data.filter(
          item => !patientNetworkIds.includes(item.id),
        );
        setOptions(sortBy(filteredData, 'name'));
      },
      enabled: tabValue ? true : false,
    },
  );

  const {
    isLoading: isUpdatingPatientNetwork,
    mutate: updatePatientNetworkData,
  } = useMutation(
    'update-patient-network',
    ({
      userId,
      patientNetwork,
    }: {
      userId: number;
      patientNetwork: GetUserListResponse[];
    }) => apiClient.updatePatientNetwork(userId, patientNetwork),
    {
      onSuccess: response => {
        setSnackbarMessage('Network Updated Successfuly!');
        handleSnackBarOpen();
        patientNetworkRefresh?.(userData?.id!);
        dispatch(Submit.CANCEL);
      },
      onError: () => {
        setSnackbarMessage('Network Update Failed!');
        handleSnackBarOpen();
      },
    },
  );

  const handleNetworkUserDelete = (userUniquekey: string) => {
    const filteredList = patientNetwork.filter(
      user => `${user.id}_${user.name}` !== userUniquekey,
    );
    isEqual(patientNetwork, filteredList)
      ? dispatch(Submit.CANCEL)
      : dispatch(Submit.UPDATE);
    setPatientNetwork(filteredList);
  };

  const handleDropdownChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: GetUserListResponse | null,
  ) => {
    const newNetwork = patientNetwork.concat(newValue!);
    isEqual(patientNetwork, newNetwork)
      ? dispatch(Submit.CANCEL)
      : dispatch(Submit.UPDATE);
    setPatientNetwork(newNetwork);

    const newOptions = options.filter(option => option.id !== newValue?.id);
    setOptions(newOptions);
    setValue(null);
    setInputValue('');
  };

  const handleSnackBarOpen = () => setIsSnackbarOpen(true);

  const handleSnackBarClose = () => setIsSnackbarOpen(false);

  const updatePatientNetwork = () => {
    if (window.confirm('Do you want to save changes in the network?')) {
      updatePatientNetworkData({ userId: userData?.id!, patientNetwork });
    }
  };

  useEffect(() => {
    if (patientNetworkList) {
      setPatientNetwork(patientNetworkList);
    }
  }, [patientNetworkList]);

  return (
    <>
      {isEmpty(patientNetwork) ? (
        <div className="noMembers centerLoading">
          {intl.formatMessage({
            id: I18nKey.UPDATE_DIALOG_NETWORK_NO_MEMBERS_LABEL,
          })}
        </div>
      ) : (
        patientNetwork.map(item => (
          <PatientNetworkItem
            key={item.id + item.name}
            item={item}
            handleNetworkMemberAlertStatusUpdate={
              handleNetworkMemberAlertStatusUpdate
            }
            userData={userData!}
            handleNetworkUserDelete={handleNetworkUserDelete}
            isReadOnly={isReadOnly}
          />
        ))
      )}
      <div className="marginTop marginLeft">
        <Autocomplete
          clearOnEscape
          value={value}
          open={open}
          disabled={isReadOnly}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={handleDropdownChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={option =>
            `${option.name || option}${option.degree}${option.specialty}${
              option.id
            }`
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#106a9c',
                width: 370,
              }}
              {...props}
            >
              <div className="optionName">{`${option.name}${
                option.degree ? `,${option.degree}` : ''
              }`}</div>
              <div className="optionRole">
                {option.specialty || startCase(option.role)}
              </div>
            </Box>
          )}
          options={options}
          loading={isFetching}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              sx={{ marginLeft: 3, width: '80%' }}
              label={<FormattedMessage id={I18nKey.NETWORK_INFO_OPTIONS} />}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="marginTop centerLoading">
        <Button
          variant="contained"
          color="inherit"
          sx={{ backgroundColor: 'white' }}
          onClick={updatePatientNetwork}
          disabled={!isSubmit || isUpdatingPatientNetwork || isReadOnly}
        >
          {isUpdatingPatientNetwork ? (
            <CircularProgress size={30} />
          ) : (
            intl.formatMessage({ id: I18nKey.SAVE })
          )}
        </Button>
      </div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleSnackBarClose}
        message={snackbarMessage}
      />
    </>
  );
};
export default PatientNetwork;

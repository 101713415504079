import React, { useState } from 'react';
import {
  GetUserListResponse,
  UpdateAlertStatusRequest,
  UserDataResponse,
} from 'api/SharedTypes';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import ActivateMenu from './ActivateMenu';
import DeactivateMenu from './DeactivateMenu';
import 'components/NetworkInfo/NetworkInfo.css';
import { UserRole } from 'services/Utils';
import startCase from 'lodash/startCase';

interface PatientNetworkItemProps {
  readonly item: GetUserListResponse;
  readonly handleNetworkMemberAlertStatusUpdate?: (
    networkMemberId: number,
    patientId: number,
    status: UpdateAlertStatusRequest,
  ) => void;
  readonly userData: UserDataResponse;
  readonly handleNetworkUserDelete: (user: string) => void;
  readonly isReadOnly: boolean;
}

const PatientNetworkItem: React.FC<PatientNetworkItemProps> = ({
  item,
  handleNetworkMemberAlertStatusUpdate,
  handleNetworkUserDelete,
  userData,
  isReadOnly,
}) => {
  const [anchorElActivate, setAnchorElActivate] = useState<null | HTMLElement>(
    null,
  );
  const [anchorElDeactivate, setAnchorElDeactivate] =
    useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorElActivate(null);
    setAnchorElDeactivate(null);
  };

  const isActivateOpen = Boolean(anchorElActivate);
  const isDeactivateOpen = Boolean(anchorElDeactivate);

  const handleActivateClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElActivate(event.currentTarget);
    setAnchorElDeactivate(null);
  };

  const handleDeactivateClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDeactivate(event.currentTarget);
    setAnchorElActivate(null);
  };

  const handleNetworkStatusUpdate = (
    networkMemberId: number,
    patientId: number,
    status: UpdateAlertStatusRequest,
  ) => {
    handleClose();
    handleNetworkMemberAlertStatusUpdate?.(networkMemberId, patientId, status);
  };

  const handleUserDelete = (userId: string) => {
    handleNetworkUserDelete(userId);
    handleClose();
  };

  return (
    <div key={item.id} className="networkInfoContainer">
      <div className="networkUserContainer">
        {item.role !== UserRole.caregiver ? (
          <div className="networkUserAlertIcon">
            {item.alert_receiver === 1 ? (
              <NotificationsActiveIcon fontSize="medium" />
            ) : (
              <NotificationsOffIcon fontSize="medium" />
            )}
          </div>
        ) : (
          ''
        )}
        <div className="networkUserNameTextContainer">
          <div className="networkUserNameText">{item.name}</div>
          <div className="networkUserSpecialityText">
            {item.role !== UserRole.caregiver
              ? item.specialty
              : startCase(item.role)}
          </div>
        </div>
      </div>
      <IconButton
        id="more-button"
        aria-controls={
          item.alert_receiver === 1
            ? 'more-menu-deactivate'
            : 'more-menu-activate'
        }
        disabled={isReadOnly}
        aria-haspopup="true"
        aria-expanded={isActivateOpen || isDeactivateOpen ? 'true' : undefined}
        onClick={
          item.alert_receiver === 1
            ? handleDeactivateClick
            : handleActivateClick
        }
      >
        <MoreVertIcon fontSize="medium" />
      </IconButton>
      <ActivateMenu
        anchorElActivate={anchorElActivate}
        isActivateOpen={isActivateOpen}
        handleClose={handleClose}
        handleNetworkStatusUpdate={handleNetworkStatusUpdate}
        handleUserDelete={handleUserDelete}
        userData={userData}
        item={item}
      />
      <DeactivateMenu
        anchorElDeactivate={anchorElDeactivate}
        isDeactivateOpen={isDeactivateOpen}
        handleClose={handleClose}
        handleNetworkStatusUpdate={handleNetworkStatusUpdate}
        handleUserDelete={handleUserDelete}
        userData={userData}
        item={item}
      />
    </div>
  );
};
export default PatientNetworkItem;

import React from 'react';
import { Tab } from '@mui/material';

const Divider: React.FC = () => {
  return (
    <Tab
      icon={<div className="divider">|</div>}
      sx={{
        '&.MuiButtonBase-root': {
          margin: -6,
          opacity: 1,
        },
      }}
      disabled
    />
  );
};
export default Divider;

import { DateTime } from 'luxon';

const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const NAME_VALIDATION_REGEX = /^\S*$/;

export const validateEmail = (email: string) => {
  const emailValidationRegex = new RegExp(EMAIL_VALIDATION_REGEX);
  return emailValidationRegex.test(email);
};

export const validateName = (name: string) => {
  const nameValidationRegex = new RegExp(NAME_VALIDATION_REGEX);
  return nameValidationRegex.test(name);
};

export const sortByName = (unsortedArray: any) => {
  const totalCount = 'total_count';
  const sortedArray = unsortedArray[1]?.hasOwnProperty(totalCount)
    ? unsortedArray[0]?.sort(
        (
          a: { first_name: string; last_name: string },
          b: { first_name: string; last_name: string },
        ) =>
          a.last_name === b.last_name
            ? a.first_name.localeCompare(b.first_name)
            : a.last_name.localeCompare(b.last_name),
      )
    : unsortedArray?.sort(
        (
          a: { first_name: string; last_name: string },
          b: { first_name: string; last_name: string },
        ) =>
          a.last_name === b.last_name
            ? a.first_name.localeCompare(b.first_name)
            : a.last_name.localeCompare(b.last_name),
      );
  return sortedArray;
};

const getIsInvalidReason = (dateValue: DateTime) => {
  if (dateValue && dateValue?.invalidReason) {
    return true;
  }
  return false;
};

export const getIsDOBInvalid = (
  dateValue: DateTime | null | undefined,
  isRequired: boolean,
) => {
  if (isRequired) {
    if (!dateValue) {
      return true;
    }
    return getIsInvalidReason(dateValue);
  }
  if (!dateValue) {
    return false;
  }
  return getIsInvalidReason(dateValue);
};

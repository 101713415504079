import React, { useState, useRef, useContext, useEffect } from 'react';
import { UserContext } from 'contexts/userContext';
import { CircularProgress } from '@material-ui/core';
import {
  FileDownloadOutlined,
  RemoveRedEyeOutlined,
} from '@mui/icons-material';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { PatientConsentRecords, UserDataResponse } from 'api/SharedTypes';
import { createApiClient } from 'api/apiClient';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';
import PdfViewer from 'components/PdfViewer';
import CareGemConsentForm from 'pages/ConsentForm/CareGemConsentForm';
import RPMConsentForms from 'pages/ConsentForm/RPMConsentForms';
import ReactDOMServer from 'react-dom/server';
import './CareGemConsentForm.css';
import { SubmitFormData, UserRole } from 'services/Utils';
import { usePatientData } from '../../pages/Patients/usePatientData';

interface ConsentStatusProps {
  patientId: number;
  readonly userData: UserDataResponse;
  handleDialogClose: () => void;
  CGConsentForm?: boolean | string | number;
  RPMConsentForm?: boolean | string | number;
  getPatientList?: (flag: any) => void;
}

interface ConsentData {
  name: string;
  logo: string;
}

const convertUTCDateToLocal = (dateString: string) => {
  const localDateTime = DateTime.fromISO(dateString, { zone: 'utc' }).toLocal();
  const formattedDateTime = localDateTime.toFormat('MMM-dd-yyyy hh:mm a');
  return formattedDateTime;
};

const ConsentStatus: React.FC<ConsentStatusProps> = ({
  patientId,
  userData,
  handleDialogClose,
  CGConsentForm,
  RPMConsentForm,
  getPatientList,
}): JSX.Element => {
  const OrgID = useContext(UserContext)?.userData?.userOrg;
  const [consentRecords, setConsentRecords] = useState<PatientConsentRecords[]>(
    [],
  );
  const {
    patientUserData,
    patientFormActions,
    patientFormData,
    setPatientFormData,
    getFormSubmitData,
    putUpdateFormData,
    clearForm,
  } = usePatientData();
  const [selectedConsentId, setSelectedConsentId] = useState<string>('');
  const [generatedBlobUrl, setGeneratedBlobUrl] = useState<string>('');
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');

  const [consentCG, setConsentCG] = useState<boolean>();
  const [consentCGSigned, setConsentCGSigned] = useState<boolean>();
  const [consentRPM, setConsentRPM] = useState<boolean>();
  const [consentRPMSigned, setConsentRPMSigned] = useState<boolean>();

  const fileInputRef = useRef<Record<number, HTMLInputElement | null>>({});

  const accessToken = localStorage.getItem('access_token');
  const apiClient = createApiClient(accessToken!, new AbortController());
  const [consentData, setConsentData] = useState<
    ConsentData | null | undefined
  >(null);

  useEffect(() => {
    let isMounted: boolean = true;
    const fetchConsentData = async () => {
      try {
        const headers = new Headers();
        headers.append('Authorization', `${accessToken}`);
        const response = await fetch(
          // 'https://caregem.dev1.althea.ai/NonProd/users/checkConsent',
          `${process.env.REACT_APP_API_ENDPOINT}/org/get_org_logo/${OrgID}`,
          {
            method: 'GET',
            headers: headers,
          },
        );
        const data = await response.json();
        if (isMounted) {
          setConsentData(data);
        }
      } catch (error) {
        if (isMounted) {
          console.log(error);
        }
      }
    };
    fetchConsentData();

    return () => {
      isMounted = false;
    };
  }, []);

  const { isLoading } = useQuery(
    'patient-consent-records',
    () => apiClient.getConsentRecords(patientId),
    {
      onSuccess: data => {
        if (data.length > 0) {
          const recordsWithKey: PatientConsentRecords[] = data.map(
            (item: PatientConsentRecords, index: number) => {
              return { ...item, id: index };
            },
          );
          setConsentRecords(recordsWithKey);
        }
      },
    },
  );

  const downloadPdfFile = (base64: string) => {
    const tempLink = document.createElement('a');
    tempLink.href = `data:application/pdf;base64,${base64}`;
    tempLink.setAttribute('download', `consent_${patientId}.pdf`);
    tempLink.click();
  };

  const generateBlob = (base64: string) => {
    const byteCharacters = atob(base64);

    const arrayBuffer = new ArrayBuffer(byteCharacters.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteCharacters.length; i++) {
      uint8Array[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    setGeneratedBlobUrl(url);
    setShowPdf(true);
  };

  const { isLoading: isGetConsentPdfLoading, mutate: getConsentPdf } =
    useMutation(
      'patient-consent-pdf',
      ({
        patientId,
        version,
        consentType,
        consentPath,
        action,
      }: {
        patientId: number;
        version: number;
        consentType: string;
        consentPath: string;
        action: string;
      }) =>
        apiClient.getConsentPdfString(
          patientId,
          version,
          consentType,
          consentPath,
        ),
      {
        onSuccess: (data, variable) => {
          const base64String: string = data.pdfByteStr;
          if (variable.action === 'DOWNLOAD') {
            downloadPdfFile(base64String);
          } else {
            generateBlob(base64String);
          }
        },
      },
    );

  const handleDownloadPdf = async (record: PatientConsentRecords) => {
    const payload = {
      patientId: patientId,
      version: record.version,
      consentType: record.consent_name,
      action: 'DOWNLOAD',
      consentPath: record.consent_path,
    };
    let title = 'Caregem';
    if (record.consent_name.toLowerCase().indexOf('rpm') !== -1) {
      title = 'Rpm';
    }
    setDialogTitle(title);
    setSelectedConsentId(`dwn_${record.id as number}`);
    getConsentPdf(payload);
  };

  const handleShowPdf = async (record: PatientConsentRecords) => {
    const payload = {
      patientId: patientId,
      version: record.version,
      consentType: record.consent_name,
      action: 'VIEW',
      consentPath: record.consent_path,
    };
    let title = 'Caregem';
    if (record.consent_name.toLowerCase().indexOf('rpm') !== -1) {
      title = 'Rpm';
    }
    setDialogTitle(title);
    setSelectedConsentId(`view_${record.id as number}`);
    getConsentPdf(payload);
  };

  const { isLoading: isUploading, mutate: uploadConsentFile } = useMutation(
    'upload-consent-file',
    ({
      patient_internal_id,
      uploadFileData,
    }: {
      patient_internal_id: number;
      uploadFileData: {
        base64: string;
        consent_name: string;
        date: string;
      };
    }) => apiClient.uploadConsentFile(patient_internal_id, uploadFileData),
    {
      onSuccess: () => {
        handleDialogClose();
        getPatientList && getPatientList(false);
      },
      onError: error => {
        handleDialogClose();
      },
    },
  );

  const { isLoading: isUpdatingPatientData, mutate: updatePatientData } =
    useMutation(
      'update-patient-data',
      async (params: {
        // details: { physical_caregem?: string; physical_rpm?: string };
        // id: number;
        details: SubmitFormData;
        id: number;
      }) => {
        return apiClient.updatePatientData(params.details, params.id);
      },
      {
        onSuccess: (response: any) => {},
      },
    );

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result
        ?.toString()
        .replace(/^data:application\/pdf;base64,/, '');
      const patient_internal_id = userData.internal_id ?? 0;
      if (base64String) {
        const consentType =
          consentRecords.find(record => record.id === id)?.consent_name || '';
        const currentDate = DateTime.local().toISODate();

        const uploadFileData = {
          base64: base64String,
          consent_name: consentType,
          date: currentDate,
        };
        uploadConsentFile({ patient_internal_id, uploadFileData });

        const data = putUpdateFormData(patientId);

        // Update physical_caregem or physical_rpm based on consent type

        // const updateDetails: {
        //   physical_caregem?: string;
        //   physical_rpm?: string;
        // } = {};
        if (consentType.toLowerCase() === 'caregem') {
          updatePatientData({
            details: {
              ...data,
              role: UserRole.patient,
              physical_caregem: '1',
            },
            id: patientId!,
          });
          return;
        } else if (consentType.toLowerCase() === 'rpm') {
          updatePatientData({
            details: {
              ...data,
              role: UserRole.patient,
              physical_rpm: '1',
            },
            id: patientId!,
          });
          return;
        }
      }
    };
    reader.readAsDataURL(file);
  };

  const columns: GridColDef[] = [
    {
      field: 'consent_name',
      headerName: 'Consent Type',
      width: 130,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.consent_name === 'caregem') {
          setConsentCG(true);
          return 'Caregem';
        } else if (params.row.consent_name === 'rpm') {
          setConsentRPM(true);
          return 'RPM';
        } else {
          return 'Device Acknowledgement';
        }
      },
    },
    {
      field: 'version',
      headerName: 'Version',
      width: 100,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.version;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      valueGetter: (params: GridValueGetterParams) => {
        const consentType = params.row.consent_name.toLowerCase();
        if (params.row.sign_date) {
          if (consentType === 'caregem') {
            setConsentCGSigned(true);
          }
          if (consentType === 'rpm') {
            setConsentRPMSigned(true);
          }
          return 'Signed';
        }
        if (consentType === 'caregem') {
          setConsentCGSigned(false);
        }
        if (consentType === 'rpm') {
          setConsentRPMSigned(false);
        }
        return 'Not Signed';
      },
    },
    {
      field: 'sign_date',
      headerName: 'Signed on Date',
      width: 170,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.sign_date) {
          return 'N/A';
        }
        return convertUTCDateToLocal(params.row.sign_date);
      },
      sortComparator: (date1, date2) => {
        const localDateTimeA = DateTime.fromISO(date1, {
          zone: 'utc',
        }).toLocal();
        const localDateTimeB = DateTime.fromISO(date2, {
          zone: 'utc',
        }).toLocal();
        return (
          localDateTimeA.get('millisecond') - localDateTimeB.get('millisecond')
        );
      },
    },
    {
      field: 'valid_upto',
      headerName: 'Valid Till Date',
      width: 170,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.valid_upto) {
          return 'N/A';
        }
        return convertUTCDateToLocal(params.row.valid_upto.replace(' ', 'T'));
      },
    },
    {
      field: 'view_consent_link',
      headerName: 'View / Download',
      width: 170,
      renderCell: (params: GridRenderCellParams<PatientConsentRecords>) => {
        const statusNA = params.row.sign_date;

        if (!statusNA) {
          return 'N/A';
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 4,
            }}
            key={params.row.id}
          >
            <IconButton
              onClick={() => handleShowPdf(params.row as PatientConsentRecords)}
            >
              {isGetConsentPdfLoading &&
              selectedConsentId === `view_${params.row.id as number}` ? (
                <CircularProgress size={20} />
              ) : (
                <RemoveRedEyeOutlined sx={{ cursor: 'pointer' }} />
              )}
            </IconButton>
            <IconButton
              onClick={() =>
                handleDownloadPdf(params.row as PatientConsentRecords)
              }
            >
              {isGetConsentPdfLoading &&
              selectedConsentId === `dwn_${params.row.id as number}` ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadOutlined sx={{ cursor: 'pointer' }} />
              )}
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: 'upload_consent',
      headerName: 'Upload',
      width: 170,
      renderCell: (params: GridRenderCellParams<PatientConsentRecords>) => {
        const consentType = params.row.consent_name.toLowerCase();
        const statusNA = params.row.sign_date;

        const handleRPMUpload = () => {
          const caregemSigned = consentCGSigned; // Get the status of Caregem consent

          if (!caregemSigned) {
            alert(
              'Please upload the Caregem consent first before uploading the RPM consent.',
            );
          } else {
            const fileInput = fileInputRef.current[params.row.id as number];
            fileInput?.click();
          }
        };

        if (consentType === 'caregem') {
          // Only show upload button if the consent type is Caregem and not signed
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 4,
              }}
              key={params.row.id}
            >
              {/* <IconButton onClick={printCaregemConsentForm}>
                <PrintOutlinedIcon sx={{ cursor: 'pointer' }} />
              </IconButton> */}

              <input
                type="file"
                accept=".pdf, image/*"
                style={{ display: 'none' }}
                onChange={e => handleFileUpload(e, params.row.id as number)}
                ref={input =>
                  (fileInputRef.current[params.row.id as number] = input) ?? ''
                }
              />

              <IconButton
                onClick={() =>
                  fileInputRef.current[params.row.id as number]?.click()
                }
              >
                <PublishOutlinedIcon sx={{ cursor: 'pointer' }} />
              </IconButton>
            </Box>
          );
        } else if (consentType === 'rpm') {
          // Show upload button for RPM consent
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 4,
              }}
              key={params.row.id}
            >
              {/* <IconButton onClick={printRPMConsentForm}>
                <PrintOutlinedIcon sx={{ cursor: 'pointer' }} />
              </IconButton> */}

              <input
                type="file"
                accept=".pdf, image/*"
                style={{ display: 'none' }}
                onChange={e => handleFileUpload(e, params.row.id as number)}
                ref={input =>
                  (fileInputRef.current[params.row.id as number] = input) ?? ''
                }
              />
              <IconButton onClick={handleRPMUpload}>
                <PublishOutlinedIcon sx={{ cursor: 'pointer' }} />
              </IconButton>
            </Box>
          );
        } else {
          return null; // Hide upload button for other consent types or when signed
        }
      },
    },
  ];

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={30} />
      </Box>
    );
  }

  const printCaregemConsentForm = () => {
    const caregemConsentForm = (
      <CareGemConsentForm
        key={consentData?.name}
        orgName={consentData?.name}
        consentName={'caregem'}
        pName={`${userData.first_name}  ${userData.last_name}`}
        pDob={userData.dob}
        sign={'PdfValue'}
        pEmail={userData.email}
        signer={userData.role}
        clientLogo={consentData?.logo}
        userInitial={'userInitial'}
      />
    );

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      console.error('Failed to open print window');
      return;
    }
    printWindow.document.write(`
      <html>
        <head>
          <title>CareGem Consent Form</title>
          <link rel="stylesheet" type="text/css" href="CareGemConsentForm.css">
          <style>
          .main {
            margin: 0 auto;
            display: grid;
            place-items: center;
            margin-top: 10px;
            margin-bottom: 50px;
          }
          .container {
            background-color: rgb(255, 255, 255);
            width: 640px;
            display: grid;
            place-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            margin-top: 10px;
          }
          
          .consesntContainer {
            background-color: rgb(255, 255, 255);
            width: 640px;
            height: 980px;
            display: grid;
            place-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            margin-top: 10px;
          }
          
          .form-container {
            border: 1px solid black;
            width: 600px;
            height: 100%;
            padding: 20px 50px;
            box-sizing: border-box;
          }
          
          .header {
            display: flex;
            justify-content: space-between;
          }
          
          .paragraph {
            font-size: 0.8em;
            font-weight: 400;
            margin-top: 17;
          }
          
          .patientData {
            font-weight: 500;
            border-bottom: 1px solid black;
            padding: 0px 60px;
          }
          
          .signature-container {
            margin-top: 20px;
            position: relative;
            width: 100%;
            padding-bottom: 150px;
            border: 1px solid lightgray;
            border-radius: 10px;
          }
          
          .signature-container .sigCanvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          
          .signature-container-text {
            margin-top: 20px;
            position: relative;
            width: 100%;
            padding-bottom: 50px;
            border: 1px solid lightgray;
            border-radius: 10px;
          }
          
          .consentFormHeader {
            margin-top: 40px;
          }
          
          .formField {
            margin-left: 103px;
          }
          
          .mobile-consent-modal {
            width: 90vw;
            overflow-y: auto;
          }
          
          @media only screen and (max-width: 768px) {
            .logo-left {
              width: 100%;
            }
          
            .consentFormHeader {
              margin-top: 20px;
            }
          
            .form-container {
              border: 1px solid black;
              width: 77vw;
              height: 100%;
              padding: 5px 10px;
              box-sizing: border-box;
            }
          
            .patientData {
              font-weight: 500;
              border-bottom: 1px solid black;
              padding: 0px 10px;
              margin-left: 0px;
            }
          
            .consesntContainer {
              margin-top: 0;
              padding-top: 0;
              width: 100%;
            }
          
            .header {
              flex-wrap: wrap;
            }
          
            .formField {
              margin-left: 0px;
            }
          }
        </style>          
        </head>
        <body>${ReactDOMServer.renderToString(caregemConsentForm)}</body>
      </html>
    `);

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 470);
  };

  const printRPMConsentForm = () => {
    const caregemConsentForm = (
      <RPMConsentForms
        key={consentData?.name}
        orgName={consentData?.name}
        consentName={'rpm'}
        pName={`${userData.first_name}  ${userData.last_name}`}
        pDob={userData.dob}
        sign={'PdfValue'}
        pEmail={userData.email}
        signer={userData.role}
        clientLogo={consentData?.logo}
        userInitial={'userInitial'}
      />
    );

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      console.error('Failed to open print window');
      return;
    }
    printWindow.document.write(`
      <html>
        <head>
          <title>CareGem Consent Form</title>
          <link rel="stylesheet" type="text/css" href="CareGemConsentForm.css">
          <style>
          .main {
            margin: 0 auto;
            display: grid;
            place-items: center;
            margin-top: 10px;
            margin-bottom: 50px;
          }
          .container {
            background-color: rgb(255, 255, 255);
            width: 640px;
            display: grid;
            place-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            margin-top: 10px;
          }
          
          .consesntContainer {
            background-color: rgb(255, 255, 255);
            width: 640px;
            height: 980px;
            display: grid;
            place-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            margin-top: 10px;
          }
          
          .form-container {
            border: 1px solid black;
            width: 600px;
            height: 100%;
            padding: 20px 50px;
            box-sizing: border-box;
          }
          
          .header {
            display: flex;
            justify-content: space-between;
          }
          
          .paragraph {
            font-size: 0.8em;
            font-weight: 400;
            margin-top: 17;
          }
          
          .patientData {
            font-weight: 500;
            border-bottom: 1px solid black;
            padding: 0px 60px;
          }
          
          .signature-container {
            margin-top: 20px;
            position: relative;
            width: 100%;
            padding-bottom: 150px;
            border: 1px solid lightgray;
            border-radius: 10px;
          }
          
          .signature-container .sigCanvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          
          .signature-container-text {
            margin-top: 20px;
            position: relative;
            width: 100%;
            padding-bottom: 50px;
            border: 1px solid lightgray;
            border-radius: 10px;
          }
          
          .consentFormHeader {
            margin-top: 40px;
          }
          
          .formField {
            margin-left: 103px;
          }
          
          .mobile-consent-modal {
            width: 90vw;
            overflow-y: auto;
          }
          
          @media only screen and (max-width: 768px) {
            .logo-left {
              width: 100%;
            }
          
            .consentFormHeader {
              margin-top: 20px;
            }
          
            .form-container {
              border: 1px solid black;
              width: 77vw;
              height: 100%;
              padding: 5px 10px;
              box-sizing: border-box;
            }
          
            .patientData {
              font-weight: 500;
              border-bottom: 1px solid black;
              padding: 0px 10px;
              margin-left: 0px;
            }
          
            .consesntContainer {
              margin-top: 0;
              padding-top: 0;
              width: 100%;
            }
          
            .header {
              flex-wrap: wrap;
            }
          
            .formField {
              margin-left: 0px;
            }
          }
        </style>          
        </head>
        <body>${ReactDOMServer.renderToString(caregemConsentForm)}</body>
      </html>
    `);

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 470);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isUploading ? (
        <>
          <div className="centerLoading">
            <CircularProgress />
          </div>
        </>
      ) : (
        <>
          <DataGrid
            columns={columns}
            rows={consentRecords}
            pageSize={5}
            autoHeight
            disableSelectionOnClick
            checkboxSelection={false}
          />
          <Dialog
            open={showPdf}
            fullWidth={true}
            maxWidth="lg"
            onClose={() => setShowPdf(false)}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                flexDirection: 'row-reverse',
              }}
            >
              <DialogActions>
                <Button
                  onClick={() => setShowPdf(false)}
                  color="primary"
                  style={{ fontSize: '16px' }}
                >
                  Close
                </Button>
              </DialogActions>
              <DialogTitle>{dialogTitle} Consent</DialogTitle>
            </div>

            {generatedBlobUrl && <PdfViewer fileUrl={generatedBlobUrl} />}
          </Dialog>

          {/* <>
            {userData.physical_caregem === '1' ||
            userData.physical_caregem === '0' ? (
              <Button
                style={{
                  margin: 17,
                }}
                variant="contained"
                onClick={printCaregemConsentForm}
              >
                PRINT CAREGEM CONSENT
              </Button>
            ) : null}

            {userData.physical_rpm === '1' || userData.physical_rpm === '0' ? (
              <Button
                style={{
                  margin: 17,
                }}
                variant="contained"
                onClick={printRPMConsentForm}
              >
                PRINT RPM CONSENT
              </Button>
            ) : null}
          </> */}
          <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
            {CGConsentForm !== 'NA' ? (
              <Button
                style={{
                  width: 220,
                }}
                variant="contained"
                onClick={printCaregemConsentForm}
              >
                PRINT CAREGEM CONSENT
              </Button>
            ) : null}

            {RPMConsentForm !== 'NA' ? (
              <Button
                style={{
                  width: 220,
                }}
                variant="contained"
                onClick={printRPMConsentForm}
              >
                PRINT RPM CONSENT
              </Button>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default ConsentStatus;

import React, { useEffect, useMemo, useState } from 'react';
import RemoteMonitoringData from './RemoteMonitoringData';
import {
  CircularProgress,
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
  TextFieldProps,
} from '@mui/material';
import './RemoteMonitoring.css';
import { createApiClient } from 'api/apiClient';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import range from 'lodash/range';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';
import {
  ProvidersRemoteMonitoringDetails,
  AllPatientsRemoteMonitoringDetails,
} from 'api/SharedTypes';

interface ProviderData {
  label: string;
  value: string;
}

const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const getMonths = (
  currentMonth: number,
  startDateMonth: number,
  endDateMonth: number,
  startDateYear: number,
  endDateYear: number,
) => {
  if (
    startDateMonth === -1 ||
    endDateMonth === -1 ||
    startDateYear === -1 ||
    endDateYear === -1
  ) {
    let secondLastMonth = (currentMonth + monthsLength - 2) % monthsLength;
    let lastMonth = (currentMonth + monthsLength - 1) % monthsLength;
    let nextMonth = (currentMonth + 1) % monthsLength;
    return [
      monthNames[secondLastMonth],
      monthNames[lastMonth],
      monthNames[currentMonth],
      monthNames[nextMonth],
    ];
  }
  const startDateMonthIndex = startDateMonth - 1;
  const endDateMonthIndex = endDateMonth - 1;

  if (startDateYear !== endDateYear) {
    const monthIndexRange = range(12);
    const startRange = monthIndexRange.filter(
      index => index >= startDateMonthIndex,
    );
    const endRange = monthIndexRange.filter(
      index => index <= endDateMonthIndex,
    );

    return [...startRange, ...endRange].map(index => monthNames[index]);
  }
  const monthRange = range(startDateMonthIndex, endDateMonthIndex + 1);
  return monthRange.map(index => monthNames[index]);
};

let monthsLength = monthNames.length;

const ERROR_MESSAGE = 'Failed to fetch Remote Monitoring details';

const RemoteMonitoringReport = () => {
  let currentMonth = new Date().getMonth();

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);
  const intl = useIntl();

  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<ProviderData | null>(null);
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [providerRMDetails, setProviderRMDetails] =
    useState<ProvidersRemoteMonitoringDetails | null>(null);
  const [allRMDetails, setAllRMDetails] =
    useState<AllPatientsRemoteMonitoringDetails | null>(null);

  const {
    isLoading: isProviderRMReportDetailsLoading,
    mutate: getProviderRMReportDetails,
    isError: isProviderRMReportDetailsError,
  } = useMutation(
    'get-provider-rm-report-details',
    ({
      startDateString,
      endDateString,
      value,
    }: {
      startDateString: string;
      endDateString: string;
      value: string;
    }) =>
      apiClient.getProviderRMReportDetails(
        value,
        startDateString,
        endDateString,
      ),
    {
      onSuccess: report => {
        setProviderRMDetails(report[0]);
        setAllRMDetails(null);
      },
    },
  );

  const {
    isLoading: isAllProviderRMReportDetailsLoading,
    mutate: getAllProviderRMReportDetails,
    isError: isAllProviderRMReportDetailsError,
    data: AllRPMData,
  } = useMutation(
    'get-all-provider-rm-report-details',
    ({
      startDateString,
      endDateString,
    }: {
      startDateString: string;
      endDateString: string;
    }) =>
      apiClient.getAllProviderRMReportDetails(startDateString, endDateString),
    {
      onSuccess: report => {
        setAllRMDetails(report);
        setProviderRMDetails(null);
      },
    },
  );

  useEffect(() => {
    function flatten(arr) {
      return arr.reduce((flatArr, current) => {
        return flatArr.concat(
          Array.isArray(current) ? flatten(current) : current,
        );
      }, []);
    }
    if (typeof AllRPMData !== 'undefined' && AllRPMData !== null) {
      const flattenedData = flatten(AllRPMData);
      setAllRMDetails(flattenedData);
    } else {
      console.log('AllRPMData is not defined or is null.');
    }
  }, [AllRPMData]);

  const { isFetching: isBillingProvidersLoading, data: billingPrvList } =
    useQuery('get-rm-billing-providers', () =>
      apiClient.getRMBillingProviders(),
    );

  const options = useMemo(() => {
    if (billingPrvList) {
      let providersData = billingPrvList
        .filter(
          provider =>
            provider.role !== 'caregiver' && provider.remote_monitoring === 'Y',
        )
        .map<ProviderData>(eachProvider => ({
          label: eachProvider.name,
          value: eachProvider.id.toString(),
        }));
      return [...providersData, { label: 'All', value: 'All' }];
    }
    return [];
  }, [billingPrvList]);

  const startDateMonth = startDate?.month || -1;
  const endDateMonth = endDate?.month || -1;
  const endDateYear = endDate?.year || -1;
  const startDateYear = startDate?.year || -1;

  const months = getMonths(
    currentMonth,
    startDateMonth,
    endDateMonth,
    startDateYear,
    endDateYear,
  );
  const janIndex = months.indexOf('JAN');

  const headings = [
    'PATIENT NAME',
    'PROVIDER NAME',
    'DEVICE DETAILS',
    ...months.map((month, index) => {
      if (endDateYear === -1 || janIndex === -1 || janIndex === 0) return month;
      if (index < janIndex) return `${month} ${endDateYear - 1}`;
      return `${month} ${endDateYear}`;
    }),
  ];

  const handleProviderChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ProviderData | null,
  ) => {
    if (newValue === null) {
      setProviderRMDetails(null);
      setAllRMDetails(null);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (!value) return;
    // Default to the last three months if start date is not selected
    const defaultStartDate = DateTime.local()
      .minus({ days: 90 })
      .startOf('day');

    const startDateString =
      startDate?.startOf('day').toString() || defaultStartDate.toString();

    const s = new Date(startDateString);

    const defaultEndDate = DateTime.local().set({
      hour: 23,
      minute: 59,
      second: 0,
    });

    const endDateString =
      endDate?.set({ hour: 23, minute: 59, second: 0 }).toString() ||
      defaultEndDate.toString();

    const e = new Date(endDateString);

    if (startDateString) {
      if (!endDateString) {
        return;
      }
    }
    if (endDateString) {
      if (!startDateString) {
        return;
      }
    }
    if (value?.value !== 'All') {
      getProviderRMReportDetails({
        value: value?.value!,
        startDateString:
          startDateString === 'Invalid DateTime' ? '' : s?.toISOString(),
        endDateString:
          endDateString === 'Invalid DateTime' ? '' : e?.toISOString(),
      });
    } else {
      getAllProviderRMReportDetails({
        startDateString:
          startDateString === 'Invalid DateTime' ? '' : s?.toISOString(),
        endDateString:
          endDateString === 'Invalid DateTime' ? '' : e?.toISOString(),
      });
    }
    return () => {
      controller.abort();
    };
  }, [value, startDate, endDate]);

  const showRemoteMonitoringReportTable = useMemo(
    () =>
      (!isEmpty(get(providerRMDetails, 'patient_details', null)) ||
        !isEmpty(get(allRMDetails, 'patient_details', null))) &&
      !isAllProviderRMReportDetailsLoading &&
      !isProviderRMReportDetailsLoading &&
      value !== null,
    [
      providerRMDetails,
      allRMDetails,
      isAllProviderRMReportDetailsLoading,
      isProviderRMReportDetailsLoading,
      value,
    ],
  );

  const showSelectProviderDateMessage = useMemo(() => value === null, [value]);

  const showNoReportsMessage = useMemo(
    () =>
      !isAllProviderRMReportDetailsLoading &&
      !isProviderRMReportDetailsLoading &&
      !isAllProviderRMReportDetailsError &&
      !isProviderRMReportDetailsError,
    [
      isAllProviderRMReportDetailsLoading,
      isProviderRMReportDetailsLoading,
      isAllProviderRMReportDetailsError,
      isProviderRMReportDetailsError,
    ],
  );

  const showErrorMessage = useMemo(
    () =>
      value !== null &&
      !isAllProviderRMReportDetailsLoading &&
      !isProviderRMReportDetailsLoading &&
      (isAllProviderRMReportDetailsError || isProviderRMReportDetailsError),
    [
      value,
      isAllProviderRMReportDetailsLoading,
      isProviderRMReportDetailsLoading,
      isAllProviderRMReportDetailsError,
      isProviderRMReportDetailsError,
    ],
  );

  return (
    <>
      <button className="printButton" onClick={() => window.print()}>
        Print
      </button>
      <div className="reportSelectionHeaderContainer">
        <Autocomplete
          open={open}
          sx={{ width: '50%' }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={value}
          onChange={handleProviderChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={option => option.label}
          options={options}
          loading={isBillingProvidersLoading}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={intl.formatMessage({
                id: I18nKey.REMOTE_MONITORING_SELECT_PROVIDERS,
              })}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isBillingProvidersLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
          <DatePicker
            disableFuture
            label="Start Date"
            value={startDate}
            onChange={value => setStartDate(value)}
            renderInput={(params: TextFieldProps) => <TextField {...params} />}
            minDate={endDate?.minus({ month: 11 }).startOf('month') || null}
          />
          <DatePicker
            disableFuture
            label="End Date"
            value={endDate}
            onChange={value => setEndDate(value)}
            renderInput={(params: TextFieldProps) => <TextField {...params} />}
            maxDate={startDate?.plus({ month: 11 }).endOf('month') || null}
          />
        </LocalizationProvider>
      </div>

      {allRMDetails ? (
        <div id="remoteMonitorReport">
          <table>
            <thead>
              <tr className="tableHeaderPageTitle">
                <th colSpan={headings.length}>
                  <b>{'RPM DETAIL'}</b>
                </th>
              </tr>
              <tr>
                {headings.map((heading, index) => (
                  <th key={index} className="rmReportColumnHeader">
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <>
              {allRMDetails ? (
                <>
                  <RemoteMonitoringData
                    months={months}
                    patientDetails={
                      Array.isArray(allRMDetails) ? allRMDetails : []
                    }
                  />
                </>
              ) : (
                <div className="bigText centerContent">
                  {intl.formatMessage({
                    id: I18nKey.REMOTE_MONITORING_NO_REPORTS,
                  })}
                </div>
              )}
            </>
          </table>
        </div>
      ) : (
        <>
          {showRemoteMonitoringReportTable ? (
            <div id="remoteMonitorReport">
              <table>
                <thead>
                  <tr className="tableHeaderPageTitle">
                    <th colSpan={headings.length}>
                      <b>{'RPM DETAIL'}</b>
                    </th>
                  </tr>
                  <tr>
                    {headings.map((heading, index) => (
                      <th key={index} className="rmReportColumnHeader">
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <>
                  {/* {allRMDetails && (
                    <>
                      <RemoteMonitoringData
                        months={months}
                        patientDetails={allRMDetails}
                      />
                    </>
                  )} */}
                  {providerRMDetails && (
                    <>
                      <RemoteMonitoringData
                        months={months}
                        patientDetails={providerRMDetails.patient_details}
                      />
                    </>
                  )}
                </>
              </table>
            </div>
          ) : showSelectProviderDateMessage ? (
            <div>
              <div className="bigText centerContent">
                {intl.formatMessage({
                  id: I18nKey.REMOTE_MONITORING_SELECT_PROVIDERS_DATE_RANGE,
                })}
              </div>
            </div>
          ) : showNoReportsMessage ? (
            <div className="bigText centerContent">
              {intl.formatMessage({ id: I18nKey.REMOTE_MONITORING_NO_REPORTS })}
            </div>
          ) : showErrorMessage ? (
            <div className="red centerContent">{ERROR_MESSAGE}</div>
          ) : (
            <div className="centerContent">
              <CircularProgress className="loaderStyle" />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RemoteMonitoringReport;

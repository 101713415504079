import { useQuery } from 'react-query';
import { createApiClient } from 'api/apiClient';
import { useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeviceHistoryRecords } from 'api/SharedTypes';
import { DateTime } from 'luxon';
import { CircularProgress } from '@mui/material';

interface DeviceAssignmentProps {
  patientId: number;
}

const convertUTCDateToLocal = (dateString: string) => {
  const localDateTime = DateTime.fromISO(dateString, { zone: 'utc' }).toLocal();
  const formattedDateTime = localDateTime.toFormat('MMM-dd-yyyy hh:mm a');
  return formattedDateTime;
};

const DeviceAssignment: React.FC<DeviceAssignmentProps> = ({
  patientId,
}): JSX.Element => {
  const accessToken = localStorage.getItem('access_token');
  const apiClient = createApiClient(accessToken!, new AbortController());
  const [deviceRecords, setDeviceRecords] = useState<DeviceHistoryRecords[]>(
    [],
  );
  const [historyRecords, setHistoryRecords] = useState('');

  const {
    data: deviceHistoryData,
    isLoading,
    refetch: refetchDeviceHistory,
  } = useQuery(
    'device-history',
    async () => {
      return await apiClient.deviceHistory((patientId as number).toString());
    },
    {
      onSuccess: (data: any) => {
        setHistoryRecords(data);
        if (data.length > 0) {
          const recordsWithKey: DeviceHistoryRecords[] = data.map(
            (item: DeviceHistoryRecords, index: number) => {
              return { ...item, id: index };
            },
          );
          setDeviceRecords(recordsWithKey);
        }
      },
      enabled: true,
    },
  );

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      width: 480,
      valueGetter: (params: GridValueGetterParams) => {
        return (
          params.row.status +
          `${
            params.row.rpm_enabled_by !== null
              ? ' (' + params.row.rpm_enabled_by + ')'
              : ''
          }`
        );
      },
    },
    {
      field: 'created_date',
      headerName: 'Status Date',
      width: 350,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.created_date) {
          return 'N/A';
        }
        return convertUTCDateToLocal(params.row.created_date);
      },
    },
  ];

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div style={{ flexGrow: 1, display: 'flex' }}>
          <DataGrid
            columns={columns}
            rows={deviceRecords}
            pageSize={5}
            autoHeight
            disableSelectionOnClick
            checkboxSelection={false}
          />
        </div>
      )}
    </div>
  );
};

export default DeviceAssignment;

import { DateTime } from 'luxon';
import axios from 'axios';
import { SubmitFormData, UserRole } from 'services/Utils';
import {
  CallRecordsResponse,
  AddDiagnosisResponse,
  CreateUserResponse,
  DeleteDiagnosisResponse,
  DiagnosisListResponse,
  GetUserListResponse,
  PatientDiagnosesResponse,
  UserDataResponse,
  NetworkResponse,
  UpdateUserResponse,
  CallLogsResponse,
  UpdateAlertStatusRequest,
  UpdateAlertStatusResponse,
  IMEIDetailsResponse,
  BillingLogsResponse,
  GetMessagesLogResponse,
  MessageData,
  RetrievedUserResponse,
  ProvidersRemoteMonitoringDetails,
  AllPatientsRemoteMonitoringDetails,
  BillingProvidersResponse,
  DeleteUserResponse,
  UpdateNetworkResponse,
  ChangeLogResponse,
  // ExceptionsListData,
  UserData,
  ExceptionStatus,
  ExceptionInputUserData,
  CreateUserInExceptionResponse,
  GetUserChannelsResponse,
  ChannelMessagesResponse,
  PatientConsentRecords,
  ConsentPdfResponse,
  CarePlanResponse,
  SetThresholdBody,
} from './SharedTypes';
import jwtDecode from 'jwt-decode';
import { filter } from 'lodash';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

interface ApiClient {
  readonly getPatientList: (
    loadUpdatedData?: boolean,
    body?: any,
  ) => Promise<GetUserListResponse[]>;
  readonly getArchivedList: (
    loadUpdatedData?: boolean,
  ) => Promise<GetUserListResponse[]>;
  readonly getCaregiversList: (
    loadUpdatedData?: boolean,
  ) => Promise<GetUserListResponse[]>;
  readonly getCaseManagersList: (
    loadUpdatedData?: boolean,
  ) => Promise<GetUserListResponse[]>;
  readonly getNursesList: (
    loadUpdatedData?: boolean,
  ) => Promise<GetUserListResponse[]>;
  readonly getPhysiciansList: (
    loadUpdatedData?: boolean,
  ) => Promise<GetUserListResponse[]>;
  readonly createNewUser: (
    details: SubmitFormData,
    type: UserRole,
  ) => Promise<CreateUserResponse>;

  readonly updatePatientData: (
    details: SubmitFormData,
    id: number,
  ) => Promise<UpdateUserResponse>;

  readonly updateCaregiverData: (
    details: SubmitFormData,
    id: number,
  ) => Promise<UpdateUserResponse>;

  readonly updateProviderData: (
    details: SubmitFormData,
    id: number,
  ) => Promise<UpdateUserResponse>;

  readonly getUserData: (
    id: number,
    type: UserRole,
    loadUpdatedData?: boolean,
  ) => Promise<UserDataResponse>;

  readonly getChangeLogs: (
    id: string,
    type: string,
  ) => Promise<ChangeLogResponse[]>;

  readonly getUserName: (
    firstName: string,
    lastName: string,
  ) => Promise<string>;
  readonly getCallRecords: (id: string) => Promise<CallRecordsResponse[]>;
  readonly getCallLogs: (id: string) => Promise<CallLogsResponse[]>;
  readonly getMessagesLog: (id: string) => Promise<GetMessagesLogResponse>;
  readonly getBillingLogs: () => Promise<BillingLogsResponse[]>;
  readonly getPatientDiagnoses: (
    patientInternalId: number,
  ) => Promise<PatientDiagnosesResponse[]>;
  readonly getDiagnosisList: () => Promise<DiagnosisListResponse[]>;
  readonly addDiagnosis: (
    patientInternalId: number,
    diagnosisCode: string,
  ) => Promise<AddDiagnosisResponse>;
  readonly deleteDiagnosis: (
    patientInternalId: number,
    diagnosisCode: string,
  ) => Promise<DeleteDiagnosisResponse>;
  readonly getNetworkList: (
    id: string,
    type: UserRole,
  ) => Promise<NetworkResponse[]>;
  readonly getPatientNetworkList: (
    id: number,
  ) => Promise<GetUserListResponse[]>;
  readonly updateAlertStatus: (
    id: number,
    status: UpdateAlertStatusRequest,
  ) => Promise<UpdateAlertStatusResponse>;
  readonly updatePatientAlertStatus: (
    id: number,
    status: UpdateAlertStatusRequest,
  ) => Promise<UpdateAlertStatusResponse>;
  readonly getChats: (
    id: string,
    cursor: string,
    limit: number,
  ) => Promise<MessageData[]>;
  readonly getUnpairedDevices: (
    imeiString: string,
  ) => Promise<IMEIDetailsResponse[]>;
  readonly getPairedUser: (imei: string) => Promise<UserDataResponse>;
  readonly postPairDevice: (
    imei: string,
    patient_internal_id: string,
    start_date: string,
  ) => Promise<UserDataResponse>;
  readonly postUnpairPatientsDevice: (
    imei: string,
    date_time: string,
    patient_internal_id: string,
  ) => Promise<UserDataResponse>;
  readonly updateArchiveList: (
    id: number | undefined,
    type: string | undefined,
  ) => Promise<RetrievedUserResponse>;
  readonly getProviderOptions: () => Promise<GetUserListResponse[]>;
  readonly getProviderRMReportDetails: (
    id: string,
    start_date: string,
    end_date: string,
  ) => Promise<ProvidersRemoteMonitoringDetails[]>;
  readonly getAllProviderRMReportDetails: (
    start_date: string,
    end_date: string,
  ) => Promise<AllPatientsRemoteMonitoringDetails>;
  readonly getRMBillingProviders: () => Promise<BillingProvidersResponse[]>;
  readonly deleteUser: (
    id: number,
    role: string,
  ) => Promise<DeleteUserResponse>;
  readonly updatePatientNetwork: (
    id: number,
    network: GetUserListResponse[],
  ) => Promise<UpdateNetworkResponse>;
  readonly updateCaregiverNetwork: (
    id: number,
    network: NetworkResponse[],
  ) => Promise<UpdateNetworkResponse>;
  readonly resetPassword: (
    username: string,
  ) => Promise<{ statusCode: number; message: string }>;
  readonly getExceptions: (page?:number) => Promise<any>;
  readonly getLoggedInUserData: () => Promise<UserData>;
  readonly updateExceptionStatus: (
    exceptionId: string | number,
    status: ExceptionStatus,
  ) => Promise<string>;
  readonly createUserInException: (
    exceptionId: string,
    inputData: ExceptionInputUserData,
  ) => Promise<CreateUserInExceptionResponse>;
  readonly updateUserInException: (
    exceptionId: string,
    inputData: ExceptionInputUserData,
  ) => Promise<UpdateUserResponse>;
  readonly getUserChannels: (
    username: string,
    includePatientLinkedChats: boolean,
    sendLastMessage?: boolean,
  ) => Promise<GetUserChannelsResponse>;
  readonly listChannelMessages: (
    channel_name: string,
    next_token?: string | null,
    max_results?: number,
  ) => Promise<ChannelMessagesResponse>;
  readonly getProviderDegrees: () => Promise<string[]>;
  readonly resendInvitation: (patientId: number) => Promise<any>;
  readonly getConsentRecords: (
    patientId: number,
  ) => Promise<PatientConsentRecords[]>;
  readonly getConsentPdfString: (
    patientId: number,
    version: number,
    consentType: string,
    consentPath: string,
  ) => Promise<ConsentPdfResponse>;
  readonly getCarePlans: (orgId: string) => Promise<CarePlanResponse[]>;
  readonly getOrganization_details: (orgId: string) => Promise<any>;
  readonly placeOrder: (patientId: string) => Promise<any>;
  readonly deviceHistory: (patientId: string) => Promise<any>;
  readonly getRpmDeviceOrder: (orgId: number) => Promise<any>;
  readonly postRpmDeviceOrder: (
    orgId: number,
    placedOrderData: PlacedOrderData,
  ) => Promise<any>;
  readonly getRpmDeviceOrderHistory: (orgId: number) => Promise<any>;
  readonly uploadConsentFile: (
    patient_internal_id: number,
    uploadFileData: UploadFileData,
  ) => Promise<any>;

  readonly getSurveyReport: (body: any) => Promise<any>;
  readonly setThresholdData: (data: SetThresholdBody) => Promise<any>;
  readonly getThresholdData: (orgId : string) => Promise<any>;
  
}

interface PlacedOrderData {
  device_type: string;
  kit_id: string;
  device_provider_name: string;
  no_of_devices: string;
}

interface UploadFileData {
  base64: string;
  consent_name: string;
  date: string;
}

const PATHS = Object.freeze({

  RPM_DEVICE_ORDER: 'org/rpm_device_order/',
  RPM_DEVICE_PLACE_ORDER: 'org/place_order/',
  RPM_DEVICE_ORDER_HISTORY: 'org/get_order_history/',
  UPLOAD_CONSENT_FILE: '/org/add_physical_consent?patient_internal_id=',
  GET_PATIENTS: 'users/patients',
  GET_ARCHIVED: 'users/archived',
  GET_CAREGIVERS: 'users/caregivers',
  GET_CASE_MANAGERS: 'users/case_managers',
  GET_NURSES: 'users/nurses',
  GET_PHYSICIANS: 'users/physicians',
  GET_PROVIDERS: 'users/providers',
  RESEND_INVITATION: 'users/resendVerificationCode',
  CREATE_NEW_USER: (type: UserRole) => `users/${type}`,
  GET_BILLING_LOGS: 'billing/reports',
  GET_USER_NAME: `users/username`,
  GET_DIAGNOSIS_LIST: 'users/diagnosis',
  GET_ALL_RM_REPORT_DETAILS: `users/provider/remote_monitoring_report/`,
  GET_RM_BILLING_PROVIDERS: `users/provider/billing/`,
  GET_CONSENT_RECORDS: (patientId: number) =>
    `users/patient/consents/${patientId}`,
  GET_CONSENT_PDF_STRING: `users/patient/consentform`,
  GET_USER_DATA: (id: number, role: UserRole) => `users/${role}/${id}`,
  GET_CALL_RECORDS: (id: string) => `call/call_records/${id}`,
  GET_CALL_LOGS: (id: string) => `call/call_logs/${id}`,
  GET_MESSAGES_LOGS: (id: string) => `messages/message_log/${id}`,
  GET_CHAT_LOGS: (chatId: string) => `messages/chats/${chatId}`,
  GET_CHANGE_LOGS: (id: string, role: string) =>
    `users/change_log/${role}/${id}`,
  GET_PATIENT_DIAGNOSIS: (patientInternalId: number) =>
    `patient/diagnosis/${patientInternalId}`,
  GET_NETWORK_LIST: (role: string, id: string) => `users/${role}/network/${id}`,
  GET_UNPAIRED_DEVICES: (imeiString: string) =>
    `users/patient/unpaired_devices/${imeiString}`,
  GET_PAIRED_USER: (imei: string) => `users/patient/paired_user/${imei}`,
  GET_PROVIDER_RM_REPORT_DETAILS: (
    id: string,
    start_date: string,
    end_date: string,
  ) =>
    `users/provider/remote_monitoring_report/${id}${
      start_date ? `?start_date=${start_date}` : ''
    }${end_date ? `&end_date=${end_date}` : ''}`,
  ADD_DEVICE_PAIRING: `users/patient/device`,
  UPDATE_ALERT_STATUS: (id: number, status: number) =>
    `users/provider/alert/${id}/${status}`,
  UPDATE_PATIENT_ALERT_STATUS: (id: number, status: number) =>
    `users/patient/network/${id}/alert/${status}`,
  UPDATE_PATIENT: (id: number) => `users/patient/${id}`,
  UPDATE_CAREGIVER: (id: number) => `users/caregiver/${id}`,
  UPDATE_PROVIDER: (id: number) => `users/provider/${id}`,
  UPDATE_DEVICE_UNPAIRING: (patient_internal_id: string) =>
    `users/patient/device/${patient_internal_id}`,
  UPDATE_ARCIVED_LIST: (id: number, role: string) =>
    `users/reactivate/${id}?role=${role}`,
  UPDATE_PATIENT_NETWORK: (id: number) => `users/patient/network/${id}`,
  UPDATE_CAREGIVER_NETWORK: (id: number) => `users/caregiver/network/${id}`,
  DELETE_USER: (id: number, role: string) => `users/${role}/${id}`,
  RESET_PASSWORD: `users/forgot_password`,
  GET_EXCEPTION_LIST: 'users/patient/exceptions/',
  GET_LOGGED_IN_USER_DATA: 'users/auth',
  UPDATE_USER_FROM_EXCEPTION: (exceptionId: string) =>
    `users/patient/exception/${exceptionId}`,
  CREATE_USER_FROM_EXCEPTION: (exceptionId: string) =>
    `users/patient/exception/${exceptionId}`,
  UPDATE_EXCEPTION_STATUS: (exception_id: string | number) =>
    `users/patient/exception/${exception_id}`,
  GET_USER_CHANNELS: (
    username: string,
    includePatientLinkedChats: boolean,
    sendLastMessage?: boolean,
  ) =>
    `messages/userChannel?username=${username}&includePatientLinkedChats=${
      includePatientLinkedChats ? 'True' : 'False'
    }${sendLastMessage ? `&sendLastMessage=True` : ''}`,
  LIST_CHANNEL_MESSAGES: 'messages/channelMessages',
  GET_PROVIDER_DEGREES: 'users/provider/degree/',
  GET_CARE_PLANS: (orgId: string) => `org/vbc/${orgId}`,
  GET_ORGANIZATION_DETAILS: (orgId: string) =>
    `org/organization_details/${orgId}`,
  PLACE_ORDER: (userId: string) => `users/place_order/${userId}`,
  DEVICE_HISTORY: (patientId: string) =>
    `users/get_device_history/${patientId}`,
  GET_SURVEY_REPORT: 'survey/get_survey_report',
  POST_THRESHOLD_DATA: "org/org_bp_threshold"
});

const checkTokenExpiry = () => {
  //Added logout functionality here rather than the userContext hook since hooks can only be called in components or custom hooks
  const token: any = jwtDecode(localStorage.getItem('access_token') || '');
  if (DateTime.now().toMillis() >= token.exp * 1000) {
    const rememberMe = localStorage.getItem('login_remember');
    const userName = localStorage.getItem('login_username');
    localStorage.clear();
    localStorage.setItem('login_remember', String(rememberMe));
    if (rememberMe) {
      localStorage.setItem('login_username', String(userName));
    }
    window.location.pathname = '/';
  }
};

export const createApiClient = (
  token: string,
  controller: AbortController,
): ApiClient => {
  const config = {
    baseURL: BASE_URL,
    signal: controller.signal,
    headers: {
      Authorization: token,
    },
  };

  const getRpmDeviceOrder = async (orgId: number) => {
    checkTokenExpiry();
    try {
      const res = await axios.get(`${PATHS.RPM_DEVICE_ORDER}${orgId}`, config);
      // console.log('reponse', res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getRpmDeviceOrderHistory = async (orgId: number) => {
    checkTokenExpiry();
    try {
      const res = await axios.get(
        `${PATHS.RPM_DEVICE_ORDER_HISTORY}${orgId}`,
        config,
      );
      // console.log('reponse', res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const postRpmDeviceOrder = async (
    orgId: number,
    placedOrderData: PlacedOrderData,
  ) => {
    checkTokenExpiry();
    return axios
      .post(`${PATHS.RPM_DEVICE_PLACE_ORDER}${orgId}`, placedOrderData, {
        ...config,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  };

  const uploadConsentFile = async (
    patient_internal_id: number,
    uploadFileData: UploadFileData,
  ) => {
    checkTokenExpiry();
    return axios
      .post(
        `${PATHS.UPLOAD_CONSENT_FILE}${patient_internal_id}`,
        uploadFileData,
        {
          ...config,
        },
      )
      .then(response => {
        // console.log(response);
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  };

  const getPatientList = async (
    loadUpdatedData?: boolean,
    body?: any,
  ): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    // const fullConfig={...config,body}
    const filteredParams =
      body &&
      Object.fromEntries(
        Object?.entries(body).filter(([_, value]) => value !== ''),
      );

    const response = await axios.get<GetUserListResponse[]>(
      PATHS.GET_PATIENTS,
      {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now(),
            }
          : filteredParams,
      },
    );

    return response.data;
  };
  const getArchivedList = async (
    loadUpdatedData?: boolean,
  ): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<GetUserListResponse[]>(
      PATHS.GET_ARCHIVED,
      {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now(),
            }
          : null,
      },
    );
    return response.data;
  };
  const getCaregiversList = async (
    loadUpdatedData?: boolean,
  ): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<GetUserListResponse[]>(
      PATHS.GET_CAREGIVERS,
      {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now(),
            }
          : null,
      },
    );
    return response.data;
  };
  const getCaseManagersList = async (
    loadUpdatedData?: boolean,
  ): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<GetUserListResponse[]>(
      PATHS.GET_CASE_MANAGERS,
      {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now(),
            }
          : null,
      },
    );
    return response.data;
  };
  const getNursesList = async (
    loadUpdatedData?: boolean,
  ): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<GetUserListResponse[]>(PATHS.GET_NURSES, {
      ...config,
      params: loadUpdatedData
        ? {
            timeStamp: Date.now(),
          }
        : null,
    });
    return response.data;
  };
  const getPhysiciansList = async (
    loadUpdatedData?: boolean,
  ): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<GetUserListResponse[]>(
      PATHS.GET_PHYSICIANS,
      {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now(),
            }
          : null,
      },
    );
    return response.data;
  };

  const createNewUser = async (
    details: SubmitFormData,
    type: UserRole,
  ): Promise<CreateUserResponse> => {
    checkTokenExpiry();
    return axios
      .post<CreateUserResponse>(PATHS.CREATE_NEW_USER(type), details, config)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  };

  const getUserName = async (
    firstName: string,
    lastName: string,
  ): Promise<string> => {
    checkTokenExpiry();
    const response = await axios.get<string>(PATHS.GET_USER_NAME, {
      ...config,
      params: { first_name: firstName, last_name: lastName },
    });
    return response.data;
  };

  const getChats = async (
    id: string,
    cursor: string,
    limit: number,
  ): Promise<MessageData[]> => {
    checkTokenExpiry();
    const response = await axios.get<MessageData[]>(PATHS.GET_CHAT_LOGS(id), {
      ...config,
      params: { cursor, limit },
    });
    return response.data;
  };
  const updatePatientData = async (details: SubmitFormData, id: number) => {
    checkTokenExpiry();
    return axios
      .put<UpdateUserResponse>(PATHS.UPDATE_PATIENT(id), details, {
        ...config,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data.detail;
      });
  };

  const updateCaregiverData = async (details: SubmitFormData, id: number) => {
    checkTokenExpiry();
    return axios
      .put<UpdateUserResponse>(PATHS.UPDATE_CAREGIVER(id), details, {
        ...config,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  };

  const updateProviderData = async (details: SubmitFormData, id: number) => {
    checkTokenExpiry();
    return axios
      .put<UpdateUserResponse>(PATHS.UPDATE_PROVIDER(id), details, {
        ...config,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  };

  const getUserData = async (
    id: number,
    role: UserRole,
    loadUpdatedData?: boolean,
  ): Promise<UserDataResponse> => {
    checkTokenExpiry();
    const response = await axios.get<UserDataResponse>(
      PATHS.GET_USER_DATA(id, role),
      {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now(),
            }
          : null,
      },
    );
    return response.data;
  };

  const getChangeLogs = async (
    id: string,
    role: string,
  ): Promise<ChangeLogResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<ChangeLogResponse[]>(
      PATHS.GET_CHANGE_LOGS(id, role),
      config,
    );
    return response.data;
  };

  const getCallRecords = async (id: string): Promise<CallRecordsResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<CallRecordsResponse[]>(
      PATHS.GET_CALL_RECORDS(id),
      config,
    );
    return response.data;
  };
  const getCallLogs = async (
    id: string | undefined,
  ): Promise<CallLogsResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<CallLogsResponse[]>(
      PATHS.GET_CALL_LOGS(id!),
      config,
    );
    return response.data;
  };

  const getBillingLogs = async (): Promise<BillingLogsResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<BillingLogsResponse[]>(
      PATHS.GET_BILLING_LOGS,
      config,
    );
    return response.data;
  };

  const getMessagesLog = async (
    id: string,
  ): Promise<GetMessagesLogResponse> => {
    checkTokenExpiry();
    const response = await axios.get<GetMessagesLogResponse>(
      PATHS.GET_MESSAGES_LOGS(id),
      config,
    );
    return response.data;
  };

  const getPatientDiagnoses = async (
    patientInternalId: number,
  ): Promise<PatientDiagnosesResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<PatientDiagnosesResponse[]>(
      PATHS.GET_PATIENT_DIAGNOSIS(patientInternalId),
      config,
    );
    return response.data;
  };

  const getDiagnosisList = async (): Promise<DiagnosisListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<DiagnosisListResponse[]>(
      PATHS.GET_DIAGNOSIS_LIST,
      config,
    );
    return response.data;
  };

  const getNetworkList = async (
    id: string,
    type: UserRole,
  ): Promise<NetworkResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<NetworkResponse[]>(
      PATHS.GET_NETWORK_LIST(type, id),
      {
        ...config,
        // params: {
        //   user_type: type,
        // },
      },
    );
    return response.data;
  };

  const getPatientNetworkList = async (
    id: number,
  ): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<GetUserListResponse[]>(
      PATHS.GET_NETWORK_LIST(UserRole.patient, id.toString()),
      config,
    );
    return response.data;
  };

  const addDiagnosis = async (
    patientInternalId: number,
    diagnosisCode: string,
  ): Promise<AddDiagnosisResponse> => {
    checkTokenExpiry();
    const response = await axios.post<AddDiagnosisResponse>(
      PATHS.GET_PATIENT_DIAGNOSIS(patientInternalId),
      {
        diagnosis: diagnosisCode,
      },
      config,
    );
    return response.data;
  };

  const deleteDiagnosis = async (
    patientInternalId: number,
    diagnosisCode: string,
  ): Promise<DeleteDiagnosisResponse> => {
    checkTokenExpiry();
    const response = await axios.delete<DeleteDiagnosisResponse>(
      PATHS.GET_PATIENT_DIAGNOSIS(patientInternalId),
      {
        ...config,
        params: {
          diagnoses: diagnosisCode,
        },
      },
    );
    return response.data;
  };

  const updateAlertStatus = async (
    id: number,
    status: UpdateAlertStatusRequest,
  ): Promise<UpdateAlertStatusResponse> => {
    checkTokenExpiry();
    const response = await axios.put(
      PATHS.UPDATE_ALERT_STATUS(id, status.alert_receiver_status),
      {},
      config,
    );
    return response.data;
  };

  const updatePatientAlertStatus = async (
    id: number,
    status: UpdateAlertStatusRequest,
  ): Promise<UpdateAlertStatusResponse> => {
    checkTokenExpiry();
    const response = await axios.put(
      PATHS.UPDATE_PATIENT_ALERT_STATUS(id, status.alert_receiver_status),
      {},
      config,
    );
    return response.data;
  };

  const updateArchiveList = async (
    id: number | undefined,
    role: string | undefined,
  ) => {
    checkTokenExpiry();
    const response = await axios.put<RetrievedUserResponse>(
      PATHS.UPDATE_ARCIVED_LIST(id!, role?.toString() || ''),
      {},
      config,
    );
    return response.data;
  };

  const getUnpairedDevices = async (
    imeiString: string,
  ): Promise<IMEIDetailsResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get<IMEIDetailsResponse[]>(
      PATHS.GET_UNPAIRED_DEVICES(imeiString),
      config,
    );
    return response.data;
  };
  const getPairedUser = async (imei: string): Promise<UserDataResponse> => {
    checkTokenExpiry();
    const response = await axios.get<UserDataResponse>(
      PATHS.GET_PAIRED_USER(imei),
      config,
    );
    return response.data;
  };

  const postPairDevice = async (
    imei: string,
    patient_internal_id: string,
    start_date: string,
  ): Promise<UserDataResponse> => {
    checkTokenExpiry();
    const response = await axios.post(
      PATHS.ADD_DEVICE_PAIRING,
      {
        imei,
        patient_internal_id,
        start_date,
        remote_monitoring: 'Y',
      },
      config,
    );
    return response.data;
  };

  const postUnpairPatientsDevice = async (
    imei: string,
    date_time: string,
    patient_internal_id: string,
  ): Promise<UserDataResponse> => {
    checkTokenExpiry();
    const response = await axios.put(
      PATHS.UPDATE_DEVICE_UNPAIRING(patient_internal_id),
      {
        imei,
        end_date: date_time,
        remote_monitoring: 'N',
      },
      config,
    );

    return response.data;
  };

  const getProviderOptions = async (): Promise<GetUserListResponse[]> => {
    checkTokenExpiry();
    const response = await axios.get(PATHS.GET_PROVIDERS, config);
    return response.data;
  };

  const getProviderRMReportDetails = async (
    id: string,
    start_date: string,
    end_date: string,
  ): Promise<ProvidersRemoteMonitoringDetails[]> => {
    checkTokenExpiry();
    const response = await axios.get(
      PATHS.GET_PROVIDER_RM_REPORT_DETAILS(id, start_date, end_date),
      config,
    );
    return response.data;
  };

  const deleteUser = async (
    id: number,
    role: string,
  ): Promise<DeleteUserResponse> => {
    checkTokenExpiry();
    const response = await axios.delete(PATHS.DELETE_USER(id, role), config);
    return response.data;
  };

  const getAllProviderRMReportDetails = async (
    start_date: string,
    end_date: string,
  ): Promise<AllPatientsRemoteMonitoringDetails> => {
    checkTokenExpiry();
    const response = await axios.get(
      `${PATHS.GET_ALL_RM_REPORT_DETAILS}?start_date=${start_date}&end_date=${end_date}`,
      config,
    );
    return response.data;
  };

  const getRMBillingProviders = async (): Promise<
    BillingProvidersResponse[]
  > => {
    checkTokenExpiry();
    const response = await axios.get(PATHS.GET_RM_BILLING_PROVIDERS, config);
    return response.data;
  };

  const updatePatientNetwork = async (
    id: number,
    network: GetUserListResponse[],
  ): Promise<UpdateNetworkResponse> => {
    checkTokenExpiry();
    const response = await axios.put<UpdateNetworkResponse>(
      PATHS.UPDATE_PATIENT_NETWORK(id),
      {
        users: network,
      },
      config,
    );
    return response.data;
  };

  const updateCaregiverNetwork = async (
    id: number,
    network: NetworkResponse[],
  ): Promise<UpdateNetworkResponse> => {
    checkTokenExpiry();
    const response = await axios.put<UpdateNetworkResponse>(
      PATHS.UPDATE_CAREGIVER_NETWORK(id),
      {
        users: network,
      },
      config,
    );
    return response.data;
  };

  const resetPassword = async (
    username: string,
  ): Promise<{ statusCode: number; message: string }> => {
    const response = await axios.get<{ statusCode: number; message: string }>(
      PATHS.RESET_PASSWORD,
      {
        baseURL: BASE_URL,
        params: {
          username: username,
          portal: 'Org',
        },
      },
    );
    return response.data;
  };

  const getLoggedInUserData = async () => {
    checkTokenExpiry();
    const response = await axios.get(PATHS.GET_LOGGED_IN_USER_DATA, config);
    return response.data;
  };

  const getExceptions = async (page?:any ) => {
    checkTokenExpiry();
    const response = await axios.get(`${PATHS.GET_EXCEPTION_LIST}?offset=${page}`, config);
    return response.data;
  };

  const updateExceptionStatus = async (
    exceptionId: string | number,
    _status: ExceptionStatus,
  ) => {
    checkTokenExpiry();
    const response = await axios.delete(
      PATHS.UPDATE_EXCEPTION_STATUS(exceptionId),
      config,
    );
    return response.data;
  };

  const createUserInException = async (
    exceptionId: string,
    inputData: ExceptionInputUserData,
  ) => {
    checkTokenExpiry();
    const response = await axios.post(
      PATHS.CREATE_USER_FROM_EXCEPTION(exceptionId),
      inputData,
      config,
    );
    return response.data;
  };

  const updateUserInException = async (
    exceptionId: string,
    inputData: ExceptionInputUserData,
  ) => {
    checkTokenExpiry();
    const response = await axios.put(
      PATHS.UPDATE_USER_FROM_EXCEPTION(exceptionId),
      inputData,
      config,
    );
    return response.data;
  };

  const getUserChannels = async (
    username: string,
    includePatientLinkedChats: boolean,
    sendLastMessage?: boolean,
  ) => {
    checkTokenExpiry();
    const response = await axios.get(
      `${PATHS.GET_USER_CHANNELS(
        username,
        includePatientLinkedChats,
        sendLastMessage,
      )}`,
      config,
    );
    return response.data;
  };

  const listChannelMessages = async (
    channel_name: string,
    next_token?: string | null,
    max_results?: number,
  ) => {
    checkTokenExpiry();
    const _params = {
      channel_name: channel_name,
      next_token: next_token,
      max_results: max_results,
    };

    if (!next_token) {
      delete _params.next_token;
    }
    if (!max_results) {
      delete _params.max_results;
    }

    const response = await axios.get(PATHS.LIST_CHANNEL_MESSAGES, {
      ...config,
      params: _params,
    });
    return response.data;
  };

  const getProviderDegrees = async () => {
    checkTokenExpiry();
    const response = await axios.get<string[]>(
      PATHS.GET_PROVIDER_DEGREES,
      config,
    );
    return response.data;
  };

  const resendInvitation = async (patientId: number) => {
    checkTokenExpiry();
    const response = await axios.get<any>(
      PATHS.RESEND_INVITATION + `/${patientId}`,
      config,
    );
    return response.data;
  };
  const getConsentRecords = async (patientId: number) => {
    checkTokenExpiry();
    const response = await axios.get<PatientConsentRecords[]>(
      PATHS.GET_CONSENT_RECORDS(patientId),
      {
        ...config,
      },
    );
    return response.data;
  };

  const getConsentPdfString = async (
    patientId: number,
    version: number,
    consentType: string,
    consentPath: string
  ) => {
    checkTokenExpiry();
    const response = await axios.post(
      PATHS.GET_CONSENT_PDF_STRING,
      {
        internal_id: patientId,
        consent_name: consentType,
        version: version,
        consent_path: consentPath
      },
      {
        ...config,
      },
    );
    return response.data;
  };

  const getCarePlans = async (orgId: string) => {
    checkTokenExpiry();
    const response = await axios.get<any>(PATHS.GET_CARE_PLANS(orgId), config);
    return response.data;
  };

  const getOrganization_details = async (orgId: string) => {
    checkTokenExpiry();
    const response = await axios.get<any>(
      PATHS.GET_ORGANIZATION_DETAILS(orgId),
      config,
    );
    return response.data;
  };

  const getThresholdData = async(orgId: string)=>{
    const response = await getOrganization_details(orgId);
    const wrapped1 = [response].flat();
    const specificData = wrapped1.map((user: any) => ({
      lower_diastolic: user.lower_diastolic,
      upper_diastolic: user.upper_diastolic,
      lower_systolic: user.lower_systolic,
      upper_systolic: user.upper_systolic
    }));
    return specificData;
  }



  const placeOrder = async (userId: string) => {
    checkTokenExpiry();
    const response = await axios.get<any>(PATHS.PLACE_ORDER(userId), config);
    return response.data;
  };

  const deviceHistory = async (patientId: string) => {
    checkTokenExpiry();
    const response = await axios.get<any>(
      PATHS.DEVICE_HISTORY(patientId),
      config,
    );
    return response.data;
  };


  const getSurveyReport = async (body: any) => {
    checkTokenExpiry();

    const filteredParams =
    body &&
    Object.fromEntries(
      Object?.entries(body).filter(([_, value]) => value !== "")
    );
    const response = await axios.get<any>(PATHS.GET_SURVEY_REPORT, {...config, params: filteredParams},
    );

    // console.log("response", response)
    return response.data;
  }

  const setThresholdData = async (bodyData: SetThresholdBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.POST_THRESHOLD_DATA}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };


  return {
    setThresholdData,
    getThresholdData,
    uploadConsentFile,
    getRpmDeviceOrder,
    getRpmDeviceOrderHistory,
    postRpmDeviceOrder,
    addDiagnosis,
    createNewUser,
    getPatientList,
    getArchivedList,
    getCaregiversList,
    getCaseManagersList,
    getNursesList,
    getPhysiciansList,
    getUserData,
    getChangeLogs,
    getUserName,
    getCallRecords,
    getBillingLogs,
    getPatientDiagnoses,
    getDiagnosisList,
    getNetworkList,
    getPatientNetworkList,
    getCallLogs,
    getUnpairedDevices,
    getPairedUser,
    getMessagesLog,
    getChats,
    getProviderOptions,
    getProviderRMReportDetails,
    getAllProviderRMReportDetails,
    getRMBillingProviders,
    updatePatientData,
    updateProviderData,
    updateCaregiverData,
    updateAlertStatus,
    updatePatientAlertStatus,
    updateArchiveList,
    updatePatientNetwork,
    updateCaregiverNetwork,
    deleteDiagnosis,
    deleteUser,
    postPairDevice,
    postUnpairPatientsDevice,
    resetPassword,
    getLoggedInUserData,
    getExceptions,
    updateExceptionStatus,
    createUserInException,
    updateUserInException,
    getUserChannels,
    listChannelMessages,
    getProviderDegrees,
    resendInvitation,
    getConsentRecords,
    getConsentPdfString,
    getCarePlans,
    getOrganization_details,
    placeOrder,
    deviceHistory,
    getSurveyReport
  };
};

import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import { createApiClient } from 'api/apiClient';
import { ChatData } from 'api/SharedTypes';
import ChatTable from './ChatTable';
import 'pages/MessagesLog/MessagesLog.css';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import uniqBy from 'lodash/uniqBy';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';

export enum MessageType {
  private = 'private',
  network = 'network',
}

export const OldMessagesLog: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [chatData, setChatData] = useState<ChatData[]>([]);
  const [patientName, setPatientName] = useState<string>('');

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const { isLoading, isError } = useQuery(
    'get-messages-log',
    () => apiClient.getMessagesLog(id!),
    {
      onSuccess: data => {
        setPatientName(data.patient.name);
        setChatData(uniqBy(data.chats, 'id'));
      },
      enabled: id ? true : false,
    },
  );

  return isLoading ? (
    <div className="loadingContainer">
      <CircularProgress />
    </div>
  ) : isError ? (
    <div className="errorText">
      {intl.formatMessage({ id: I18nKey.MESSAGES_LOG_ERROR })}
    </div>
  ) : isEmpty(chatData) ? (
    <div className="noDataContainer">No Old History for this Patient</div>
  ) : (
    <div className="messagesLogContainer">
      {chatData.map(item => (
        <ChatTable key={item.id} chatData={item} patientName={patientName} />
      ))}
    </div>
  );
};

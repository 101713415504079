import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonGroup,
} from '@mui/material';
import React, { useContext } from 'react';
import { UserContext, UserContextState } from 'contexts/userContext';

interface AutomationDialogProps {
  readonly open: boolean;
  readonly handleAutomationAccept: () => void;
  readonly patientUserData: any;
  readonly handleClose: () => void;
}

export const AutomationDialog: React.FC<AutomationDialogProps> = ({
  open,
  handleAutomationAccept,
  patientUserData,
  handleClose,
}) => {
  const { userData } = useContext<UserContextState>(UserContext);
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmation Message</DialogTitle>
        <DialogContent>
          <b>{userData?.name}</b> is authorizing shipment of Remote Monitoring
          Device to <b>{patientUserData.name}</b> of{' '}
          <b>{sessionStorage.getItem('orgName')}</b>.<br />
          Your organization will be billed for the device with your
          authorization details.
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleAutomationAccept}
            >
              Proceed
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Decline
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
};

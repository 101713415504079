import React, { useContext, useEffect, useState } from 'react';
import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import { GetUserListResponse } from 'api/SharedTypes';
import 'components/UsersList/UsersList.css';
import startCase from 'lodash/startCase';
import { UserContext, UserContextState } from 'contexts/userContext';

interface ArchivedListProps {
  readonly users: GetUserListResponse[];

  readonly onUserRestore?: (id: GetUserListResponse) => void;
}

const ArchivedList: React.FC<ArchivedListProps> = ({
  users,
  onUserRestore,
}) => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const [searchText, setSearchText] = useState<string>('');

  const [filteredUsers, setFilteredUsers] =
    useState<GetUserListResponse[]>(users);

  const username = (item: GetUserListResponse) => {
    if (item.role !== 'patient' && item.role !== 'caregiver')
      return `${item.name}, ${item.degree}`;
    else return `${item.name}`;
  };

  const specialty = (item: GetUserListResponse) => {
    if (item.role !== 'patient' && item.role !== 'caregiver')
      return `${item.specialty}, ${startCase(item.role)}`;
    return `${startCase(item.role)}`;
  };

  useEffect(() => {
    const searchResults = users.filter((user: GetUserListResponse) =>
      user?.name?.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredUsers(searchText ? searchResults : users);
  }, [searchText, users]);

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value);

  return (
    <div className="fullWidth">
      <input
        value={searchText}
        className="searchBar"
        placeholder="Search..."
        onChange={handleSearchTextChange}
      />
      <List>
        {filteredUsers.map(item => (
          <div key={item.external_id} className="userListContainer">
            <ListItem key={item.external_id} button>
              <ListItemText
                key={item.external_id}
                primary={username(item)}
                secondary={specialty(item)}
              />
            </ListItem>

            <IconButton
              edge="end"
              aria-label="delete"
              disabled={isReadOnly}
              onClick={() => onUserRestore?.(item)}
            >
              <UndoIcon />
            </IconButton>
          </div>
        ))}
      </List>
    </div>
  );
};

export default ArchivedList;

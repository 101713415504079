import { useState } from 'react';
import { DateTime } from 'luxon';
import { UserDataResponse } from 'api/SharedTypes';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  SubmitFormData,
} from 'services/Utils';

export const useCaregiverData = () => {
  const [userName, setUserName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [cellCountryCode, setCellCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [homeTelCountryCode, setHomeTelCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [email, setEmail] = useState<string>('');
  const [landline, setLandline] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [dob, setDOB] = useState<DateTime | null>(null);
  const [gender, setGender] = useState<string>('');
  const [homeAddr1, setHomeAddr1] = useState<string>('');
  const [homeAddr2, setHomeAddr2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [licenseNumber, setLicenseNumber] = useState<string>('');
  const [remoteMonitoring, setRemoteMonitoring] = useState<string | null>(null);
  const [verifyEmailChecked, setVerifyEmailChecked] = useState<boolean>(false);

  const [caregiverData, setCaregiverData] = useState<UserDataResponse>(
    EMPTY_USER_RESPONSE_DATA,
  );

  const caregiverFormData = {
    userName,
    firstName,
    lastName,
    cellCountryCode,
    homeTelCountryCode,
    email,
    landline,
    mobile,
    dob,
    gender,
    homeAddr1,
    homeAddr2,
    city,
    state,
    zipCode,
    licenseNumber,
    remoteMonitoring,
    verifyEmailChecked,
  };

  const caregiverFormActions = {
    setUserName,
    setFirstName,
    setLastName,
    setCellCountryCode,
    setHomeTelCountryCode,
    setEmail,
    setLandline,
    setMobile,
    setDOB,
    setGender,
    setHomeAddr1,
    setHomeAddr2,
    setCity,
    setState,
    setZipCode,
    setLicenseNumber,
    setRemoteMonitoring,
    setCaregiverData,
    setVerifyEmailChecked,
  };

  const setCaregiverFormData = (data: UserDataResponse) => {
    setUserName(data.username);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setCellCountryCode(
      data.cell_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setHomeTelCountryCode(
      data.home_tel_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setEmail(data.email);
    setDOB(DateTime.fromFormat(data.dob, 'MM-dd-yyyy'));
    setGender(data.gender);
    setLandline(data.home_tel);
    setMobile(data.cell);
    setGender(data.gender);
    setHomeAddr1(data.home_addr_1);
    setHomeAddr2(data.home_addr_2);
    setCity(data.address_city);
    setState(data.state);
    setZipCode(data.address_zip);
    setLicenseNumber(data.drive_license_number);
    setRemoteMonitoring(data.remote_monitoring);
  };

  const getFormSubmitData = (): SubmitFormData => ({
    username: userName,
    first_name: firstName,
    last_name: lastName,
    email: email,
    cell_country_code: cellCountryCode,
    home_tel_country_code: homeTelCountryCode,
    dob: dob?.toFormat('MM-dd-yyyy') || '',
    cell: mobile.replaceAll('-', ''),
    home_tel: landline.replaceAll('-', ''),
    home_addr_1: homeAddr1,
    home_addr_2: homeAddr2,
    address_city: city,
    state: state,
    address_zip: zipCode,
    drive_license_number: licenseNumber,
    gender: gender,
    remote_monitoring: remoteMonitoring ? remoteMonitoring : 'N',
  });

  const putUpdateFormData = (caregiverId: number | null): SubmitFormData =>
    verifyEmailChecked
      ? {
          first_name: firstName,
          last_name: lastName,
          cell_country_code: cellCountryCode,
          home_tel_country_code: homeTelCountryCode,
          email: email,
          dob: dob?.toFormat('MM-dd-yyyy') || '',
          cell: mobile,
          home_tel: landline,
          home_addr_1: homeAddr1,
          home_addr_2: homeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          gender: gender,
          id: caregiverId ? caregiverId.toString() : '',
          remote_monitoring: remoteMonitoring ? remoteMonitoring : '',
          username: userName,
          email_verified: verifyEmailChecked,
        }
      : {
          first_name: firstName,
          last_name: lastName,
          cell_country_code: cellCountryCode,
          home_tel_country_code: homeTelCountryCode,
          email: email,
          dob: dob?.toFormat('MM-dd-yyyy') || '',
          cell: mobile,
          home_tel: landline,
          home_addr_1: homeAddr1,
          home_addr_2: homeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          gender: gender,
          id: caregiverId ? caregiverId.toString() : '',
          remote_monitoring: remoteMonitoring ? remoteMonitoring : '',
          username: userName,
        };

  const clearForm = () => {
    setUserName('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setCellCountryCode(ExportConstants.countryCodeOptions[0].value.toString());
    setHomeTelCountryCode(
      ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setDOB(null);
    setGender('');
    setLandline('');
    setMobile('');
    setGender('');
    setHomeAddr1('');
    setHomeAddr2('');
    setCity('');
    setState('');
    setZipCode('');
    setLicenseNumber('');
    setRemoteMonitoring(null);
  };

  return {
    caregiverUserData: caregiverData,
    caregiverFormData,
    caregiverFormActions,
    setCaregiverFormData,
    getFormSubmitData,
    putUpdateFormData,
    clearForm,
  };
};

import React, { useRef } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableHead,
  Checkbox,
  Snackbar,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from './Colors';
import { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { createApiClient } from 'api/apiClient';
import I18nKey from 'lib/I18nKeys';
import { useIntl } from 'react-intl';
import './SurveyReport.css';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import moment from 'moment';
import LoadingBackdrop from './Backdroptime';

interface ApiResponse {
  id: number;
  external_id: string;
  assigner_name: string;
  completed_at: string;
  responses: {
    response: {
      q_id: number;
      issue: string;
      answer: string;
      question: string;
    }[];
  };
}

const monthNames = [
  'All',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const SurveyReport = () => {
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);
  const intl = useIntl();
  const [ERROR] = [I18nKey.ERROR_MESSAGE].map(key =>
    intl.formatMessage({ id: key }),
  );
  const currentYear = new Date().getFullYear();
  const [showtable, setshowtable] = useState<boolean>(false);
  const [Surveydata, setSurveydata] = useState<any>([]);
  const [localSearchProvider, setLocalSearchProvider] = useState<string>('');
  const [isMonthSelect, setMonthSelect] = useState<any>('');
  const [isYearSelect, setYearSelect] = useState<any>(currentYear);
  const [isSelect, setSelect] = useState<string>('');
  const [page, setPage] = useState<any>(1);
  const [patientName, setpatientName] = useState<string>('');
  const [surveyResponse, setResponses] = useState([]);
  const [pamSurveyScore, setpamSurveyScore] = useState<number>();
  const [filterPam, setfilterPam] = useState<any>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isPamNotApplicable, seIsPamNotApplicable] = useState<any>(false);
  const [isAllAnswerSame, setISAllAnswerSame] = useState<any>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const columns = [
    {
      title: 'Issue',
      dataIndex: 'issue',
      key: 'issue',
      width: '200px',
      align: 'left',
      render: (record: any) => {
        // console.log('record', record);
        return <>{record}</>;
      },
    },
    {
      title: 'Action Items',
      dataIndex: 'action_item',
      key: 'action_item',
      width: '300px',
      align: 'left',
      render: (action_item: any) => {
        return <>{action_item}</>;
      },
    },
    {
      title: 'Completed',
      dataIndex: 'action_response',
      key: 'action_response',
      width: '60px',
      align: 'center',
      render: (action_response: any) => {
        return <Checkbox checked={!!action_response} disabled></Checkbox>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'action_update_time',
      key: 'action_update_time',
      width: '60px',
      align: 'center',
      render: (action_update_time: any) => {
        return <>{action_update_time}</>;
      },
    },
  ];

  const data2 = Surveydata && Surveydata;

  function getdata(e: any) {
    setpatientName(e.target.value);
  }

  let debounceTimer: ReturnType<typeof setTimeout>;

  const handleInfiniteScroll = async () => {
    try {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      debounceTimer = setTimeout(() => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.scrollHeight
        ) {
          if (isSurveyReportData?.length > 0) {
            setPage((prev: number) => prev + 1);
          }
        }
      }, 100); // Adjust the debounce time as necessary
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleInfiniteScroll);
    return () => window.removeEventListener('scroll', handleInfiniteScroll);
  }, [handleInfiniteScroll]);

  const {
    isFetching: isLoadingSurveyReport,
    isError: isErrorSurveyReport,
    data: isSurveyReportData,
    refetch: refetchSurveyReport,
  } = useQuery(
    ['patient-survey', page],
    () =>
      apiClient.getSurveyReport({
        limit: 20,
        offset: page,
        month: isMonthSelect,
        year: isYearSelect,
        name: patientName,
        status: isSelect,
      }),
    { enabled: true },
  );

  useEffect(() => {
    if (isSurveyReportData?.length === undefined) return;
    if (page === 1) {
      setSurveydata(isSurveyReportData);
    } else {
      if (isSurveyReportData?.length) {
        setSurveydata((pre: any) => [...pre, ...isSurveyReportData]);
      }
    }
    console.log('length', isSurveyReportData?.length);
  }, [isSurveyReportData, page]);

  const handleSelect = (event: SelectChangeEvent) => {
    if (event.target.value === 'All') {
      setSelect('');
    } else {
      setSelect(event.target.value);
    }
  };

  const handleSelectStatus = (event: any) => {
    if (event.target.value === 0) {
      setMonthSelect('');
    } else {
      setMonthSelect(event.target.value);
    }
  };

  const handleSelectYear = (event: SelectChangeEvent) => {
    setYearSelect(event.target.value);
  };

  const handleSearch = () => {
    setPage(1);
    refetchSurveyReport();
  };

  useEffect(() => {
    if (!Surveydata?.length) return;
    setfilterPam(surveyResponse.filter((item: any) => item?.issue !== ''));
  }, [Surveydata, surveyResponse]);

  useEffect(() => {
    if (!isLoadingSurveyReport && isSurveyReportData?.length === 0) {
      setIsSnackbarOpen(true);
      setTimeout(() => {
        setIsSnackbarOpen(false);
      }, 2000);
    } else {
      setIsSnackbarOpen(false);
    }
  }, [isSurveyReportData]);

  const isPamSurveyValid = (item: any) => {
    const filteredResponses = item?.responses?.response.filter(
      (res: any) => res?.issue !== '',
    );
    const isTrue = filteredResponses.filter(
      (res: any) => res?.action_response === true,
    );
    const isLenghtTrue =
      isTrue?.length === filteredResponses?.length && item?.score > -1;
    const isNotApplicable = item?.responses?.response?.filter(
      (item: any) => item.answer == 'N/A',
    );
    const isApplicable = isNotApplicable?.length >= 3;
    const isSameAnswerSame = item?.score === -1;

    const handleClick = () => {
      seIsPamNotApplicable(isApplicable);
      setshowtable(prevShowTable => !prevShowTable);
      setResponses(item?.responses?.response);
      setpamSurveyScore(item?.score);
      setISAllAnswerSame(isSameAnswerSame);
    };

    return (
      <InfoIcon
        style={{
          fontSize: '18px',
          color: isLenghtTrue ? 'rgb(124, 186, 91)' : Colors.Red,
          cursor: 'pointer',
        }}
        onClick={handleClick}
      />
    );
  };

  return (
    <>
      <div
        style={{
          padding: '2%',
          textAlign: 'center',
          height: '100%',
        }}
        onKeyDown={e => (e.key === 'Enter' ? handleSearch() : null)}
      >
        <TextField
          name="noAutoFill"
          style={{
            marginRight: 20,
          }}
          sx={{
            '& .MuiInputBase-root': {
              height: '40px',
            },
          }}
          id="outlined-basic"
          label="Search Patient"
          variant="outlined"
          onChange={getdata}
        />
        <FormControl style={{ marginRight: 20 }}>
          <InputLabel size="small" id="Select Status">
            Select Status
          </InputLabel>
          <Select
            sx={{ minWidth: 160 }}
            size="small"
            label="Select Status"
            labelId="Select Status"
            value={isSelect === '' ? 'All' : isSelect}
            onChange={handleSelect}
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={1}>Completed</MenuItem>
            <MenuItem value={2}>Draft</MenuItem>
            <MenuItem value={0}>Pending</MenuItem>
          </Select>
        </FormControl>

        {isPamNotApplicable || isAllAnswerSame ? (
          <Dialog
            open={showtable}
            onClose={() => {
              setshowtable(!showtable);
            }}
            maxWidth="lg"
            fullWidth={true}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Pam Survey
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setshowtable(!showtable);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <div>
                {isPamNotApplicable
                  ? 'To ensure reliability of scoring this survey is excluded as it has 3 or more N/A response'
                  : isAllAnswerSame
                  ? 'To ensure reliability of scoring this survey is excluded as response for all questions is same'
                  : ' '}
              </div>
            </DialogContent>
          </Dialog>
        ) : (
          <Dialog
            title="Pam Suryey"
            open={showtable}
            onClose={() => {
              setshowtable(!showtable);
            }}
            maxWidth="lg"
            fullWidth={true}
            style={{ userSelect: 'none', cursor: 'default' }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Pam Survey
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setshowtable(!showtable);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>{' '}
            <DialogContent sx={{ overflow: 'hidden' }} dividers>
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: 18,
                    fontWeight: 'bold',
                    padding: '0px 12px',
                    marginBottom: '8px',
                  }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 2 }}
                  >
                    Activation Level :{'  '}
                    <span style={{ color: 'red' }}>
                      {' '}
                      {pamSurveyScore && pamSurveyScore <= 47 && (
                        <div style={{ alignItems: 'center' }}>
                          <span>Level 1</span>{' '}
                          <span
                            style={{ fontSize: '14px', fontWeight: 'normal' }}
                          >
                            (Belives Activation Role Important)
                          </span>
                        </div>
                      )}
                    </span>
                    <span style={{ color: 'red' }}>
                      {' '}
                      {pamSurveyScore &&
                        pamSurveyScore >= 47.1 &&
                        pamSurveyScore <= 55.1 && (
                          <div style={{ textAlign: 'center' }}>
                            <span>Level 2</span>{' '}
                            <span
                              style={{ fontSize: '14px', fontWeight: 'normal' }}
                            >
                              (Confidence and Knowledge to take Action)
                            </span>
                          </div>
                        )}
                    </span>
                    <span style={{ color: 'orange' }}>
                      {' '}
                      {pamSurveyScore &&
                        pamSurveyScore >= 55.2 &&
                        pamSurveyScore <= 72.4 && (
                          <div style={{ textAlign: 'center' }}>
                            <span>Level 3</span>{' '}
                            <span
                              style={{ fontSize: '14px', fontWeight: 'normal' }}
                            >
                              (Taking Action)
                            </span>
                          </div>
                        )}
                    </span>
                    <span style={{ color: 'green' }}>
                      {' '}
                      {pamSurveyScore && pamSurveyScore >= 72.4 && (
                        <div style={{ textAlign: 'center' }}>
                          {' '}
                          <span>Level 4</span>{' '}
                          <span
                            style={{ fontSize: '14px', fontWeight: 'normal' }}
                          >
                            (Staying the Course under Stress)
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: 14 }}>Score :</span>{' '}
                    {pamSurveyScore && pamSurveyScore}
                  </div>
                </div>
              </>
            </DialogContent>
            {filterPam && filterPam !== 0 ? (
              <div
                style={{ height: '400px', overflowY: 'auto', color: 'black' }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell
                            sx={{
                              width: column.width,
                              textAlign: column.align,
                            }}
                          >
                            {column.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterPam.map(
                        (row: {
                          [x: string]:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                        }) => (
                          <TableRow>
                            {columns.map(column => (
                              <TableCell
                                sx={{
                                  width: column.width,
                                  textAlign: column.align,
                                }}
                              >
                                {column?.title === 'Completed' ? (
                                  <Checkbox
                                    checked={row[column?.dataIndex] === true}
                                    disabled
                                  />
                                ) : column?.title === 'Date' ? (
                                  row[column?.dataIndex] ? (
                                    row[column?.dataIndex]
                                  ) : (
                                    '--'
                                  )
                                ) : (
                                  row[column?.dataIndex]
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <p>No matching records found.</p>
            )}
          </Dialog>
        )}

        <FormControl style={{ marginRight: 20 }}>
          <InputLabel size="small" id="Select Month">
            Select Month
          </InputLabel>

          <Select
            sx={{ minWidth: 160 }}
            size="small"
            value={isMonthSelect}
            label="Select Status"
            labelId="Select Status"
            onChange={handleSelectStatus}
          >
            {monthNames.map((mon, index) => {
              return <MenuItem value={index}>{mon}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <FormControl style={{ marginRight: 20 }}>
          <InputLabel sx={{ top: -8 }} id="Select Year">
            Select Year
          </InputLabel>
          <Select
            sx={{ minWidth: 140 }}
            size="small"
            value={isYearSelect ?? ''}
            label="Select Year"
            onChange={handleSelectYear}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value={currentYear}>{currentYear}</MenuItem>
            <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
            <MenuItem value={currentYear - 2}>{currentYear - 2}</MenuItem>
            <MenuItem value={currentYear - 3}>{currentYear - 3}</MenuItem>
            <MenuItem value={currentYear - 4}>{currentYear - 4}</MenuItem>
          </Select>
        </FormControl>

        <Button variant="outlined" onClick={() => handleSearch()}>
          Search
        </Button>

        <div className="SurveyLogs">
          <table
            id="patientSympData"
            style={{
              position: 'sticky',
              zIndex: 10,
              userSelect: 'none',
              cursor: 'default',
              opacity: showtable ? 0.25 : 1,
            }}
          >
            {isLoadingSurveyReport && <LoadingBackdrop />}
            <thead style={{ color: 'black', position: 'sticky', top: 0 }}>
              <tr>
                <th>
                  <b>Name of Survey</b>
                </th>
                <th>
                  <b>Patient Name</b>
                </th>
                <th>
                  <b>Date Assigned</b>
                </th>
                <th>
                  <b>Date Completed</b>
                </th>
                <th style={{ width: '150px' }}>
                  <b>Upcoming Date</b>
                </th>
                <th>
                  <b>Status</b>
                </th>
                <th>
                  <b>Score</b>
                </th>
                <th style={{ width: '80px' }}></th>
              </tr>
            </thead>

            <thead style={{ color: 'black', top: 0 }}>
              {Surveydata && Surveydata?.length > 0 ? (
                Array.isArray(Surveydata) &&
                Surveydata?.map((item: any) => (
                  <tr className="table-data">
                    <td>{item?.name}</td>
                    <td>{item?.patient_name} </td>
                    <td>{moment(item?.created_at).format('MMM DD, YYYY')}</td>
                    <td>
                      {moment(item?.completed_at).format('MMM DD, YYYY') !==
                      'Invalid date'
                        ? moment(item?.completed_at).format('MMM DD, YYYY')
                        : 'N/A'}
                    </td>
                    <td>
                      {moment(item?.upcoming_pam).format('MMM DD, YYYY') !==
                      'Invalid date'
                        ? moment(item?.upcoming_pam).format('MMM DD, YYYY')
                        : 'N/A'}
                    </td>
                    <td>
                      {item.status === 0
                        ? 'Pending'
                        : item.status === 1
                        ? 'Completed'
                        : item.status === 2
                        ? 'Draft'
                        : 'Unknown'}
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {item?.score == null ||
                        item?.score == 0 ||
                        item?.score == -1
                          ? 'N/A'
                          : item?.score}
                      </div>
                    </td>
                    <td>
                      {item?.score || item?.score === 0
                        ? isPamSurveyValid(item)
                        : ' '}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <LoadingBackdrop />
                  No matching records found.
                </tr>
              )}
            </thead>
          </table>
        </div>
      </div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        message={'Reached end of data.'}
      />
    </>
  );
};

export default SurveyReport;

import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { createApiClient } from 'api/apiClient';
import { SelectOption } from './Utils';

const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24;

export const useProviderDegrees = () => {
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);
  const { data: providerDegreeList } = useQuery(
    'provider-degree-list',
    async () => apiClient.getProviderDegrees(),
    {
      staleTime: Infinity,
      cacheTime: TWENTY_FOUR_HOURS,
    },
  );

  const providerDegreeOptions = useMemo(() => {
    if (providerDegreeList) {
      const providerOptions = providerDegreeList.map<SelectOption>(degree => ({
        label: degree,
        value: degree,
      }));
      return providerOptions;
    }
    return [];
  }, [providerDegreeList]);

  return providerDegreeOptions;
};

import React from 'react';
import { ChangeDict, ChangeLogResponse, StateChange } from 'api/SharedTypes';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import isEmpty from 'lodash/isEmpty';

interface ChangesLogContentProps {
  changesInfo: ChangeLogResponse[];
}

const ChangesLogContent: React.FC<ChangesLogContentProps> = ({
  changesInfo,
}) => {
  const intl = useIntl();

  const getSortedKeysList = (changeItem: ChangeLogResponse) => {
    let keysList: string[] = [];

    for (let key in changeItem.new_state) {
      keysList.push(key);
    }

    for (let key in changeItem.old_state) {
      if (!keysList.includes(key)) {
        keysList.push(key);
      }
    }

    return keysList.sort();
  };

  const generateId = (stateItem: StateChange) => {
    let stateId: string | null = null;
    if ('role' in stateItem && 'id' in stateItem) {
      stateId = stateItem['role'] + '#' + stateItem['id'];
    } else if (!('role' in stateItem) && 'id' in stateItem) {
      stateId = 'org:' + stateItem['id'];
    } else {
      stateId = '';
    }

    return stateId;
  };

  const formatNestedAttributes = (
    changeItem: ChangeLogResponse,
    attributeKey: string,
    changeIndex: number,
    attributeIndex: number,
  ) => {
    let joinedStatesDict: { [parameter: string]: ChangeDict } = {};
    let new_State_Change_Item: Array<StateChange> = changeItem['new_state'][
      attributeKey
    ] as any;

    new_State_Change_Item?.forEach((newStateItem: StateChange) => {
      let stateId = generateId(newStateItem);
      let attributeDict: any = {
        name: { new: '', old: '' },
        id: { new: '', old: '' },
      };
      Object.keys(newStateItem).forEach(stateAttributeKey => {
        attributeDict[stateAttributeKey] = {
          new: newStateItem[stateAttributeKey],
          old: null,
        };
      });
      joinedStatesDict[stateId] = attributeDict;
    });

    let old_State_Change_Item: Array<StateChange> = changeItem['old_state'][
      attributeKey
    ] as any;

    old_State_Change_Item?.forEach((oldStateItem: StateChange) => {
      let stateId = generateId(oldStateItem);
      let attributeDict: any = {
        name: { new: '', old: '' },
        id: { new: '', old: '' },
      };
      Object.keys(oldStateItem).forEach((stateAttributeKey: any) => {
        if (stateId in joinedStatesDict) {
          attributeDict = joinedStatesDict[stateId];
          if (stateAttributeKey in attributeDict) {
            attributeDict[stateAttributeKey]['old'] =
              oldStateItem[stateAttributeKey];
          } else {
            attributeDict[stateAttributeKey] = {
              new: null,
              old: oldStateItem[stateAttributeKey],
            };
          }
        } else {
          attributeDict[stateAttributeKey] = {
            new: null,
            old: oldStateItem[stateAttributeKey],
          };
        }
      });
      joinedStatesDict[stateId] = attributeDict;
    });

    let attributeRow = Object.keys(joinedStatesDict).map(
      (stateId, stateIndex) => {
        let joinedStatesDicts = joinedStatesDict[stateId] as any;
        let attributeColumns = Object.keys(joinedStatesDicts).map(
          stateAttributeKey => {
            let newState = joinedStatesDicts[stateAttributeKey]['new'];
            let oldState = joinedStatesDicts[stateAttributeKey]['old'];
            let changesFound = newState !== oldState;
            return [
              <div
                key="attr"
                className={
                  changesFound ? 'complex_attribute bold' : 'complex_attribute'
                }
              >
                {stateAttributeKey}
              </div>,
              <div
                key="oldState"
                className={changesFound ? 'value bold' : 'value'}
              >
                {oldState}
              </div>,
              <div
                key="newState"
                className={changesFound ? 'value bold' : 'value'}
              >
                {newState}
              </div>,
            ];
          },
        );
        return (
          <tr
            key={
              'complex_tr_' +
              changeIndex +
              '_' +
              attributeIndex +
              '_' +
              stateIndex
            }
          >
            <td>{attributeColumns}</td>
          </tr>
        );
      },
    );
    return [
      <tr key={'tr_' + changeIndex + '_' + attributeIndex + '_' + attributeKey}>
        <td>
          <div className="attribute">{attributeKey}</div>
        </td>
      </tr>,
      attributeRow,
    ];
  };

  const formatAttributes = (
    changeItem: ChangeLogResponse,
    attributeKey: string,
    changeIndex: number,
    attributeIndex: number,
  ) => {
    let newStateColumn: string = '';
    if (attributeKey in changeItem.new_state) {
      newStateColumn = changeItem.new_state[attributeKey]!;
    }
    let oldStateColumn = '';
    if (attributeKey in changeItem.old_state) {
      oldStateColumn = changeItem.old_state[attributeKey]!;
    }
    let changesFound = newStateColumn !== oldStateColumn;
    return (
      <td key={'attribute_td_key_' + changeIndex + '_' + attributeIndex}>
        <div className={changesFound ? 'attribute bold' : 'attribute'}>
          {attributeKey}
        </div>
        <div className={changesFound ? 'value bold' : 'value'}>
          {oldStateColumn}
        </div>
        <div className={changesFound ? 'value bold' : 'value'}>
          {newStateColumn}
        </div>
      </td>
    );
  };

  return (
    <div className="row">
      {!isEmpty(changesInfo) ? (
        changesInfo.map(
          (changeItem: ChangeLogResponse, changeIndex: number) => {
            let keysList = getSortedKeysList(changeItem);
            return (
              <table className="change_log" key={'table_key_' + changeIndex}>
                <thead key={'thead_key_' + changeIndex}>
                  <tr>
                    <td key={'author_key_' + changeIndex}>
                      {`${intl.formatMessage({
                        id: I18nKey.CHANGES_LOG_UPDATED_BY,
                      })} ${changeItem.auth_name} (${changeItem.auth_role} id ${
                        changeItem.auth_id
                      })`}
                      <br />
                      {`${intl.formatMessage({
                        id: I18nKey.CHANGES_LOG_ORGANIZATION,
                      })} ${
                        changeItem.auth_org_name
                          ? `${changeItem.auth_org_name} (organization id ${changeItem.auth_org})`
                          : 'organization id ' + changeItem.auth_org
                      }`}
                      <br />
                      {`${intl.formatMessage({
                        id: I18nKey.CHANGES_LOG_DATE,
                      })} ${changeItem.utc_timestamp}`}
                      <br />
                      {`${intl.formatMessage({
                        id: I18nKey.CHANGES_LOG_LOG_ID,
                      })} ${changeItem.external_id}`}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th key={'header_state_info_' + changeIndex}>
                      <div className="attribute">{`${intl.formatMessage({
                        id: I18nKey.CHANGES_LOG_HEADER_ATTRIBUTES,
                      })}`}</div>
                      <div className="value">{`${intl.formatMessage({
                        id: I18nKey.CHANGES_LOG_HEADER_OLD_STATE,
                      })}`}</div>
                      <div className="value">{`${intl.formatMessage({
                        id: I18nKey.CHANGES_LOG_HEADER_NEW_STATE,
                      })}`}</div>
                    </th>
                  </tr>
                  {keysList.map((attributeKey, attributeIndex) => {
                    if (
                      attributeKey === 'network' ||
                      attributeKey === 'organizations'
                    ) {
                      return formatNestedAttributes(
                        changeItem,
                        attributeKey,
                        changeIndex,
                        attributeIndex,
                      );
                    } else if (attributeKey === 'external_id') {
                      return null;
                    } else {
                      return (
                        <tr
                          key={
                            'attribute_tr_key_' +
                            changeIndex +
                            '_' +
                            attributeIndex
                          }
                        >
                          {formatAttributes(
                            changeItem,
                            attributeKey,
                            changeIndex,
                            attributeIndex,
                          )}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            );
          },
        )
      ) : (
        <span>
          {intl.formatMessage({ id: I18nKey.CHANGES_LOG_ERROR_NO_CHANGES })}
        </span>
      )}
    </div>
  );
};

export default ChangesLogContent;

import { useState } from 'react';
import { UserDataResponse } from 'api/SharedTypes';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  SubmitFormData,
} from 'services/Utils';

export const usePhysicianData = () => {
  const [userName, setUserName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [cellCountryCode, setCellCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [officeTelCountryCode, setOfficeTelCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [email, setEmail] = useState<string>('');
  const [degree, setDegree] = useState<string>('');
  const [speciality, setSpeciality] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [landline, setLandline] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [officeAddr1, setOfficeAddr1] = useState<string>('');
  const [officeAddr2, setOfficeAddr2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [licenseNumber, setLicenseNumber] = useState<string>('');
  const [graduationYear, setGraduationYear] = useState<string>('');
  const [deaNumber, setDeaNumber] = useState<string>('');
  const [npi, setNpi] = useState<string>('');
  const [remoteMonitoring, setRemoteMonitoring] = useState<string>('N');
  const [verifyEmailChecked, setVerifyEmailChecked] = useState<boolean>(false);

  const [physicianData, setPhysicianData] = useState<UserDataResponse>(
    EMPTY_USER_RESPONSE_DATA,
  );

  const physicianFormData = {
    userName,
    firstName,
    lastName,
    cellCountryCode,
    officeTelCountryCode,
    email,
    degree,
    speciality,
    groupName,
    mobile,
    landline,
    gender,
    officeAddr1,
    officeAddr2,
    city,
    state,
    zipCode,
    licenseNumber,
    graduationYear,
    deaNumber,
    npi,
    remoteMonitoring,
    verifyEmailChecked,
  };

  const physicianFormActions = {
    setUserName,
    setFirstName,
    setLastName,
    setCellCountryCode,
    setOfficeTelCountryCode,
    setEmail,
    setDegree,
    setSpeciality,
    setGroupName,
    setMobile,
    setLandline,
    setGender,
    setOfficeAddr1,
    setOfficeAddr2,
    setCity,
    setState,
    setZipCode,
    setLicenseNumber,
    setGraduationYear,
    setDeaNumber,
    setNpi,
    setRemoteMonitoring,
    setPhysicianData,
    setVerifyEmailChecked,
  };

  const setPhysicianFormData = (data: UserDataResponse) => {
    setUserName(data.username);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setCellCountryCode(
      data.cell_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setOfficeTelCountryCode(
      data.office_tel_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setEmail(data.email);
    setDegree(data.degree!);
    setSpeciality(data.specialty!);
    setGroupName(data.group!);
    setMobile(data.cell);
    setLandline(data.office_tel!);
    setGender(data.gender);
    setOfficeAddr1(data.office_addr_1!);
    setOfficeAddr2(data.office_addr_2!);
    setCity(data.address_city);
    setState(data.state);
    setZipCode(data.address_zip);
    setLicenseNumber(data.drive_license_number);
    setGraduationYear(data.year_grad_med_school!);
    setRemoteMonitoring(data.remote_monitoring);
    setDeaNumber(data.dea_number!);
    setNpi(data.npi!);
  };

  const clearForm = () => {
    setUserName('');
    setFirstName('');
    setLastName('');
    setCellCountryCode(ExportConstants.countryCodeOptions[0].value.toString());
    setOfficeTelCountryCode(
      ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setEmail('');
    setDegree('');
    setSpeciality('');
    setGroupName('');
    setMobile('');
    setLandline('');
    setGender('');
    setOfficeAddr1('');
    setOfficeAddr2('');
    setCity('');
    setState('');
    setZipCode('');
    setLicenseNumber('');
    setRemoteMonitoring('N');
    setGraduationYear('');
    setDeaNumber('');
    setNpi('');
  };

  const getFormSubmitData = (): SubmitFormData =>
    verifyEmailChecked
      ? {
          username: userName,
          first_name: firstName,
          last_name: lastName,
          email: email,
          degree: degree,
          group_name: groupName,
          cell_country_code: cellCountryCode,
          office_tel_country_code: officeTelCountryCode,
          specialty: speciality,
          gender: gender,
          office_tel: landline.replaceAll('-', ''),
          cell: mobile.replaceAll('-', ''),
          office_addr_1: officeAddr1,
          office_addr_2: officeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          year_grad_med_school: graduationYear,
          dea_number: deaNumber,
          remote_monitoring: remoteMonitoring!,
          npi: npi,
          email_verified: verifyEmailChecked,
        }
      : {
          username: userName,
          first_name: firstName,
          last_name: lastName,
          email: email,
          degree: degree,
          group_name: groupName,
          cell_country_code: cellCountryCode,
          office_tel_country_code: officeTelCountryCode,
          specialty: speciality,
          gender: gender,
          office_tel: landline.replaceAll('-', ''),
          cell: mobile.replaceAll('-', ''),
          office_addr_1: officeAddr1,
          office_addr_2: officeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          year_grad_med_school: graduationYear,
          dea_number: deaNumber,
          remote_monitoring: remoteMonitoring!,
          npi: npi,
        };

  return {
    physicianUserData: physicianData,
    physicianFormData,
    physicianFormActions,
    setPhysicianFormData,
    clearForm,
    getFormSubmitData,
  };
};

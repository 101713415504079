import React, { useState, useEffect, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import { Button, CircularProgress, MenuItem, Select } from '@mui/material';
import { withStyles } from '@mui/styles';
import { createApiClient } from 'api/apiClient';
import { useQuery, useMutation } from 'react-query';
import { UserContext } from 'contexts/userContext';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

function RPMDeviceOrdersHistory() {
  const { userData } = useContext(UserContext);
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();

  const [orderHistoryData, setOrderHistoryData] = useState<any[]>([]);
  const [orderHistoryDataLength, setOrderHistoryDataLength] =
    useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortBy, setSortBy] = useState('order_datetime');
  const [sortOrder, setSortOrder] = useState('desc');

  const OrgID = userData?.userOrg;

  const apiClient = createApiClient(accessToken!, controller);

  const {
    isLoading,
    refetch: refresh,
    data,
  } = useQuery('get-rpm-device-order-history', () =>
    apiClient.getRpmDeviceOrderHistory(OrgID as number),
  );

  useEffect(() => {
    setOrderHistoryData(data);
  }, [data]);

  useEffect(() => {
    setOrderHistoryDataLength(orderHistoryData?.length);
  }, [orderHistoryData]);

  useEffect(() => {
    if (data) {
      const sortedData = [...data].sort((a, b) => {
        if (sortOrder === 'asc') {
          return new Date(a[sortBy]).getTime() - new Date(b[sortBy]).getTime();
        } else {
          return new Date(b[sortBy]).getTime() - new Date(a[sortBy]).getTime();
        }
      });
      setOrderHistoryData(sortedData);
      setOrderHistoryDataLength(sortedData.length);
    }
  }, [data, sortBy, sortOrder]);

  const columns = [
    { id: 'Sr', label: 'Sr. No.', minWidth: 17 },
    { id: 'dt', label: 'Device Type', minWidth: 170 },
    { id: 'pn', label: 'Vendor Name', minWidth: 170 },
    { id: 'ob', label: 'Ordered By', minWidth: 170 },
    { id: 'qt', label: 'Quantity', minWidth: 170 },
    { id: 'od', label: 'Order Date', minWidth: 170 },
  ];

  const Refresh = withStyles({
    root: {
      margin: '10px',
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);

  const handleRefresh = () => {
    refresh();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (column: string) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleSortOrderChange = event => {
    setSortOrder(event.target.value);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'right',
              width: '100%',
              justifyContent: 'right',
              justifyItems: 'right',
              marginTop: -30,
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: '117px', marginTop: 10 }}
            >
              <Select value={sortOrder} onChange={handleSortOrderChange}>
                <MenuItem value="asc">Old to New</MenuItem>
                <MenuItem value="desc">New to Old</MenuItem>
              </Select>
            </FormControl>
            <Refresh
              style={{
                width: 170,
              }}
              onClick={handleRefresh}
            >
              {'🔄   Refresh'}
            </Refresh>
          </div>

          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                {/* <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={'center'}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === 'od' ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {column.label}
                            <FormControl
                              variant="outlined"
                              size="small"
                              style={{ minWidth: '120px', marginLeft: '8px' }}
                            >
                              <Select
                                value={sortOrder}
                                onChange={handleSortOrderChange}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                  },
                                }}
                              >
                                <MenuItem value="asc">Old to New</MenuItem>
                                <MenuItem value="desc">New to Old</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        ) : (
                          <span>{column.label}</span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead> */}

                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={'center'}
                        style={{ minWidth: column.minWidth }}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                        {sortBy === column.id && (
                          <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orderHistoryData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map((order, index) => {
                      return (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? '#ddeffd' : 'inherit',
                          }}
                        >
                          <TableCell
                            key={'sn'}
                            component="th"
                            scope="col"
                            align={'center'}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell key={'dt'} align={'center'}>
                            {order?.device_type}
                          </TableCell>
                          <TableCell key={'pn'} align={'center'}>
                            {order?.device_provider_name}
                          </TableCell>
                          <TableCell key={'ob'} align={'center'}>
                            {order?.order_by}
                          </TableCell>
                          <TableCell key={'qu'} align={'center'}>
                            {order?.quantity}
                          </TableCell>
                          <TableCell key={'od'} align={'center'}>
                            {order
                              ? new Date(
                                  order.order_datetime,
                                ).toLocaleDateString()
                              : ''}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component={'div'}
            count={orderHistoryDataLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
          />
        </>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
    </>
  );
}

export default RPMDeviceOrdersHistory;

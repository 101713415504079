import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { NewMessagesLog } from './NewMessagesLog';
import { OldMessagesLog } from './OldMessagesLog';

const MessagesLog: React.FC = () => {
  const [showOldChatHistory, setShowOldChatHistory] = useState<boolean>(false);

  const onShowOldChatHistoryClick = () => setShowOldChatHistory(true);

  return (
    <div className="messageLogCollectionContainer" style={{ height: '100%' }}>
      <NewMessagesLog showOldChatHistory={showOldChatHistory} />
      {!showOldChatHistory && (
        <div
          style={{
            width: '100%',
            height: '80px',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Button
            color="info"
            variant="contained"
            onClick={onShowOldChatHistoryClick}
          >
            Show Old Chat History
          </Button>
        </div>
      )}
      {showOldChatHistory && <OldMessagesLog />}
    </div>
  );
};

export default MessagesLog;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import UsersList from '../../components/UsersList';
import { CircularProgress } from '@mui/material';
import isArray from 'lodash/isArray';
import { createApiClient } from 'api/apiClient';
import TabTopButton from 'components/TabTopButton';
import { GetUserListResponse, UpdateUserResponse, User } from 'api/SharedTypes';
import { FormFieldObject } from 'components/UserForm';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  FormFieldType,
  SubmitFormData,
  UserRole,
} from 'services/Utils';
import { UpdateDialog } from 'components/UpdateDialog';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/I18nKeys';
import { ExceptionDialog } from '../../components/ExceptionDialog';
import { NavRoutes } from '../NavRouter';
import {
  sortByName,
  validateEmail,
  validateName,
} from '../../services/utilsFunctions';
import './style.css';
import { useNurseData } from './useNurseData';
import { useMutation } from 'react-query';
import { UserContextState, UserContext } from 'contexts/userContext';
import { useProviderDegrees } from 'services/useProviderDegrees';

const Nurses: React.FC = () => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const {
    clearForm,
    getFormSubmitData,
    nurseFormActions,
    nurseFormData,
    nurseUserData,
    setNurseFormData,
  } = useNurseData();

  const providerDegreeOptions = useProviderDegrees();

  const intl = useIntl();

  const accessToken = localStorage.getItem('access_token');
  const controller = useMemo(() => new AbortController(), []);
  const apiClient = createApiClient(accessToken!, controller);

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [isExceptionDialogOpen, setIsExceptionDialogOpen] =
    useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [existingUserData, setExistingUserData] = useState<User>({});

  const {
    isLoading,
    mutate: getNurseList,
    isError,
    data: nursesListResp,
  } = useMutation(
    'nurse-list',
    async (loadUpdatedData?: boolean) =>
      apiClient.getNursesList(loadUpdatedData),
    {
      onError: error => {
        console.log('error ', error);
      },
    },
  );

  const { isLoading: isLoadingNurseData, mutate: getNurseData } = useMutation(
    'get-nurse-data',
    async (id: number) => apiClient.getUserData(id, UserRole.provider),
    {
      onSuccess: response => {
        setNurseFormData(response);
        nurseFormActions.setNurseData(response);
      },
      onError: () => {
        handleUpdateDialogClose();
      },
    },
  );

  const { isLoading: isUpdatingNurseData, mutate: updateNurseData } =
    useMutation(
      'update-nurse-data',
      async (params: { details: SubmitFormData; id: number }) =>
        apiClient.updateProviderData(params.details, params.id),
      {
        onSuccess: response => {
          if (response.errorCode) {
            setExceptionDialogData(response);
          } else {
            handleUpdateDialogClose();
            getNurseList(false);
          }
        },
      },
    );

  const nurses = useMemo(() => {
    if (nursesListResp) {
      if (isArray(nursesListResp)) {
        return sortByName(nursesListResp) as GetUserListResponse[];
      }
    }
    return [];
  }, [nursesListResp]);

  const handleExceptionDialogClose = () => setIsExceptionDialogOpen(false);

  const handleExceptionDialogOpen = () => setIsExceptionDialogOpen(true);

  const setExceptionDialogData = (response: UpdateUserResponse) => {
    handleExceptionDialogOpen();
    setErrorCode(response.errorCode!);
    setExistingUserData(response.existing_data!);
  };

  const handleUpdateDialogOpen = () => {
    setIsUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    clearForm();
    nurseFormActions.setProviderId(null);
    nurseFormActions.setNurseData(EMPTY_USER_RESPONSE_DATA);
    setIsUpdateDialogOpen(false);
  };

  const onNurseClick = (id: number) => {
    handleUpdateDialogOpen();
    nurseFormActions.setProviderId(id);
    getNurseData(id);
  };

  const updateNurse = async () => {
    const data = getFormSubmitData();
    updateNurseData({
      details: { ...data, role: UserRole.nurse },
      id: nurseFormData.providerId!,
    });
    nurseFormActions.setVerifyEmailChecked(false);
  };

  const fetchNurses = useCallback(
    (loadUpdatedData?: boolean) => getNurseList(loadUpdatedData),
    [getNurseList],
  );

  const getUserName = () => {
    if (
      nurseFormData.firstName &&
      nurseFormData.lastName &&
      !nurseFormData.userName
    ) {
      apiClient
        .getUserName(nurseFormData.firstName, nurseFormData.lastName)
        .then(response => nurseFormActions.setUserName(response));
    }
  };

  const handleRemoteMonitoring = () => {
    nurseFormData.remoteMonitoring === 'Y'
      ? nurseFormActions.setRemoteMonitoring('N')
      : nurseFormActions.setRemoteMonitoring('Y');
  };

  const nursesFields: FormFieldObject = {
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.firstName,
      onChange: value => nurseFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      onBlur: getUserName,
      isError: nurseFormData.firstName
        ? !validateName(nurseFormData.firstName)
        : false,
      errorText:
        !validateName(nurseFormData.firstName) && nurseFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.lastName,
      onChange: value => nurseFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      onBlur: getUserName,
      isError: nurseFormData.firstName
        ? !validateName(nurseFormData.lastName)
        : false,
      errorText:
        !validateName(nurseFormData.lastName) && nurseFormData.lastName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      isDisabled: true,
      value: nurseFormData.userName,
      onChange: value => nurseFormActions.setUserName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_USERNAME,
      }),
    },
    degree: {
      type: FormFieldType.select,
      selectOptions: providerDegreeOptions,
      isRequired: true,
      value: nurseFormData.degree,
      onChange: value => nurseFormActions.setDegree(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DEGREE,
      }),
      isDisabled: isReadOnly,
    },
    email: {
      type: FormFieldType.email,
      isRequired: true,
      value: nurseFormData.email.toLowerCase(),
      onChange: value => nurseFormActions.setEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: nurseFormData.email
        ? !validateEmail(nurseFormData.email)
        : false,
      errorText:
        !validateEmail(nurseFormData.email) && nurseFormData.email
          ? 'Please enter a valid email'
          : '',
      isDisabled: isReadOnly,
    },
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        nurseFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
      hide: true,
    },

    speciality: {
      type: FormFieldType.select,
      isRequired: true,
      value: nurseFormData.speciality,
      onChange: value => nurseFormActions.setSpeciality(value),
      selectOptions: ExportConstants.specialtyOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_SPECIALITY,
      }),
      isDisabled: isReadOnly,
    },
    office_tel_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: nurseFormData.officeTelCountryCode,
      onChange: value => nurseFormActions.setOfficeTelCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    office_tel: {
      type: FormFieldType.mask,
      isRequired: true,
      value: nurseFormData.landline,
      onChange: value => nurseFormActions.setLandline(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE,
      }),
      isDisabled: isReadOnly,
    },
    cell_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: nurseFormData.cellCountryCode,
      onChange: value => nurseFormActions.setCellCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_CELL_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    cell: {
      type: FormFieldType.mask,
      isRequired: true,
      value: nurseFormData.mobile,
      onChange: value => nurseFormActions.setMobile(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_MOBILE_NUMBER,
      }),
      isDisabled: isReadOnly,
    },
    group_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.groupName,
      onChange: value => nurseFormActions.setGroupName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_GROUP_NAME,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    office_addr_1: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.officeAddr1,
      onChange: value => nurseFormActions.setOfficeAddr1(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_OFFICE_ADDRESS_1,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    office_addr_2: {
      type: FormFieldType.text,
      isRequired: false,
      value: nurseFormData.officeAddr2,
      onChange: value => nurseFormActions.setOfficeAddr2(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_OFFICE_ADDRESS_2,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },

    address_city: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.city,
      onChange: value => nurseFormActions.setCity(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADDRESS_CITY,
      }),
      maxLength: 20,
      isDisabled: isReadOnly,
    },

    state: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.stateOptions,
      value: nurseFormData.state,
      onChange: value => nurseFormActions.setState(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_STATE,
      }),
      isDisabled: isReadOnly,
    },

    address_zip: {
      type: FormFieldType.mask,
      isRequired: true,
      mask: '######',
      value: nurseFormData.zipCode,
      onChange: value => nurseFormActions.setZipCode(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ZIP_CODE,
      }),
      isDisabled: isReadOnly,
    },
    licenseNumber: {
      type: FormFieldType.text,
      isRequired: false,
      value: nurseFormData.licenseNumber,
      onChange: value => nurseFormActions.setLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LICENSE_NUMBER,
      }),
      maxLength: 25,
      isDisabled: isReadOnly,
    },
    nursingLicenseNumber: {
      type: FormFieldType.text,
      isRequired: false,
      value: nurseFormData.nursingLicenseNumber,
      onChange: value => nurseFormActions.setNursingLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_NURSING_LICENSE_NUMBER,
      }),
      isDisabled: isReadOnly,
    },
  };

  const nursesUpdateFields: FormFieldObject = {
    ...nursesFields,
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        nurseFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
    },
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.firstName,
      onChange: value => nurseFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      maxLength: 16,
      isDisabled: isReadOnly,
    },

    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.lastName,
      onChange: value => nurseFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      value: nurseFormData.userName,
      label: intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_USERNAME }),
      isDisabled: true,
    },
  };

  useEffect(() => {
    fetchNurses();
    return () => {
      controller.abort();
    };
  }, [controller, fetchNurses]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <TabTopButton
        role={UserRole.nurse}
        getFormSubmitData={getFormSubmitData}
        clearForm={clearForm}
        formFields={nursesFields}
        fetchNewDataList={fetchNurses}
        handleRemoteMonitoring={handleRemoteMonitoring}
        remoteMonitoring={nurseFormData.remoteMonitoring!}
      />
      {isLoading ? (
        <div className="nurseListCenterLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="nurseListCenterLoading">
          <div className="red">Failed to Fetch Nurse List</div>
        </div>
      ) : (
        <UsersList
          users={nurses}
          routes={NavRoutes.nurses}
          onUserClick={onNurseClick}
          fetchNewDataList={fetchNurses}
        />
      )}
      <UpdateDialog
        handleClose={handleUpdateDialogClose}
        open={isUpdateDialogOpen}
        userData={nurseUserData}
        role={UserRole.nurse}
        handleUpdate={updateNurse}
        formFields={nursesUpdateFields}
        remoteMonitoring={nurseFormData.remoteMonitoring!}
        handleRemoteMonitoring={handleRemoteMonitoring}
        userDataLoading={isLoadingNurseData}
        isUserDataUpdating={isUpdatingNurseData}
      />
      <ExceptionDialog
        open={isExceptionDialogOpen}
        handleClose={handleExceptionDialogClose}
        errorCode={errorCode}
        handleAddUserDialogClose={handleUpdateDialogClose}
        enteredFormData={getFormSubmitData()}
        existingUserData={existingUserData}
        role={UserRole.nurse}
        fetchNewDataList={fetchNurses}
      />
    </div>
  );
};

export default Nurses;

import sortBy from 'lodash/sortBy';
import { IMEIDetailsResponse, UserDataResponse } from 'api/SharedTypes';

export interface SelectOption {
  readonly label: string;
  readonly value: string | number;
}

export enum FormFieldType {
  text = 'text',
  email = 'email',
  select = 'select',
  date = 'date',
  number = 'number',
  mask = 'mask',
  checkbox = 'checkbox',
}

export enum ProviderProps {
  degree = 'degree',
  alert_receiver = 'alert_receiver',
  specialty = 'specialty',
  groupName = 'group',
}

export enum Icons {
  settings_remote = `settings_remote`,
  notifications_on = `notifications_on`,
  notifications_off = `notifications_off`,
  warning = `warning`,
}

export enum UserRole {
  patient = 'patient',
  caregiver = 'caregiver',
  provider = 'provider',
  physician = 'physician',
  nurse = 'nurse',
  case_manager = 'case_manager',
  archived = 'archived',
}

export interface SubmitFormData {
  readonly [name: string]: string | boolean | number | null;
}

export const EMPTY_USER_RESPONSE_DATA: UserDataResponse = {
  activated: null,
  external_id: '',
  id: null,
  internal_id: null,
  ref_uid: '',
  remote_monitoring: '',
  username: '',
  address_zip: '',
  address_city: '',
  email: '',
  gender: '',
  drive_license_number: '',
  state: '',
  home_addr_1: '',
  home_addr_2: '',
  home_tel: '',
  role: '',
  last_name: '',
  dob: '',
  first_name: '',
  middle_name: '',
  cell_country_code: '+1',
  cell: '',
  name: '',
  device_pairing: '',
  start_date: '',
  physical_caregem: null,
  physical_rpm: null,
};

export const EMPTY_IMEI_RESPONSE_DATA: IMEIDetailsResponse = {
  active: '',
  end_date: '',
  imei: '',
  patient_internal_id: '',
  start_date: '',
};

const stateOptions: SelectOption[] = sortBy(
  [
    { label: 'Alabama', value: 'Alabama' },
    { label: 'Alaska', value: 'Alaska' },
    { label: 'Arizona', value: 'Arizona' },
    { label: 'Arkansas', value: 'Arkansas' },
    { label: 'California', value: 'California' },
    { label: 'Colorado', value: 'Colorado' },
    {
      label: 'Connecticut',
      value: 'Connecticut',
    },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Florida', value: 'Florida' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Hawaii', value: 'Hawaii' },
    { label: 'Idaho', value: 'Idaho' },
    { label: 'Illinois', value: 'Illinois' },
    { label: 'Indiana', value: 'Indiana' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Kansas', value: 'Kansas' },
    { label: 'Kentucky', value: 'Kentucky' },
    { label: 'Louisiana', value: 'Louisiana' },
    { label: 'Maine', value: 'Maine' },
    { label: 'Maryland', value: 'Maryland' },
    {
      label: 'Massachusetts',
      value: 'Massachusetts',
    },
    { label: 'Michigan', value: 'Michigan' },
    { label: 'Minnesota', value: 'Minnesota' },
    {
      label: 'Mississippi',
      value: 'Mississippi',
    },
    { label: 'Missouri', value: 'Missouri' },
    { label: 'Montana', value: 'Montana' },
    { label: 'Nebraska', value: 'Nebraska' },
    { label: 'Nevada', value: 'Nevada' },
    {
      label: 'New Hampshire',
      value: 'New Hampshire',
    },
    { label: 'New Jersey', value: 'New Jersey' },
    { label: 'New Mexico', value: 'New Mexico' },
    { label: 'New York', value: 'New York' },
    {
      label: 'North Carolina',
      value: 'North Carolina',
    },
    {
      label: 'North Dakota',
      value: 'North Dakota',
    },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Oklahoma', value: 'Oklahoma' },
    { label: 'Oregon', value: 'Oregon' },
    {
      label: 'Pennsylvania',
      value: 'Pennsylvania',
    },
    {
      label: 'Rhode Island',
      value: 'Rhode Island',
    },
    {
      label: 'South Carolina',
      value: 'South Carolina',
    },
    {
      label: 'South Dakota',
      value: 'South Dakota',
    },
    { label: 'Tennessee', value: 'Tennessee' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Utah', value: 'Utah' },
    { label: 'Vermont', value: 'Vermont' },
    { label: 'Virginia', value: 'Virginia' },
    { label: 'Washington', value: 'Washington' },
    {
      label: 'West Virginia',
      value: 'West Virginia',
    },
    { label: 'Wisconsin', value: 'Wisconsin' },
    { label: 'Wyoming', value: 'Wyoming' },
  ],
  'label',
);

const degreeOptions: SelectOption[] = sortBy(
  [
    { label: 'MD', value: 'MD' },
    { label: 'DO', value: 'DO' },
    { label: 'RN', value: 'RN' },
    { label: 'APN', value: 'APN' },
    { label: 'CNS', value: 'CNS' },
    { label: '(NONE)', value: '(NONE)' },
    { label: 'MB.BS', value: 'MB.BS' },
  ],
  'label',
);

const specialtyOptions: SelectOption[] = sortBy(
  [
    { label: 'Cardiology', value: 'Cardiology' },
    { label: 'Int Med', value: 'Int Med' },
    {
      label: 'Fam Practice',
      value: 'Fam Practice',
    },
    { label: 'Neurology', value: 'Neurology' },
    {
      label: 'Gastroenterology',
      value: 'Gastroenterology',
    },
    { label: 'Gen Surg', value: 'Gen Surg' },
    { label: 'Nephrology', value: 'Nephrology' },
    { label: 'Urology', value: 'Urology' },
    {
      label: 'Rheumatology',
      value: 'Rheumatology',
    },
    { label: 'Pain Mgmt', value: 'Pain Mgmt' },
    { label: 'ENT', value: 'ENT' },
    { label: 'Neurosurg', value: 'Neurosurg' },
    {
      label: 'Ophthalmology',
      value: 'Ophthalmology',
    },
    {
      label: 'Transplant surg',
      value: 'Transplant surg',
    },
    {
      label: 'Transplant hepatology',
      value: 'Transplant hepatology',
    },
    { label: 'Ortho', value: 'Ortho' },
    { label: 'OB/GYN', value: 'OB/GYN' },
    { label: 'Pediatrics', value: 'Pediatrics' },
    {
      label: 'Pulmonology',
      value: 'Pulmonology',
    },
    {
      label: 'Endocrinology',
      value: 'Endocrinology',
    },
    {
      label: 'Interventional Rad',
      value: 'Interventional Rad',
    },
    {
      label: 'Cardiovasc Surg',
      value: 'Cardiovasc Surg',
    },
    {
      label: 'Thoracic surg',
      value: 'Thoracic surg',
    },
    { label: 'Anesthesia', value: 'Anesthesia' },
    {
      label: 'Plastic Surg',
      value: 'Plastic Surg',
    },
    { label: 'Hem/Onc', value: 'Hem/Onc' },
    {
      label: 'Infectious Dis',
      value: 'Infectious Dis',
    },
    { label: 'PM&R', value: 'PM&R' },
    { label: 'Rad Onc', value: 'Rad Onc' },
    {
      label: 'Emergency Med',
      value: 'Emergency Med',
    },
    {
      label: 'Dermatology',
      value: 'Dermatology',
    },
    { label: 'Podiatry', value: 'Podiatry' },
    { label: 'Nurse', value: 'Nurse' },
    {
      label: 'Adv Practice Nurse',
      value: 'Adv Practice Nurse',
    },
    {
      label: 'Case Manager',
      value: 'Case Manager',
    },
    {
      label: 'Social Worker',
      value: 'Social Worker',
    },
    {
      label: 'Transplant Neph',
      value: 'Transplant Neph',
    },
    {
      label: 'Medical Assistant',
      value: 'Medical Assistant',
    },
    {
      label: 'Nephrology ROM',
      value: 'Nephrology ROM',
    },
    {
      label: 'Nephrology ROS',
      value: 'Nephrology ROS',
    },
    {
      label: 'Nephrology ESRD',
      value: 'Nephrology ESRD',
    },
    {
      label: 'Nephrology CKD',
      value: 'Nephrology CKD',
    },
    {
      label: 'Care coordinator',
      value: 'Care coordinator',
    },
  ],
  'label',
);

const genderOptions: SelectOption[] = sortBy(
  [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ],
  'label',
);

const adminReadOnlyOptions: SelectOption[] = sortBy(
  [
    { value: 1, label: 'True' },
    { value: 0, label: 'False' },
  ],
  'label',
);

const countryCodeOptions: SelectOption[] = sortBy(
  [
    { value: '+1', label: '+1 (USA)' },
    { value: '+91', label: '+91 (India)' },
  ],
  'label',
);

const ExportConstants = {
  stateOptions,
  degreeOptions,
  specialtyOptions,
  genderOptions,
  adminReadOnlyOptions,
  countryCodeOptions,
};

export default ExportConstants;

import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


const PaginationExceptionComponent = ({
  totalItems,
  itemsPerPage,
  isLoading,
  onPageChange,
  setCurrentPage,
  currentPage
}) => {

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const validatedCount = Number.isInteger(totalPages) ? totalPages : 0;

  const handleChange = (event: any, value: number) => {
    onPageChange(value);
    setCurrentPage(value);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Stack
      spacing={2}
      style={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        padding: 10,
      }}
    >
      <Pagination count={validatedCount} page={currentPage} onChange={handleChange} />
    </Stack>
  );
};

export default PaginationExceptionComponent;

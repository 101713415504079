import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Pagination } from '@mui/material';
import { createApiClient } from 'api/apiClient';
import { AdditionalRouteLabel } from 'components/CaregemNavBar';
import './Exceptions.css';
import { ExceptionListItem } from './ExceptionListItem';
import { useMutation } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import PaginationExceptionComponent from './PaginationException';



const Exceptions: React.FC = () => {
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const {
    isLoading,
    mutate: getExceptionList,
    isError,
    data: exceptionsListResp,
  } = useMutation('exception-list', async () => apiClient.getExceptions(currentPage));

  useEffect(() => {
    getExceptionList();
    return () => {
      controller.abort();
    };
  }, []);

  const exceptions = useMemo(() => {
    // console.log("exceptionsListResp", exceptionsListResp && exceptionsListResp.data)
    if (exceptionsListResp) {
      return exceptionsListResp?.data?.map((item: any, index: number) => ({
        ...item,
        index: index.toString(),
      }));
    }
    return [];
  }, [exceptionsListResp]);

  const [currentItems, setCurrentItems] = useState<any>([]);
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState<any>(1);


//   useEffect(() => {
// console.log("currentItems", currentPage)
//   }, [currentPage])

  const handlePageChange = (page: any) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentItems(exceptionsListResp?.data?.slice(startIndex, endIndex));
  };

  useEffect(() => {
    getExceptionList()
  },[currentPage])

  useEffect(() => {
    handlePageChange(1);
  }, [exceptionsListResp]);

  return (
    <>
      <div className="pageTitle">{AdditionalRouteLabel.exceptions}</div>
      {isLoading ? (
        <div className="exceptionsLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="exceptionsLoading">
          <div className="red">Failed to load Exceptions</div>
        </div>
      ) : isEmpty(exceptions) ? (
        <div className="exceptionsLoading">
          <div>No Data</div>
        </div>
      ) : (
        <div style={{ width: '80%', margin: 'auto' }}>
          {exceptions.map((item: any, index: React.Key | null | undefined) => (
            <div key={index} style={{ width: '100%' }}>
              <ExceptionListItem
                exceptionItem={item}
                getExceptionList={getExceptionList}
              />
              
            </div>
          ))}
         <PaginationExceptionComponent 
            totalItems={exceptionsListResp?.total_count}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            isLoading={isLoading}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            />

        </div>
        

      )}
    </>
  );
};
export default Exceptions;

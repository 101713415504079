import React from 'react';
import { FormFieldData } from 'components/UserForm';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';

interface MaskedFieldProps {
  readonly fieldItem: FormFieldData;
}

const MaskedField: React.FC<MaskedFieldProps> = ({ fieldItem }) => {
  return (
    <NumberFormat
      customInput={TextField}
      variant="standard"
      value={fieldItem.value}
      fullWidth
      format={fieldItem.mask}
      label={fieldItem.label}
      required={fieldItem.isRequired}
      onChange={e => {
        fieldItem.onChange?.(e.target.value);
      }}
      disabled={fieldItem.isDisabled}
    />
  );
};
export default MaskedField;

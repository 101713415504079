export enum Colors {
    Grey = "#636A79",
    Black = "#212b3f",
    BlackSqueeze = "#F5F9FC",
    DodgerBlue = "#1f8cff",
    White = "#fff",
    AliceBlue = "#eaf3fc",
    BabyBlue = "#ebf4f8",
    GhostWhite = "#f9fdff",
    Red = "#f31e13",
    Polar = "#EBF3FB",
    Mantis = "#7cba5b",
    WillowBrook = "#F2F7EF",
    Froly = "#F27B6E",
    Chablis = "#FFF4F3",
    MediumPurple = "#9D62DE",
    Mangolia = "#F6F0FF",
    GoldenDream = "#F1D22F",
    CitrineWhite = "#FBF7E1",
    LightGrey = "#878d99",
    Lavender = "#E2EDF3",
    TurquoiseBlue = "#55CDE6",
    Selago = "#EFF7FD",
    Beige = "#eff4df",
    gothicRegular = "#979dab",
    gothicRegularLight = "#d3d8e4",
    gothicBold = "#212b40",
    gothicBoldLight = "#636a79",
    Gainsboro = "#dcdfe4",
    HighlightGreen = "#CBF28B"
  }
  
import { useState } from 'react';
import { UserDataResponse } from 'api/SharedTypes';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  SubmitFormData,
} from 'services/Utils';

export const useNurseData = () => {
  const [userName, setUserName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [cellCountryCode, setCellCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [officeTelCountryCode, setOfficeTelCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [email, setEmail] = useState<string>('');
  const [degree, setDegree] = useState<string>('');
  const [speciality, setSpeciality] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [landline, setLandline] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [officeAddr1, setOfficeAddr1] = useState<string>('');
  const [officeAddr2, setOfficeAddr2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [licenseNumber, setLicenseNumber] = useState<string>('');
  const [nursingLicenseNumber, setNursingLicenseNumber] = useState<string>('');
  const [providerId, setProviderId] = useState<number | null>(null);
  const [remoteMonitoring, setRemoteMonitoring] = useState<string>('N');
  const [verifyEmailChecked, setVerifyEmailChecked] = useState<boolean>(false);

  const [nurseData, setNurseData] = useState<UserDataResponse>(
    EMPTY_USER_RESPONSE_DATA,
  );

  const nurseFormData = {
    userName,
    firstName,
    lastName,
    cellCountryCode,
    officeTelCountryCode,
    email,
    degree,
    speciality,
    groupName,
    mobile,
    landline,
    gender,
    officeAddr1,
    officeAddr2,
    city,
    state,
    zipCode,
    licenseNumber,
    nursingLicenseNumber,
    providerId,
    remoteMonitoring,
    verifyEmailChecked,
  };

  const nurseFormActions = {
    setUserName,
    setFirstName,
    setLastName,
    setCellCountryCode,
    setOfficeTelCountryCode,
    setEmail,
    setDegree,
    setSpeciality,
    setGroupName,
    setMobile,
    setLandline,
    setGender,
    setOfficeAddr1,
    setOfficeAddr2,
    setCity,
    setState,
    setZipCode,
    setLicenseNumber,
    setNursingLicenseNumber,
    setProviderId,
    setRemoteMonitoring,
    setNurseData,
    setVerifyEmailChecked,
  };

  const setNurseFormData = (data: UserDataResponse) => {
    setUserName(data.username);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setCellCountryCode(
      data.cell_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setOfficeTelCountryCode(
      data.office_tel_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setEmail(data.email);
    setDegree(data.degree!);
    setSpeciality(data.specialty!);
    setGroupName(data.group!);
    setMobile(data.cell);
    setLandline(data.office_tel!);
    setGender(data.gender);
    setOfficeAddr1(data.office_addr_1!);
    setOfficeAddr2(data.office_addr_2!);
    setCity(data.address_city);
    setState(data.state);
    setZipCode(data.address_zip);
    setLicenseNumber(data.drive_license_number);
    setRemoteMonitoring(data.remote_monitoring);
    setNursingLicenseNumber(data.nursing_license_number!);
  };

  const clearForm = () => {
    setUserName('');
    setFirstName('');
    setLastName('');
    setCellCountryCode(ExportConstants.countryCodeOptions[0].value.toString());
    setOfficeTelCountryCode(
      ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setEmail('');
    setDegree('');
    setSpeciality('');
    setGroupName('');
    setMobile('');
    setLandline('');
    setGender('');
    setOfficeAddr1('');
    setOfficeAddr2('');
    setCity('');
    setState('');
    setZipCode('');
    setLicenseNumber('');
    setNursingLicenseNumber('');
    setRemoteMonitoring('N');
    setProviderId(null);
  };

  const getFormSubmitData = (): SubmitFormData =>
    verifyEmailChecked
      ? {
          username: userName,
          first_name: firstName,
          last_name: lastName,
          email: email,
          degree: degree,
          group_name: groupName,
          specialty: speciality,
          cell_country_code: cellCountryCode,
          office_tel_country_code: officeTelCountryCode,
          gender: gender,
          office_tel: landline.replaceAll('-', ''),
          cell: mobile.replaceAll('-', ''),
          office_addr_1: officeAddr1,
          office_addr_2: officeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          nursing_license_number: nursingLicenseNumber,
          remote_monitoring: remoteMonitoring!,
          email_verified: verifyEmailChecked,
        }
      : {
          username: userName,
          first_name: firstName,
          last_name: lastName,
          email: email,
          degree: degree,
          group_name: groupName,
          specialty: speciality,
          cell_country_code: cellCountryCode,
          office_tel_country_code: officeTelCountryCode,
          gender: gender,
          office_tel: landline.replaceAll('-', ''),
          cell: mobile.replaceAll('-', ''),
          office_addr_1: officeAddr1,
          office_addr_2: officeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          nursing_license_number: nursingLicenseNumber,
          remote_monitoring: remoteMonitoring!,
        };

  return {
    nurseUserData: nurseData,
    nurseFormData,
    nurseFormActions,
    setNurseFormData,
    clearForm,
    getFormSubmitData,
  };
};

import React, { useContext, useEffect, useState } from 'react';
import logo from 'images/caregem-logo.png';
import I18nKey from 'lib/I18nKeys';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from 'UserPool';
import './Login.css';
import { withStyles } from '@mui/styles';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Checkbox,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { UserContext, UserContextState } from 'contexts/userContext';
import { createApiClient } from 'api/apiClient';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { NavRoutes } from 'pages/NavRouter';

interface LoginProps {
  readonly setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setResetPage: React.Dispatch<React.SetStateAction<boolean>>;
}

enum InputType {
  text = 'text',
  password = 'password',
}

const SubmitButton = withStyles({
  root: {
    margin: '10px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const VISIBILITY_ICON_SIZE = 'small';

const Login: React.FC<LoginProps> = ({ setAuthenticated, setResetPage }) => {
  const userContext = useContext<UserContextState>(UserContext);
  const [userName, setuserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] =
    useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [isChecked, setChecked] = useState<boolean>(false);
  const [confirmedUserPasswordChange, setConfirmedUserPasswordChange] =
    useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  //false commit

  const onShowPasswordChange = () => setShowPassword(!showPassword);

  const onShowConfirmPasswordChange = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const navigate = useNavigate();

  const intl = useIntl();

  const logInUser = (
    username: string,
    password: string,
    controller: AbortController,
  ) => {
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    setIsLoading(true);

    const onAuthenticateSuccess = (accessToken: string) => {
      localStorage.setItem('access_token', accessToken);
      createApiClient(accessToken, controller)
        .getLoggedInUserData()
        .then(userData => {
          userContext.setLoggedInUserData(userData);
          userContext.setIsReadOnlyUser(userData.is_read_only);
          if (userData.role === 'customer_admin') {
            setIsLoading(false);
            setAuthenticated(true);
            navigate(NavRoutes.patients);
            return;
          }
          setIsLoading(false);
          setAuthenticated(false);
          sessionStorage.setItem(
            'access_denied',
            intl.formatMessage({ id: I18nKey.ACCESS_DENIED }),
          );
        })
        .catch(() => {
          setIsLoading(false);
          setAuthenticated(false);
          setErrorMessage('Failed to load user information');
          sessionStorage.setItem('access_denied', '');
        });
    };

    if (isNewPasswordRequired) {
      if (isCodeNum(password)) {
        user.confirmPassword(password, newPassword, {
          onSuccess: () => {
            const authDetails = new AuthenticationDetails({
              Username: username,
              Password: newPassword,
            });
            user.authenticateUser(authDetails, {
              onSuccess: data => {
                const accessToken = data.getAccessToken().getJwtToken();
                onAuthenticateSuccess(accessToken);
              },
              onFailure: err => {
                setIsLoading(false);
                setAuthenticated(false);
                setErrorMessage('Failed to load user information');
                sessionStorage.setItem('access_denied', '');
              },
            });
          },
          onFailure: err => {
            setIsLoading(false);
            setErrorMessage(intl.formatMessage({ id: I18nKey.ERROR_MESSAGE }));
            sessionStorage.setItem('access_denied', '');
          },
        });
      } else {
        cognitoUser?.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: user => {
              const accessToken = user.getAccessToken().getJwtToken();
              onAuthenticateSuccess(accessToken);
            },
            onFailure: error => {
              setIsLoading(false);
              setErrorMessage(
                intl.formatMessage({ id: I18nKey.ERROR_MESSAGE }),
              );
              sessionStorage.setItem('access_denied', '');
            },
          },
        );
      }
    } else {
      user.authenticateUser(authDetails, {
        onSuccess: data => {
          const accessToken = data.getAccessToken().getJwtToken();
          onAuthenticateSuccess(accessToken);
        },
        onFailure: err => {
          console.error('onFailure: ', err);
          setIsLoading(false);
          setErrorMessage(
            intl.formatMessage({ id: I18nKey.WRONG_USER_DETAILS_ERROR }),
          );
          sessionStorage.setItem('access_denied', '');
        },
        newPasswordRequired: data => {
          setIsLoading(false);
          setIsNewPasswordRequired(true);
        },
      });
      setCognitoUser(user);
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (errorMessage) {
      setErrorMessage('');
    }
    sessionStorage.setItem('access_denied', '');
    logInUser(userName, password, controller);
  };

  const handleCheck = () => {
    isChecked ? setChecked(false) : setChecked(true);
  };

  const isSubmitActive = () =>
    newPassword &&
    confirmNewPassword &&
    newPassword === confirmNewPassword &&
    isChecked
      ? false
      : true;

  const resetPage = () => {
    setResetPage(true);
  };

  const controller = new AbortController();

  const isCodeNum = (value: string) => {
    return /^\d+$/.test(value);
  };

  useEffect(() => {
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      setuserName(urlParams.get('username')!);
      setPassword(decodeURIComponent(urlParams.get('code')!));
      if (isCodeNum(decodeURIComponent(urlParams.get('code')!))) {
        setIsNewPasswordRequired(true);
        setConfirmedUserPasswordChange(true);
        setChecked(true);
      } else {
        logInUser(
          urlParams.get('username')!,
          decodeURIComponent(urlParams.get('code')!),
          controller,
        );
      }
    }
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!newPassword || !confirmNewPassword) return;
    if (newPassword !== confirmNewPassword) {
      setErrorMessage(intl.formatMessage({ id: I18nKey.PASSWORDS_DONT_MATCH }));
      sessionStorage.setItem('access_denied', '');
    } else {
      if (
        (newPassword.length < 8 || newPassword.length > 25) &&
        (confirmNewPassword.length < 8 || confirmNewPassword.length > 25)
      ) {
        setErrorMessage('Password length needs to be between 8-25 characters');
        sessionStorage.setItem('access_denied', '');
      } else {
        setErrorMessage('');
        sessionStorage.setItem('access_denied', '');
      }
    }
  }, [newPassword, confirmNewPassword, intl]);

  useEffect(() => {
    if (!password) return;
    if (password.length < 8 || password.length > 25) {
      // setErrorMessage('Password length needs to be between 8-25 characters');
      sessionStorage.setItem('access_denied', '');
    } else {
      setErrorMessage('');
      sessionStorage.setItem('access_denied', '');
    }
  }, [password]);

  return (
    <div className="login-page">
      <form
        onSubmit={onSubmit}
        className="col-md-6 col-md-offset-3 textCenter"
        noValidate
        autoComplete="off"
      >
        <img src={logo} alt="Caregem-logo" className="caregem-logo" />
        <h2 className="col-md-12 textCenter font20px">
          {isNewPasswordRequired
            ? intl.formatMessage({ id: I18nKey.CHANGE_PASSWORD_TEXT })
            : intl.formatMessage({ id: I18nKey.WELCOME_TEXT })}
        </h2>
        <p className="col-md-12 textLeft">
          {isNewPasswordRequired && !confirmedUserPasswordChange && (
            <FormattedMessage
              id={I18nKey.CHANGING_PASSWORD_DISCLAIMER_MESSAGE}
              values={{
                link: (
                  <a href="https://www.caregem.com/legal">
                    {'https://www.caregem.com/legal'}
                  </a>
                ),
              }}
            />
          )}
        </p>
        {isNewPasswordRequired && !confirmedUserPasswordChange && (
          <p className="col-md-3">
            <Checkbox checked={isChecked} onChange={handleCheck}></Checkbox>
            {intl.formatMessage({
              id: I18nKey.AGREE,
            })}
          </p>
        )}
        <Box
          sx={{
            '& .MuiTextField-root': {
              m: 1,
              width: '30ch',
            },
          }}
        >
          <TextField
            fullWidth
            variant="standard"
            type={
              isNewPasswordRequired
                ? showPassword
                  ? InputType.text
                  : InputType.password
                : InputType.text
            }
            value={isNewPasswordRequired ? newPassword : userName}
            required
            autoComplete="on"
            placeholder={
              isNewPasswordRequired
                ? intl.formatMessage({
                    id: I18nKey.USER_FORM_FIELD_NEW_PASSWORD,
                  })
                : intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_USERNAME })
            }
            name={isNewPasswordRequired ? 'newPassword' : 'username'}
            onChange={e =>
              isNewPasswordRequired
                ? setNewPassword(e.target.value)
                : setuserName(e.target.value)
            }
            InputProps={
              isNewPasswordRequired
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onShowPasswordChange}
                        >
                          {showPassword ? (
                            <Visibility fontSize={VISIBILITY_ICON_SIZE} />
                          ) : (
                            <VisibilityOff fontSize={VISIBILITY_ICON_SIZE} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            disabled={isNewPasswordRequired ? !isChecked : false}
          />
          <br />
          <TextField
            fullWidth
            variant="standard"
            type={showConfirmPassword ? InputType.text : InputType.password}
            value={isNewPasswordRequired ? confirmNewPassword : password}
            required
            name={isNewPasswordRequired ? 'confirmPassword' : 'password'}
            autoComplete="on"
            placeholder={
              isNewPasswordRequired
                ? intl.formatMessage({
                    id: I18nKey.USER_FORM_FIELD_CONFIRM_PASSWORD,
                  })
                : intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_PASSWORD })
            }
            onChange={e => {
              if (isNewPasswordRequired) {
                setConfirmNewPassword(e.target.value);
              } else {
                setPassword(e.target.value);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={onShowConfirmPasswordChange}
                  >
                    {showConfirmPassword ? (
                      <Visibility fontSize={VISIBILITY_ICON_SIZE} />
                    ) : (
                      <VisibilityOff fontSize={VISIBILITY_ICON_SIZE} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={isNewPasswordRequired ? !isChecked : false}
          />
          <div className="red">
            {isLoading ? (
              <CircularProgress />
            ) : (sessionStorage.getItem('access_denied') ?? '').length > 0 ? (
              sessionStorage.getItem('access_denied')!
            ) : (
              errorMessage
            )}
          </div>
        </Box>
        <SubmitButton
          variant="contained"
          type="submit"
          color="primary"
          disabled={isNewPasswordRequired ? isSubmitActive() : isLoading}
        >
          {isNewPasswordRequired
            ? `${intl.formatMessage({
                id: I18nKey.SUBMIT,
              })}`
            : `${intl.formatMessage({
                id: I18nKey.SIGN_IN,
              })}`}
        </SubmitButton>

        {!isNewPasswordRequired && (
          <SubmitButton variant="text" onClick={resetPage}>
            {`${intl.formatMessage({
              id: I18nKey.RESET_PASSWORD,
            })}`}
          </SubmitButton>
        )}
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import { createApiClient } from 'api/apiClient';
import { ChannelData, ChannelUserInfo, UserInfoMap } from 'api/SharedTypes';
import ChatTable from './ChatTable';
import 'pages/MessagesLog/MessagesLog.css';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';

const getNameFromChannelUserInfo = (userData: ChannelUserInfo) =>
  `${get(userData, 'first_name', '')} ${get(userData, 'last_name', '')}`;

interface NewMessagesLogProps {
  readonly showOldChatHistory: boolean;
}

export const NewMessagesLog: React.FC<NewMessagesLogProps> = ({
  showOldChatHistory,
}) => {
  const accessToken = localStorage.getItem('access_token');
  const [username, setUsername] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [channelData, setChannelData] = useState<ChannelData[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfoMap>({});

  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const { isLoading } = useQuery(
    'get-channel-list',
    () => apiClient.getUserChannels(username, true),
    {
      onSuccess: response => {
        if (isObject(response)) {
          setErrorMessage('');
          setChannelData(response.channel_arns);
          setUserInfo(response.user_info);
        } else {
          setErrorMessage('Invalid API response');
        }
      },
      onError: () => {
        setErrorMessage('Failed to fetch Channels');
      },
      enabled: username ? true : false,
    },
  );

  useEffect(() => {
    const patientUsername = localStorage.getItem(
      'patient_message_log_username',
    );
    if (patientUsername) {
      setUsername(patientUsername);
    }
  }, []);

  return isLoading ? (
    <div className="loadingContainer">
      <CircularProgress />
    </div>
  ) : errorMessage ? (
    <div>
      <div className="red bigText">{errorMessage}</div>
    </div>
  ) : isEmpty(channelData) && !showOldChatHistory ? (
    <div className="noDataContainer">No Data for this Patient</div>
  ) : (
    <>
      <div className="messagesLogContainer">
        {!isEmpty(channelData) && (
          <div>
            <b>Patient Name:</b>
            <span className="leftMargin">
              {getNameFromChannelUserInfo(userInfo[username])}
            </span>
          </div>
        )}
        {channelData
          .sort(
            (a, b) =>
              new Date(b.latest_message_timestamp).valueOf() -
              new Date(a.latest_message_timestamp).valueOf(),
          )
          .map(item => (
            <ChatTable
              key={item.channel_arn}
              channelData={item}
              userInfo={userInfo}
            />
          ))}
      </div>
    </>
  );
};

import { Button, Icon, ListItemButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/I18nKeys';
import {
  SelectOption,
  FormFieldType,
  UserRole,
  Icons,
} from '../../services/Utils';
import {
  getIsDOBInvalid,
  validateEmail,
  validateName,
} from '../../services/utilsFunctions';
import { UserFormFields } from '../UserFormFields';
import { green, grey } from '@mui/material/colors';
import { GetUserListResponse } from 'api/SharedTypes';

export interface FormFieldData {
  readonly value?: string;
  readonly dateValue?: DateTime | null;
  readonly label: string;
  readonly defaultValue?: string;
  readonly selectOptions?: SelectOption[];
  readonly isRequired: boolean;
  readonly isDisabled?: boolean;
  readonly errorText?: string | (() => string);
  readonly isError?: boolean;
  readonly type: FormFieldType;
  readonly onChange?: (value: string) => void;
  readonly onDateChange?: (value: DateTime | null) => void;
  readonly onBlur?: () => void;
  readonly mask?: string;
  readonly maxLength?: number;
  readonly tooltip?: string | null;
  readonly hide?: boolean;
}

export interface FormFieldObject {
  [name: string]: FormFieldData;
}

interface UserFormProps {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly handleFormSubmit: () => void;
  readonly formFields: FormFieldObject;
  readonly role: UserRole;
  readonly isLoading: boolean;
  readonly handleRemoteMonitoring?: (
    patientNetwork: GetUserListResponse[],
  ) => void;
  readonly remoteMonitoring?: string;
  readonly patientNetwork?: GetUserListResponse[];
}

const UserForm = ({
  open,
  handleClose,
  handleFormSubmit,
  formFields,
  role,
  isLoading,
  handleRemoteMonitoring,
  remoteMonitoring,
  patientNetwork,
}: UserFormProps) => {
  const isSubmitDisabled = () => {
    const requiredFields = Object.keys(formFields).filter(
      field => formFields[field].isRequired,
    );
    const isRequiredFilled = requiredFields.map(field => {
      if (formFields[field].type === FormFieldType.date) {
        if (!formFields[field].dateValue) {
          return true;
        }
        if (
          formFields[field].dateValue &&
          formFields[field].dateValue?.invalidReason
        ) {
          return true;
        }
        return false;
      }
      if (field === 'email') {
        if (formFields[field].value) {
          if (validateEmail(formFields[field].value || '')) {
            return false;
          }
        }
        return true;
      }

      if (field === 'first_name' || field === 'last_name') {
        if (formFields[field].value) {
          if (validateName(formFields[field].value || '')) {
            return false;
          }
        }
        return true;
      }

      return formFields[field].value ? false : true;
    });

    const invalidMiddleName =
      formFields['middle_name'] && formFields['middle_name'].value
        ? !validateName(formFields['middle_name'].value || '')
        : false;

    const isDOBRequired = formFields['dob']?.isRequired || false;
    const isDOBInvalid = getIsDOBInvalid(
      formFields['dob']?.dateValue,
      isDOBRequired,
    );
    return (
      isRequiredFilled.reduce((acc, nextValue) => acc || nextValue) ||
      isLoading ||
      isDOBInvalid ||
      invalidMiddleName
    );
  };

  const intl = useIntl();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle> {`Add New ${startCase(role)}`}</DialogTitle>
      <DialogContent>
        <UserFormFields formFields={formFields} />
      </DialogContent>
      <DialogActions>
        {(role === UserRole.physician ||
          role === UserRole.nurse ||
          role === UserRole.case_manager) &&
          (remoteMonitoring === 'N' || remoteMonitoring === '') && (
            <ListItemButton
              style={{
                display: 'flex',
                justifyContent: 'right',
                maxWidth: 'min-content',
              }}
              onClick={() => {
                handleRemoteMonitoring?.(patientNetwork!);
              }}
            >
              <Icon
                sx={{
                  color: grey[500],
                }}
              >
                {Icons.settings_remote}
              </Icon>
            </ListItemButton>
          )}

        {(role === UserRole.physician ||
          role === UserRole.nurse ||
          role === UserRole.case_manager) &&
          remoteMonitoring !== 'N' && (
            <ListItemButton
              style={{
                display: 'flex',
                justifyContent: 'right',
                maxWidth: 'min-content',
              }}
              onClick={() => {
                handleRemoteMonitoring?.(patientNetwork!);
              }}
            >
              <Icon
                sx={{
                  color:
                    remoteMonitoring === 'Y'
                      ? green[500]
                      : remoteMonitoring == null
                      ? grey[500]
                      : '#f2d720',
                }}
              >
                {Icons.settings_remote}
              </Icon>
            </ListItemButton>
          )}

        <Button
          variant="contained"
          disabled={isSubmitDisabled()}
          onClick={handleFormSubmit}
        >
          {intl.formatMessage({ id: I18nKey.CREATE })}
        </Button>
        <Button onClick={handleClose}>
          {intl.formatMessage({ id: I18nKey.CANCEL })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserForm;

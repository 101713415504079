import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  ChannelData,
  ChannelMessageData,
  ChannelUserInfo,
  UserInfoMap,
} from 'api/SharedTypes';
import DoneIcon from '@mui/icons-material/Done';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { createApiClient } from 'api/apiClient';
import isEmpty from 'lodash/isEmpty';
import { Button, CircularProgress } from '@mui/material';
import 'pages/MessagesLog/MessagesLog.css';
import { useMutation, useQuery } from 'react-query';

interface ChatTableProps {
  readonly channelData: ChannelData;
  readonly userInfo: UserInfoMap;
}

const CHAT_TABLE_TIME_FORMAT = 'MM-dd-yyyy, hh:mm:ss a';

const getNameFromChannelUserInfo = (userData: ChannelUserInfo) => {
  if (!userData || isEmpty(userData)) return '';
  return `${userData.first_name} ${userData.last_name}`;
};

const getRoleFromChannelUserInfo = (userData: ChannelUserInfo) => {
  if (!userData || isEmpty(userData)) return '';
  return userData.role;
};

const getChatTimestampFromUtcTimeString = (timeString: string) => {
  const isoDate = DateTime.fromMillis(Date.parse(timeString)).toISO();
  return DateTime.fromISO(isoDate, { zone: 'UTC' }).toFormat(
    CHAT_TABLE_TIME_FORMAT,
  );
};

const ChatTable: React.FC<ChatTableProps> = ({ channelData, userInfo }) => {
  const intl = useIntl();
  const [messages, setChatMessages] = useState<ChannelMessageData[]>([]);
  const [channelNextToken, setChannelNextToken] = useState<string | null>(null);

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const { isFetching: isLoading } = useQuery(
    ['get-channel-messages', channelData.channel_name],
    () =>
      apiClient.listChannelMessages(channelData.channel_name, channelNextToken),
    {
      onSuccess: response => {
        setChatMessages(response.messages);
        setChannelNextToken(response.next_token);
      },
      enabled: channelData ? true : false,
    },
  );

  const { isLoading: isMoreLoading, mutate: getMoreChannelMessages } =
    useMutation(
      'get-more-channel-messages',
      ({
        channelName,
        nextToken,
      }: {
        channelName: string;
        nextToken: string | null | undefined;
      }) => apiClient.listChannelMessages(channelName, nextToken),
      {
        onSuccess: response => {
          setChatMessages([...messages, ...response.messages]);
          setChannelNextToken(response.next_token);
        },
      },
    );

  const channelId = useMemo(() => {
    if (channelData) {
      const channelNameSplit = channelData.channel_arn.split('/');
      if (!isEmpty(channelNameSplit)) {
        return channelNameSplit[channelNameSplit.length - 1];
      }
    }
    return '';
  }, [channelData]);

  const getMoreMessages = async (
    channelName: string,
    nextToken?: string | null,
  ) => getMoreChannelMessages({ channelName, nextToken });

  const onMoreMessagesClick = () => {
    getMoreMessages(channelData.channel_name, channelNextToken);
  };

  return !isLoading && isEmpty(messages) ? null : (
    <table className="tableContainer">
      <tbody>
        <tr>
          <td>{`${intl.formatMessage({
            id: I18nKey.MESSAGES_LOG_CHAT_ID_LABEL,
          })} ${channelId}`}</td>
          <td>
            {channelData.is_patient_linked_channel === 0
              ? intl.formatMessage({ id: I18nKey.MESSAGES_LOG_PRIVATE_CHAT })
              : `${intl.formatMessage({
                  id: I18nKey.MESSAGES_LOG_RELATED_TO_PATIENT,
                })} ${getNameFromChannelUserInfo(
                  userInfo[channelData.pat_uname],
                )}`}
          </td>
          <td />
        </tr>
        <tr>
          <td>
            {intl.formatMessage({
              id: I18nKey.MESSAGES_LOG_PARTICIPANTS_LABEL,
            })}
          </td>

          {channelData.is_patient_linked_channel === 0 ? (
            <>
              <td>
                {intl.formatMessage({
                  id: I18nKey.MESSAGES_LOG_NAME_LABEL,
                })}
                <b>
                  {' '}
                  {getNameFromChannelUserInfo(userInfo[channelData.pat_uname])}
                  {' - '}
                  {getRoleFromChannelUserInfo(userInfo[channelData.pat_uname])}
                </b>
              </td>
              <td>
                {intl.formatMessage({
                  id: I18nKey.MESSAGES_LOG_NAME_LABEL,
                })}{' '}
                <b>
                  {getNameFromChannelUserInfo(
                    userInfo[channelData.user1_uname],
                  )}
                  {' - '}
                  {getRoleFromChannelUserInfo(
                    userInfo[channelData.user1_uname],
                  )}
                </b>
              </td>
            </>
          ) : (
            <>
              <td>
                {intl.formatMessage({
                  id: I18nKey.MESSAGES_LOG_NAME_LABEL,
                })}
                <b>
                  {' '}
                  {getNameFromChannelUserInfo(
                    userInfo[channelData.user1_uname],
                  )}
                  {' - '}
                  {getRoleFromChannelUserInfo(
                    userInfo[channelData.user1_uname],
                  )}
                </b>
              </td>
              <td>
                {intl.formatMessage({
                  id: I18nKey.MESSAGES_LOG_NAME_LABEL,
                })}
                <b>
                  {' '}
                  {getNameFromChannelUserInfo(
                    userInfo[channelData.user2_uname],
                  )}
                  {' - '}
                  {getRoleFromChannelUserInfo(
                    userInfo[channelData.user2_uname],
                  )}
                </b>
              </td>
            </>
          )}
        </tr>
        <tr className="centerText">
          <td colSpan={1} className="no-border">
            {intl.formatMessage({ id: I18nKey.MESSAGES_LOG_MESSAGES_LABEL })}
          </td>
          <td className="no-border"></td>
          <td className="no-border"></td>
        </tr>

        {isLoading ? (
          <tr>
            <td colSpan={3}>
              <CircularProgress className="centerLoading" />
            </td>
          </tr>
        ) : isEmpty(messages) ? (
          <tr>
            <td colSpan={3} className="textCenterAlign">
              No Messages Found
            </td>
          </tr>
        ) : (
          <>
            {messages?.map((message: ChannelMessageData) => {
              const sender = userInfo[message.Sender];
              return (
                <tr>
                  <td key={message.Sender || message.latest_message}>
                    {`${getNameFromChannelUserInfo(
                      sender,
                    )} at ${getChatTimestampFromUtcTimeString(
                      message.latest_message_timestamp,
                    )}`}
                    <DoneIcon sx={{ color: 'green' }} />
                    {/* TODO: show Tick on IsRead when functionality for it is added */}
                  </td>
                  <td className="messageContentTableCell">
                    {message.latest_message}
                  </td>
                  <td />
                </tr>
              );
            })}
            {channelNextToken && (
              <tr>
                <td colSpan={3}>
                  <div className="moreMessagesLoadingContainer">
                    {isMoreLoading ? (
                      <CircularProgress className="centerLoading" />
                    ) : (
                      <Button color="info" onClick={onMoreMessagesClick}>
                        Load Older Messages
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};
export default ChatTable;

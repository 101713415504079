import React, { useEffect, useMemo } from 'react';
import { createApiClient } from 'api/apiClient';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { useParams } from 'react-router';
import { UserRole } from 'services/Utils';
import './CallLogs.css';
import { CircularProgress, Alert, AlertTitle } from '@mui/material';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { useQueries } from 'react-query';
import { DateTime } from 'luxon';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const convertUTCDateToLocal = (dateString: string) => {
  const utcDate = DateTime.fromFormat(dateString, 'MM-dd-yyyy hh:mm a', {
    zone: 'UTC',
  });
  const localDate = utcDate.toLocal();
  return localDate.toFormat('MM-dd-yyyy hh:mm a');
};

const CallLogs: React.FC = () => {
  const { internalId, userId } = useParams();
  const intl = useIntl();

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const [
    {
      refetch: getCallLogsList,
      isFetching: isCallLogsLoading,
      isError: isCallLogsError,
      data: callLogsResp,
    },
    {
      refetch: getPatientData,
      isFetching: isPatientDataLoading,
      isError: isPatientDataError,
      data: patientDataResp,
    },
  ] = useQueries([
    {
      queryKey: 'get-call-logs',
      queryFn: () => apiClient.getCallLogs(internalId!),
      enabled: false,
    },
    {
      queryKey: 'get-patient-data',
      queryFn: () => apiClient.getUserData(Number(userId), UserRole.patient),
      enabled: false,
    },
  ]);

  const callLogs = useMemo(() => {
    if (callLogsResp) {
      if (isArray(callLogsResp)) {
        return callLogsResp;
      }
    }
    return [];
  }, [callLogsResp]);

  const patientName = useMemo(() => {
    if (patientDataResp) {
      return patientDataResp.name;
    }
    return '';
  }, [patientDataResp]);

  const [NORECORDS, ERROR, INFO] = [
    I18nKey.NO_DATA_CALL_RECORDS,
    I18nKey.ERROR_MESSAGE,
    I18nKey.INFO,
  ].map(key => intl.formatMessage({ id: key }));

  useEffect(() => {
    getCallLogs();
    return () => {
      controller.abort();
    };
  }, []);

  const getCallLogs = () => {
    getCallLogsList();
    getPatientData();
  };

  const prepareDataTableRows = () => {
    var groupedData = groupBy(
      callLogs,
      record =>
        new Date(record.date_p.replace(/-/g, '/')).getMonth() +
        '.' +
        new Date(record.date_p.replace(/-/g, '/')).getFullYear(),
    );

    var sortedKeys = Object.keys(groupedData)
      .sort((x, y) => {
        var split_x = x.split('.');
        var split_y = y.split('.');

        if (split_x[1] !== split_y[1]) {
          return parseInt(split_x[1]) - parseInt(split_y[1]);
        }

        return parseInt(split_x[0]) - parseInt(split_y[0]);
      })
      .reverse();
    var rows: JSX.Element[] = [];

    sortedKeys.forEach(month => {
      var total_duration = 0;
      Object.values(groupedData[month]).forEach(record => {
        total_duration += record.call_duration / 60;

        rows.push(
          <tr key={record.date_p}>
            <td>{convertUTCDateToLocal(record.date_p)}</td>
            <td>{record.prv_name}</td>
            <td>{(record.call_duration / 60).toFixed(2)}</td>
            <td>{record.call_type}</td>
          </tr>,
        );
      });

      var only_month = parseInt(month.split('.')[0]);

      rows.push(
        <tr key={month} className="rowContainer">
          <td colSpan={4}>
            <b>{monthNames[only_month]}</b>
            {intl.formatMessage({ id: I18nKey.TOTAL_CALL_TIME })}:
            {Math.round(total_duration * 100) / 100} minutes
          </td>
        </tr>,
      );
    });

    return rows;
  };
  return isCallLogsLoading || isPatientDataLoading ? (
    <div className="callLogsCenterLoading">
      <CircularProgress />
    </div>
  ) : isCallLogsError ? (
    <div className="callLogsCenterLoading">
      <div className="infoCard">
        <Alert severity="info">
          <AlertTitle>{INFO}</AlertTitle>
          <h2>{ERROR}</h2>
        </Alert>
      </div>
    </div>
  ) : (
    <div>
      {isPatientDataError ? (
        <h3 className="textCenter red">Failed to load Patient Name</h3>
      ) : (
        <h1 id="title"> {patientName} </h1>
      )}
      {isEmpty(callLogs) ? (
        <div className="infoCard">
          <Alert severity="info">
            <AlertTitle>{INFO}</AlertTitle>
            <h2>{NORECORDS}</h2>
          </Alert>
        </div>
      ) : (
        <table id="patientSympData">
          <thead>
            <tr>
              <th>
                <b>{intl.formatMessage({ id: I18nKey.DATE })}</b>
              </th>
              <th>
                <b>{intl.formatMessage({ id: I18nKey.PROVIDER })}</b>
              </th>
              <th>
                <b>{intl.formatMessage({ id: I18nKey.DURATION_MIN })}</b>
              </th>
              <th>
                <b>{intl.formatMessage({ id: I18nKey.TYPE })}</b>
              </th>
            </tr>
          </thead>
          <tbody>{prepareDataTableRows().map(component => component)}</tbody>
        </table>
      )}
    </div>
  );
};

export default CallLogs;

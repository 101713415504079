import React, { useContext, useEffect, useReducer, useState } from 'react';
import Delete from '@mui/icons-material/Delete';
import MoreVert from '@mui/icons-material/MoreVert';
import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Box,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { createApiClient } from 'api/apiClient';
import { NetworkResponse, UserDataResponse } from 'api/SharedTypes';
import { UserContext, UserContextState } from 'contexts/userContext';
import I18nKey from 'lib/I18nKeys';
import { UserRole } from 'services/Utils';
import { TabContext } from 'components/UpdateDialog';
import 'components/NetworkInfo/NetworkInfo.css';

interface ProviderNetworkistProps {
  readonly userData: UserDataResponse;
  readonly networkData: NetworkResponse[];
  readonly refreshNetworkList?: (id: number, role: UserRole) => void;
}

const submitData = false;

enum Submit {
  UPDATE = 'update',
  CANCEL = 'cancel',
}

const reducer = (state: boolean, action: Submit) => {
  switch (action) {
    case Submit.CANCEL:
      return false;
    case Submit.UPDATE:
      return true;
    default:
      throw new Error('Unexpected action');
  }
};

const ProviderNetwork: React.FC<ProviderNetworkistProps> = ({
  userData,
  networkData,
  refreshNetworkList,
}) => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const intl = useIntl();
  const [providerNetwork, setProviderNetwork] = useState<NetworkResponse[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<NetworkResponse[]>([]);
  const [value, setValue] = useState<NetworkResponse | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSubmit, dispatch] = useReducer(reducer, submitData);

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const tabValue = useContext(TabContext);

  const { isFetching: isPatientOptionsLoading } = useQuery(
    'get-patient-options',
    () => apiClient.getPatientList(),
    {
      onSuccess: data => {
        const typeCorrectedNetworkData = isEmpty(networkData)
          ? []
          : networkData;
        const caregiverNetworkIds =
          typeCorrectedNetworkData.map(user => user.id) || [];
        const filteredData = data.filter(
          item => !caregiverNetworkIds.includes(item.id),
        );
        const networkOptionData: NetworkResponse[] = filteredData.map(item => ({
          id: item.id,
          username: item.username,
          external_id: item.external_id,
          internal_id: item.internal_id,
          remote_monitoring: item.remote_monitoring,
          name: item.name,
          role: item.role,
          organizations: [],
        }));
        setOptions(networkOptionData);
      },
      onError: () => {
        setOptions([]);
      },
      enabled: tabValue ? true : false,
    },
  );

  const {
    isLoading: isUpdatingCaregiverNetwork,
    mutate: updateCaregiverNetworkData,
  } = useMutation(
    'update-caregiver-network',
    ({
      userId,
      providerNetwork,
    }: {
      userId: number;
      providerNetwork: NetworkResponse[];
    }) => apiClient.updateCaregiverNetwork(userId, providerNetwork),
    {
      onSuccess: response => {
        setSnackbarMessage('Network Updated Successfuly!');
        handleSnackBarOpen();
        dispatch(Submit.CANCEL);

        const role = userData.role;

        if (
          refreshNetworkList &&
          userData &&
          userData.id &&
          (role === UserRole.physician ||
            role === UserRole.caregiver ||
            role === UserRole.case_manager ||
            role === UserRole.nurse)
        ) {
          refreshNetworkList(userData.id, role);
        }
      },
      onError: () => {
        setSnackbarMessage('Network Update Failed!');
        handleSnackBarOpen();
      },
    },
  );

  const handleMenuControlClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const deleteNetworkUser = () => {
    const filteredList = providerNetwork.filter(
      user => user.id.toString() !== selectedUserId,
    );
    isEqual(providerNetwork, filteredList)
      ? dispatch(Submit.CANCEL)
      : dispatch(Submit.UPDATE);
    setProviderNetwork(filteredList);
    handleMenuClose();
  };

  const handleSnackBarOpen = () => setIsSnackbarOpen(true);

  const handleSnackBarClose = () => setIsSnackbarOpen(false);

  const updateCaregiverNetwork = () => {
    updateCaregiverNetworkData({
      userId: userData.id!,
      providerNetwork,
    });
  };

  const handleDropdownChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: NetworkResponse | null,
  ) => {
    const newNetwork = providerNetwork.concat(newValue!);
    isEqual(providerNetwork, newNetwork)
      ? dispatch(Submit.CANCEL)
      : dispatch(Submit.UPDATE);
    setProviderNetwork(newNetwork);
    const newOptions = options.filter(option => option.id !== newValue?.id);
    setOptions(newOptions);
    setValue(null);
    setInputValue('');
  };

  useEffect(() => {
    if (networkData) {
      setProviderNetwork(isEmpty(networkData) ? [] : networkData);
    }
  }, [networkData]);

  return (
    <>
      {isEmpty(providerNetwork) ? (
        <div className="noMembers centerLoading">
          {intl.formatMessage({
            id: I18nKey.UPDATE_DIALOG_NETWORK_NO_MEMBERS_LABEL,
          })}
        </div>
      ) : (
        <List>
          {providerNetwork.map((item, index) => (
            <ListItem key={item.id} disablePadding>
              <div key={item.id} className="networkInfoContainer">
                <div className="networkUserContainer">
                  <div className="networkUserNameTextContainer">
                    <ListItemText
                      className="networkUserNameText"
                      primary={item.name}
                      secondary={item.role}
                    ></ListItemText>
                  </div>
                </div>
                {userData.role === UserRole.caregiver && (
                  <div>
                    <IconButton
                      disabled={isReadOnly}
                      onClick={e =>
                        handleMenuControlClick(e, item.id.toString())
                      }
                    >
                      <MoreVert fontSize="medium" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={selectedUserId === item.id.toString()}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={deleteNetworkUser}>
                        <ListItemIcon>
                          <Delete fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText className="networkUserNameText">
                          {intl.formatMessage({
                            id: I18nKey.USER_NETWORK_ACTION_REMOVE,
                          })}
                        </ListItemText>
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            </ListItem>
          ))}
        </List>
      )}
      {userData.role === UserRole.caregiver && (
        <div>
          <div className="centerLoading">
            <Autocomplete
              clearOnEscape
              fullWidth
              value={value}
              open={open}
              disabled={isReadOnly}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={handleDropdownChange}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              getOptionLabel={option =>
                `${option.name}${option.role}${option.id}`
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: '#106a9c',
                    width: 290,
                  }}
                  {...props}
                >
                  <div className="optionName">{option.name}</div>
                  <div className="optionRole">{option.role}</div>
                </Box>
              )}
              options={options}
              loading={isPatientOptionsLoading}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  sx={{ marginLeft: 3, width: '80%' }}
                  label={<FormattedMessage id={I18nKey.NETWORK_INFO_OPTIONS} />}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isPatientOptionsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="marginTop centerLoading">
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: 'white' }}
              onClick={updateCaregiverNetwork}
              disabled={!isSubmit || isUpdatingCaregiverNetwork || isReadOnly}
            >
              {isUpdatingCaregiverNetwork ? (
                <CircularProgress size={30} />
              ) : (
                intl.formatMessage({ id: I18nKey.SAVE })
              )}
            </Button>
          </div>
        </div>
      )}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleSnackBarClose}
        message={snackbarMessage}
      />
    </>
  );
};
export default ProviderNetwork;

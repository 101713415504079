import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { User } from 'api/SharedTypes';
import { SubmitFormData, UserRole } from 'services/Utils';
import './ExceptionDialog.css';
import {
  ExceptionDialogAction,
  ExceptionDialogContent,
  ExceptionDialogTitle,
} from './Utils';

interface ExceptionDialogProps {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly errorCode: string;
  readonly handleAddUserDialogClose: () => void;
  readonly enteredFormData: SubmitFormData;
  readonly existingUserData: User;
  readonly role: UserRole;
  readonly fetchNewDataList: (loadUpdatedData?: boolean) => void;
}

export enum ExceptionMessage {
  addNew = 'add_new',
  addToNewOrg = 'add_to_new_org',
}

export const ExceptionDialog: React.FC<ExceptionDialogProps> = ({
  open,
  handleClose,
  errorCode,
  handleAddUserDialogClose,
  enteredFormData,
  existingUserData,
  fetchNewDataList,
  role,
}) => {
  const intl = useIntl();

  const dialogTitle = ExceptionDialogTitle();

  const dialogActions = ExceptionDialogAction(
    handleClose,
    intl,
    handleAddUserDialogClose,
  );

  const dialogContent = ExceptionDialogContent();

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{errorCode ? dialogTitle[errorCode] : null}</DialogTitle>
        <DialogContent>
          {errorCode ? dialogContent[errorCode] : null}
        </DialogContent>
        <DialogActions>
          {errorCode ? dialogActions[errorCode] : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

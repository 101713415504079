import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import UsersList from '../../components/UsersList';
import { CircularProgress } from '@mui/material';
import isArray from 'lodash/isArray';
import { createApiClient } from 'api/apiClient';
import TabTopButton from 'components/TabTopButton';
import { GetUserListResponse, UpdateUserResponse, User } from 'api/SharedTypes';
import { FormFieldObject } from 'components/UserForm';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  FormFieldType,
  SubmitFormData,
  UserRole,
} from 'services/Utils';
import { UpdateDialog } from 'components/UpdateDialog';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/I18nKeys';
import { ExceptionDialog } from '../../components/ExceptionDialog';
import { NavRoutes } from '../NavRouter';
import {
  sortByName,
  validateEmail,
  validateName,
} from '../../services/utilsFunctions';
import { useCaregiverData } from './useCaregiverData';
import { useMutation } from 'react-query';
import './style.css';
import { UserContextState, UserContext } from 'contexts/userContext';

const Caregivers: React.FC = () => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const {
    caregiverFormActions,
    caregiverFormData,
    caregiverUserData,
    clearForm,
    getFormSubmitData,
    putUpdateFormData,
    setCaregiverFormData,
  } = useCaregiverData();
  const [caregiverId, setCaregiverId] = useState<number | null>(null);

  const intl = useIntl();

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [isExceptionDialogOpen, setIsExceptionDialogOpen] =
    useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [existingUserData, setExistingUserData] = useState<User>({});

  const {
    isLoading,
    mutate: getCaregiverList,
    isError,
    data: caregiverListResp,
  } = useMutation(
    'caregiver-list',
    async (loadUpdatedData?: boolean) =>
      apiClient.getCaregiversList(loadUpdatedData),
    {
      onError: error => {
        console.log('error ', error);
      },
    },
  );

  const { isLoading: isLoadingCaregiverData, mutate: getCaregiverData } =
    useMutation(
      'get-caregiver-data',
      async (id: number) => apiClient.getUserData(id, UserRole.caregiver),
      {
        onSuccess: response => {
          setCaregiverFormData(response);
          caregiverFormActions.setCaregiverData(response);
        },
        onError: () => {
          handleUpdateDialogClose();
        },
      },
    );

  const { isLoading: isUpdatingCaregiverData, mutate: updateCaregiverData } =
    useMutation(
      'update-caregiver-data',
      async (params: { details: SubmitFormData; id: number }) =>
        apiClient.updateCaregiverData(params.details, params.id),
      {
        onSuccess: response => {
          if (response.errorCode) {
            setExceptionDialogData(response);
          } else {
            handleUpdateDialogClose();
            getCaregiverList(false);
          }
        },
      },
    );

  const caregivers = useMemo(() => {
    if (caregiverListResp) {
      if (isArray(caregiverListResp)) {
        return sortByName(caregiverListResp) as GetUserListResponse[];
      }
    }
    return [];
  }, [caregiverListResp]);

  const handleUpdateDialogOpen = () => {
    setIsUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    clearForm();
    setCaregiverId(null);
    caregiverFormActions.setCaregiverData(EMPTY_USER_RESPONSE_DATA);
    setIsUpdateDialogOpen(false);
  };

  const handleExceptionDialogClose = () => setIsExceptionDialogOpen(false);

  const handleExceptionDialogOpen = () => setIsExceptionDialogOpen(true);

  const setExceptionDialogData = (response: UpdateUserResponse) => {
    handleExceptionDialogOpen();
    setErrorCode(response.errorCode!);
    setExistingUserData(response.existing_data!);
  };

  const fetchCaregivers = useCallback(
    (loadUpdatedData?: boolean) => getCaregiverList(loadUpdatedData),
    [getCaregiverList],
  );

  const updateCaregiver = async () => {
    const data = putUpdateFormData(caregiverId);
    updateCaregiverData({
      details: { ...data, role: UserRole.caregiver },
      id: caregiverId!,
    });
    caregiverFormActions.setVerifyEmailChecked(false);
  };

  const onCaregiverClick = (id: number) => {
    handleUpdateDialogOpen();
    setCaregiverId(id);
    getCaregiverData(id);
  };

  const accessToken = localStorage.getItem('access_token');
  const controller = useMemo(() => new AbortController(), []);
  const apiClient = createApiClient(accessToken!, controller);

  const getUserName = () => {
    if (
      caregiverFormData.firstName &&
      caregiverFormData.lastName &&
      !caregiverFormData.userName
    ) {
      apiClient
        .getUserName(caregiverFormData.firstName, caregiverFormData.lastName)
        .then(response => caregiverFormActions.setUserName(response));
    }
  };

  const caregiverFields: FormFieldObject = {
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caregiverFormData.firstName,
      onChange: value => caregiverFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      onBlur: getUserName,
      isError: caregiverFormData.firstName
        ? !validateName(caregiverFormData.firstName)
        : false,
      errorText:
        !validateName(caregiverFormData.firstName) &&
        caregiverFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caregiverFormData.lastName,
      onChange: value => caregiverFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      onBlur: getUserName,
      isError: caregiverFormData.lastName
        ? !validateName(caregiverFormData.lastName)
        : false,
      errorText:
        !validateName(caregiverFormData.lastName) && caregiverFormData.lastName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      isDisabled: true,
      value: caregiverFormData.userName,
      onChange: value => caregiverFormActions.setUserName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_USERNAME,
      }),
    },
    dob: {
      type: FormFieldType.date,
      isRequired: true,
      dateValue: caregiverFormData.dob,
      onDateChange: value => caregiverFormActions.setDOB(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DATE_OF_BIRTH,
      }),
      isDisabled: isReadOnly,
    },
    email: {
      type: FormFieldType.email,
      isRequired: true,
      value: caregiverFormData.email.toLowerCase(),
      onChange: value => caregiverFormActions.setEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: caregiverFormData.email
        ? !validateEmail(caregiverFormData.email)
        : false,
      errorText:
        !validateEmail(caregiverFormData.email) && caregiverFormData.email
          ? 'Please enter a valid email'
          : '',
      isDisabled: isReadOnly,
    },
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        caregiverFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
      hide: true,
    },
    gender: {
      type: FormFieldType.select,
      isRequired: true,
      value: caregiverFormData.gender,
      onChange: value => caregiverFormActions.setGender(value),
      selectOptions: ExportConstants.genderOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_GENDER,
      }),
      isDisabled: isReadOnly,
    },
    home_tel_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: caregiverFormData.homeTelCountryCode,
      onChange: value => caregiverFormActions.setHomeTelCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    home_tel: {
      type: FormFieldType.mask,
      isRequired: true,
      value: caregiverFormData.landline,
      onChange: value => caregiverFormActions.setLandline(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE,
      }),
      isDisabled: isReadOnly,
    },
    country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: caregiverFormData.cellCountryCode,
      onChange: value => caregiverFormActions.setCellCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_CELL_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    cell: {
      type: FormFieldType.mask,
      isRequired: true,
      value: caregiverFormData.mobile,
      onChange: value => caregiverFormActions.setMobile(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_MOBILE_NUMBER,
      }),
      isDisabled: isReadOnly,
    },
    home_addr_1: {
      type: FormFieldType.text,
      isRequired: true,
      value: caregiverFormData.homeAddr1,
      onChange: value => caregiverFormActions.setHomeAddr1(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_HOME_ADDRESS_1,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    home_addr_2: {
      type: FormFieldType.text,
      isRequired: false,
      value: caregiverFormData.homeAddr2,
      onChange: value => caregiverFormActions.setHomeAddr2(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_HOME_ADDRESS_2,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    city: {
      type: FormFieldType.text,
      isRequired: true,
      value: caregiverFormData.city,
      onChange: value => caregiverFormActions.setCity(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADDRESS_CITY,
      }),
      maxLength: 20,
      isDisabled: isReadOnly,
    },
    state: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.stateOptions,
      value: caregiverFormData.state,
      onChange: value => caregiverFormActions.setState(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_STATE,
      }),
      isDisabled: isReadOnly,
    },
    zipCode: {
      type: FormFieldType.mask,
      isRequired: true,
      value: caregiverFormData.zipCode,
      mask: '######',
      onChange: value => caregiverFormActions.setZipCode(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ZIP_CODE,
      }),
      isDisabled: isReadOnly,
    },
    licenseNumber: {
      type: FormFieldType.text,
      isRequired: false,
      value: caregiverFormData.licenseNumber,
      onChange: value => caregiverFormActions.setLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LICENSE_NUMBER,
      }),
      maxLength: 25,
      isDisabled: isReadOnly,
    },
  };

  const caregiverUpdateFields: FormFieldObject = {
    ...caregiverFields,
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        caregiverFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
    },
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caregiverFormData.firstName,
      onChange: value => caregiverFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      isError: caregiverFormData.firstName
        ? !validateName(caregiverFormData.firstName)
        : false,
      errorText:
        !validateName(caregiverFormData.firstName) &&
        caregiverFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caregiverFormData.lastName,
      onChange: value => caregiverFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      isError: caregiverFormData.lastName
        ? !validateName(caregiverFormData.lastName)
        : false,
      errorText:
        !validateName(caregiverFormData.lastName) && caregiverFormData.lastName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      value: caregiverFormData.userName,
      onChange: () => {},
      label: intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_USERNAME }),
      isDisabled: true,
    },
    dob: {
      type: FormFieldType.date,
      isRequired: true,
      dateValue: caregiverFormData.dob,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DATE_OF_BIRTH,
      }),
      isDisabled: true,
    },
  };

  useEffect(() => {
    fetchCaregivers();
    return () => {
      controller.abort();
    };
  }, [controller, fetchCaregivers]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <TabTopButton
        role={UserRole.caregiver}
        getFormSubmitData={getFormSubmitData}
        clearForm={clearForm}
        formFields={caregiverFields}
        fetchNewDataList={fetchCaregivers}
      />
      {isLoading ? (
        <div className="caregiverListCenterLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="caregiverListCenterLoading">
          <div className="red">Failed to Fetch Caregiver List</div>
        </div>
      ) : (
        <UsersList
          users={caregivers}
          routes={NavRoutes.caregivers}
          onUserClick={onCaregiverClick}
          fetchNewDataList={fetchCaregivers}
        />
      )}
      <UpdateDialog
        handleClose={handleUpdateDialogClose}
        open={isUpdateDialogOpen}
        userData={caregiverUserData}
        role={UserRole.caregiver}
        handleUpdate={updateCaregiver}
        formFields={caregiverUpdateFields}
        userDataLoading={isLoadingCaregiverData}
        isUserDataUpdating={isUpdatingCaregiverData}
      />
      <ExceptionDialog
        open={isExceptionDialogOpen}
        handleClose={handleExceptionDialogClose}
        errorCode={errorCode}
        handleAddUserDialogClose={handleUpdateDialogClose}
        enteredFormData={putUpdateFormData(caregiverId)}
        existingUserData={existingUserData}
        role={UserRole.caregiver}
        fetchNewDataList={fetchCaregivers}
      />
    </div>
  );
};

export default Caregivers;

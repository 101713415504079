import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import startCase from 'lodash/startCase';
import { DataTable, Column } from 'primereact';
import { useMutation } from 'react-query';
import { createApiClient } from 'api/apiClient';
import {
  ExceptionInputUserData,
  ExceptionMatchingData,
  ExceptionsListData,
  ExceptionStatus,
} from 'api/SharedTypes';
import { UserContextState, UserContext } from 'contexts/userContext';
import { TableWrapper } from './styles';

const HEADER_DISPLAY_NONE: React.CSSProperties = { display: 'none' };

interface ExpandedExceptionProps {
  readonly rowItem: ExceptionsListData;
  readonly exceptionFromSameOrg: boolean;
  readonly getExceptionList: () => void;
}

const ExceptionStatusColorMap: Record<ExceptionStatus, string> = {
  APPROVED: 'green',
  ARCHIVED: 'red',
  DENIED: 'red',
  PENDING: 'gray',
};

const COLUMN_KEY_LIST = [
  'fields',
  'first_name',
  'last_name',
  'dob',
  'ssn',
  'email',
  'home_tel',
  'cell',
  'org_id',
  'home_addr_1',
  'home_addr_2',
  'state',
  'drive_license_number',
  'gender',
  'address_city',
  'address_zip',
  'actions',
];

const useAllQueries = <T extends any>({
  onSuccess,
  onError,
}: {
  onSuccess: (response: T[]) => void;
  onError?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const executeQueries = (promises: Promise<T>[]) => {
    setIsLoading(true);
    Promise.all(promises)
      .then(response => {
        setIsLoading(false);
        setIsError(false);
        onSuccess?.(response);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
        onError?.();
      });
  };

  return { isLoading, isError, executeQueries };
};

export const ExpandedException: React.FC<ExpandedExceptionProps> = ({
  rowItem,
  exceptionFromSameOrg,
  getExceptionList,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {
    isLoading: isUpdatingExceptionStatus,
    mutate: updateExceptionStatus,
  } = useMutation(
    'update-exception-status',
    async ({ id, status }: { id: string | number; status: ExceptionStatus }) =>
      apiClient.updateExceptionStatus(id, status),
    {
      onSuccess: () => {
        getExceptionList();
      },
    },
  );

  const { isLoading: isArchivingRemainingExceptions, executeQueries } =
    useAllQueries<string>({
      onSuccess: () => {
        getExceptionList();
      },
    });

  const {
    isLoading: isCreatingUserInException,
    mutate: createUserFromException,
  } = useMutation(
    'create-user-in-exception',
    async (params: {
      id: string;
      userData: ExceptionInputUserData;
      remainingExceptionIds: string[];
    }) => apiClient.createUserInException(params.id, params.userData),
    {
      onSuccess: (_resp, params) => {
        const promises = params.remainingExceptionIds.map(id =>
          apiClient.updateExceptionStatus(id, ExceptionStatus.ARCHIVED),
        );
        executeQueries(promises);
      },
    },
  );

  const {
    isLoading: isUpdatingUserInException,
    mutate: updateUserFromException,
  } = useMutation(
    'update-user-in-exception',
    async (params: {
      id: string;
      userData: ExceptionInputUserData;
      remainingExceptionIds: string[];
    }) => apiClient.updateUserInException(params.id, params.userData),
    {
      onSuccess: (_resp, params) => {
        const promises = params.remainingExceptionIds.map(id =>
          apiClient.updateExceptionStatus(id, ExceptionStatus.ARCHIVED),
        );
        executeQueries(promises);
      },
    },
  );

  const sortedMatchingData = useMemo(() => {
    const duplicateMatchingData = [...rowItem.matching_data];
    return duplicateMatchingData.sort(
      (a, b) =>
        b.matching_fields.split(',').length -
        a.matching_fields.split(',').length,
    );
  }, [rowItem.matching_data]);

  const maxAttributeMatchCount = useMemo(() => {
    const matchAttributeCounts = rowItem.matching_data.map(
      data => data.matching_fields.split(',').length,
    );
    return Math.max(...matchAttributeCounts);
  }, [rowItem.matching_data]);

  const onCloseDialog = () => setOpenDialog(false);

  const onOpenDialog = () => setOpenDialog(true);

  const onApproveException = (exceptionId: string | number) => () => {
    updateExceptionStatus({
      id: exceptionId,
      status: ExceptionStatus.APPROVED,
    });
  };

  const onDenyException = (exceptionId: string | number) => () => {
    updateExceptionStatus({ id: exceptionId, status: ExceptionStatus.DENIED });
  };

  const onArchiveException = (exceptionId: string | number) => () => {
    updateExceptionStatus({
      id: exceptionId,
      status: ExceptionStatus.ARCHIVED,
    });
  };

  const onCreateInException =
    (
      exceptionId: string,
      userData: ExceptionInputUserData,
      remainingExceptionIds: string[],
    ) =>
    () =>
      createUserFromException({
        id: exceptionId,
        remainingExceptionIds: remainingExceptionIds,
        userData: userData,
      });

  const onUpdateInException =
    (
      exceptionId: string,
      userData: ExceptionInputUserData,
      remainingExceptionIds: string[],
    ) =>
    () =>
      updateUserFromException({
        id: exceptionId,
        remainingExceptionIds: remainingExceptionIds,
        userData: userData,
      });

  const rowMatchesInSameOrg = useCallback(
    (rowOrgId: number) => {
      if (userContext.userData) {
        return userContext.userData.userOrg === rowOrgId;
      }
      return false;
    },
    [userContext.userData],
  );

  const actionsBodyTemplate = (row: ExceptionMatchingData) => {
    const remainingExceptionIds = rowItem.matching_data
      .map(data => data.exception_id)
      .filter(id => id !== row.exception_id);
    if (!exceptionFromSameOrg && rowMatchesInSameOrg(row.matching_org_id)) {
      if (row.status === ExceptionStatus.PENDING) {
        return (
          <div style={{ display: 'flex', gap: 15 }}>
            <Button
              variant="contained"
              color="success"
              disabled={isUpdatingExceptionStatus || userContext.isReadOnly}
              onClick={onApproveException(row.exception_id)}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={isUpdatingExceptionStatus || userContext.isReadOnly}
              onClick={onDenyException(row.exception_id)}
            >
              Deny
            </Button>
          </div>
        );
      }
      return (
        <div style={{ color: ExceptionStatusColorMap[row.status] }}>
          {row.status}
        </div>
      );
    }
    if (exceptionFromSameOrg) {
      const matchingFieldCount = row.matching_fields.split(',').length;
      if (matchingFieldCount === 4 || matchingFieldCount === 3) {
        if (rowMatchesInSameOrg(row.matching_org_id)) {
          return (
            <div style={{ display: 'flex', gap: 15 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isUpdatingUserInException ||
                  isArchivingRemainingExceptions ||
                  userContext.isReadOnly
                }
                onClick={onUpdateInException(
                  row.exception_id,
                  rowItem.user_data,
                  remainingExceptionIds,
                )}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={
                  isUpdatingExceptionStatus ||
                  isArchivingRemainingExceptions ||
                  userContext.isReadOnly
                }
                onClick={onArchiveException(row.exception_id)}
              >
                Archive
              </Button>
            </div>
          );
        }
        if (!rowMatchesInSameOrg(row.matching_org_id)) {
          return (
            <div style={{ display: 'flex', gap: 15, flexDirection: 'column' }}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: 'fit-content' }}
                disabled={
                  isCreatingUserInException ||
                  isArchivingRemainingExceptions ||
                  userContext.isReadOnly ||
                  row.status === ExceptionStatus.PENDING ||
                  row.status === ExceptionStatus.APPROVED
                }
                onClick={onCreateInException(
                  row.exception_id,
                  rowItem.user_data,
                  remainingExceptionIds,
                )}
              >
                Create New
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ width: 'fit-content' }}
                disabled={
                  isUpdatingUserInException ||
                  isArchivingRemainingExceptions ||
                  userContext.isReadOnly ||
                  row.status === ExceptionStatus.PENDING ||
                  row.status === ExceptionStatus.DENIED
                }
                onClick={onUpdateInException(
                  row.exception_id,
                  rowItem.user_data,
                  remainingExceptionIds,
                )}
              >
                Update With Approval
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={
                  isUpdatingExceptionStatus ||
                  isArchivingRemainingExceptions ||
                  userContext.isReadOnly
                }
                style={{ width: 'fit-content' }}
                onClick={onArchiveException(row.exception_id)}
              >
                Archive
              </Button>
            </div>
          );
        }
      }
      if (matchingFieldCount === 1 || matchingFieldCount === 2) {
        return (
          <div style={{ display: 'flex', gap: 15, flexDirection: 'column' }}>
            {maxAttributeMatchCount !== 4 ? (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isCreatingUserInException ||
                  isArchivingRemainingExceptions ||
                  userContext.isReadOnly
                }
                style={{ width: 'fit-content' }}
                onClick={onCreateInException(
                  row.exception_id,
                  rowItem.user_data,
                  remainingExceptionIds,
                )}
              >
                Create New
              </Button>
            ) : null}
            <Tooltip title="This action requires backend intervention">
              <Button
                variant="contained"
                style={{ width: 'fit-content' }}
                disabled={userContext.isReadOnly}
                onClick={onOpenDialog}
                sx={{
                  background: 'gray',
                  '&:hover': { background: '#9a9a9a' },
                }}
              >
                Update
              </Button>
            </Tooltip>
            <Button
              variant="contained"
              color="error"
              disabled={
                isUpdatingExceptionStatus ||
                isArchivingRemainingExceptions ||
                userContext.isReadOnly
              }
              style={{ width: 'fit-content' }}
              onClick={onArchiveException(row.exception_id)}
            >
              Archive
            </Button>
          </div>
        );
      }
    }
    return null;
  };

  const columnsListBodyTemplate = (column: string) => (
    <div style={{ color: 'white' }}>
      <b>{startCase(column)}</b>
    </div>
  );

  const inputDataBodyTemplate = (column: string) => {
    if (column === 'actions') {
      return <>{'-'}</>;
    }
    if (column === 'fields') {
      return (
        <>
          <b>{'Input User Data'}</b>
        </>
      );
    }
    return <>{rowItem.user_data[column]}</>;
  };

  const matchingDataBodyTemplate =
    (item: ExceptionMatchingData, index: number) => (column: string) => {
      if (column === 'actions') {
        return actionsBodyTemplate(item);
      }
      if (column === 'org_id') {
        return <>{item.matching_org_id}</>;
      }
      if (column === 'fields') {
        return (
          <>
            <b>{`Existing User ${
              rowItem.matching_data.length > 1 ? index + 1 : ''
            }`}</b>
          </>
        );
      }
      if (item.matching_fields.includes(column)) {
        return (
          <mark style={{ backgroundColor: 'yellow' }}>{item[column]}</mark>
        );
      }
      return <>{item[column]}</>;
    };

  return (
    <TableWrapper style={{ overflow: 'scroll', border: '1px solid #f1f1f1' }}>
      <DataTable className="table autoHeight" value={COLUMN_KEY_LIST}>
        <Column
          field="columns"
          header=""
          headerStyle={HEADER_DISPLAY_NONE}
          style={{ minWidth: '200px' }}
          bodyStyle={{ background: '#90caf9' }}
          body={columnsListBodyTemplate}
        />
        <Column
          field="inputData"
          header=""
          style={{ minWidth: '200px' }}
          headerStyle={HEADER_DISPLAY_NONE}
          body={inputDataBodyTemplate}
        />
        {sortedMatchingData.map((item, index) => (
          <Column
            key={index}
            field={`matchingData ${index}`}
            header=""
            style={{ minWidth: '300px' }}
            headerStyle={HEADER_DISPLAY_NONE}
            body={matchingDataBodyTemplate(item, index)}
          />
        ))}
      </DataTable>
      <Dialog open={openDialog} onClose={onCloseDialog}>
        <DialogTitle>User Notification</DialogTitle>
        <DialogContent>This action requires backend intervention</DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>Ok</Button>
        </DialogActions>
      </Dialog>
    </TableWrapper>
  );
};

import React, { useContext, useMemo, useState } from 'react';
import { createApiClient } from 'api/apiClient';

import {
  Alert,
  AlertTitle,
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import {
  GetUserListResponse,
  IMEIDetailsResponse,
  UserDataResponse,
} from 'api/SharedTypes';
import { EMPTY_IMEI_RESPONSE_DATA } from 'services/Utils';
import { isArray, sortedUniq } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { FormFieldType } from 'services/Utils';
import { FormFieldObject } from 'components/UserForm';
import SelectField from 'components/UserFormFields/SelectField';
import { UserContext } from 'contexts/userContext';
import { AutomationDialog } from 'components/AutomationDialog/AutomationDialog';

interface DevicePairingProps {
  openDevicePairing: boolean;
  userData: UserDataResponse;
  handleDevicePairing: () => void;
  reloadUserData: (id: number) => void;
  readonly handleRemoteMonitoring?: (
    patientNetwork: GetUserListResponse[],
  ) => void;
  readonly patientNetwork?: GetUserListResponse[];
}

const PAIRING_DATE_FORMAT = 'yyyy-MM-dd hh:mm:ss';
const DevicePairing: React.FC<DevicePairingProps> = ({
  openDevicePairing,
  userData,
  handleDevicePairing,
  reloadUserData,
  handleRemoteMonitoring,
  patientNetwork,
}) => {
  const { setUpdateClick } = useContext<any>(UserContext);
  const intl = useIntl();

  const accessToken = localStorage.getItem('access_token');
  const apiClient = createApiClient(accessToken!, new AbortController());
  const [unpairDate, setUnpairDate] = useState<DateTime>(DateTime.now());
  const [pairDate, setPairDate] = useState<DateTime | null>(DateTime.now());
  const [minPairDate, setMinPairDate] = useState<DateTime | null>(
    DateTime.now(),
  );
  const [unpairDialogOpen, setUnpairDialogOpen] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const [pairedDialog, setPairedDialog] = useState<boolean>(false);
  const [pairedPatient, setPairedPatient] = useState<UserDataResponse | null>(
    null,
  );
  const [value, setValue] = React.useState<IMEIDetailsResponse>(
    EMPTY_IMEI_RESPONSE_DATA,
  );
  const [inputValue, setInputValue] = React.useState<string>('');
  const [unpairedDevices, setUnpairedDevices] = useState<IMEIDetailsResponse[]>(
    [],
  );
  const [isAutomationDialogOpen, setIsAutomationDialogOpen] =
    useState<boolean>(false);

  const { isLoading: isGettingUnpairedDevices, mutate: getUnpairedDevices } =
    useMutation(
      'get-unpaired-devices',
      (value: string) => apiClient.getUnpairedDevices(value),
      {
        onSuccess: data => {
          setUnpairedDevices(data);
        },
      },
    );

  const { isLoading: isUnpairingDevice, mutate: unpairDeviceFromPatient } =
    useMutation(
      'unpair-device-from-patient',
      ({
        imei,
        unpairDate,
        userInternalId,
      }: {
        imei: string;
        unpairDate: string;
        userInternalId: string;
      }) =>
        apiClient.postUnpairPatientsDevice(imei, unpairDate, userInternalId),
      {
        onSuccess: () => {
          handleDevicePairing();
          reloadUserData(userData.id!);
          setUnpairDate(DateTime.now());
        },
        onError: () => {
          handleDevicePairing();
          setUnpairDialogOpen(false);
        },
      },
    );

  const { isLoading: isPairingDevice, mutate: pairDevice } = useMutation(
    'pair-device',
    ({
      imei,
      userInternalId,
      pairDate,
    }: {
      imei: string;
      userInternalId: string;
      pairDate: string;
    }) => apiClient.postPairDevice(imei, userInternalId, pairDate),
    {
      onSuccess: response => {
        reloadUserData(userData.id!);
        handleDevicePairing();
      },
    },
  );

  const { isLoading: isGettingPairedUser, mutate: getPairedUser } = useMutation(
    'get-unpaired-devices',
    (imei: string) => apiClient.getPairedUser(imei),
    {
      onSuccess: response => {
        if (!response) {
          pairDevice({
            imei: value.imei,
            userInternalId: userData.internal_id?.toString() || '',
            pairDate:
              pairDate?.toUTC().toFormat('yyyy-MM-dd HH:mm:ss').toString() ||
              '',
          });
          handleRemoteMonitoring?.(patientNetwork!);
          setTimeout(() => {
            setUpdateClick(true);
          }, 2000);
        } else {
          setPairedDialog(true);
          setPairedPatient(response);
        }
      },
    },
  );

  const {
    isLoading: isLoadingplaceOrder,
    data: placeOrderData,
    refetch: placeOrder,
  } = useQuery(
    'place-orders',
    async () => {
      const data = await apiClient.placeOrder(
        (userData?.internal_id as number).toString(),
      );
      sessionStorage.setItem('ortderType', 'auto');
      setTimeout(() => {
        setUpdateClick(true);
      }, 2000);
      handleRemoteMonitoring?.(patientNetwork!);
      return data;
    },
    {
      onSuccess: (data: any) => {
        handleDevicePairing();
      },
      enabled: false,
    },
  );

  const options = useMemo(() => {
    if (unpairedDevices) {
      if (isArray(unpairedDevices)) {
        return sortedUniq(unpairedDevices);
      }
    }
    return [];
  }, [unpairedDevices]);

  const onIMEIInputChange = (value: string) => {
    if (value.length === 6) {
      getUnpairedDevices(value);
    } else if (value.length === 15) {
      let selectedOption = options.find(item => {
        return item.imei === value;
      });

      if (selectedOption?.end_date === null) {
        setMinPairDate(DateTime.now().minus({ days: 30 }));
      } else {
        setMinPairDate(
          DateTime.fromFormat(selectedOption?.end_date!, PAIRING_DATE_FORMAT),
        );
      }
    }
  };

  const clearForm = () => {
    setOpen(false);
    setValue(EMPTY_IMEI_RESPONSE_DATA);
    setInputValue('');
    setPairDate(DateTime.now());
  };

  const cancelUnpairing = () => {
    setUnpairDate(DateTime.now());
    setUnpairDialogOpen(false);
  };

  const cancelPairing = () => {
    setPairedDialog(false);
    setUnpairedDevices([]);
    clearForm();
  };

  const onClickunpairDevice = () => {
    setUnpairDialogOpen(true);
  };

  const unpairDevice = () => {
    unpairDeviceFromPatient({
      imei: userData.device_pairing!,
      unpairDate:
        unpairDate.toUTC().toFormat('yyyy-MM-dd HH:mm:ss').toString() || '',
      userInternalId: userData.internal_id?.toString() || '',
    });
    setTimeout(() => {
      setUpdateClick(true);
    }, 2000);
  };

  const [type, setType] = useState<string>('Manual');

  const typeOptions: any = [
    {
      label: 'Automated',
      value: 'Automated',
    },
    {
      label: 'Manual',
      value: 'Manual',
    },
  ];

  const devicePairingFields: FormFieldObject = {
    deviceType: {
      type: FormFieldType.select,
      selectOptions: typeOptions,
      isRequired: true,
      value: type,
      onChange: value => {
        setType(value);
      },
      label: intl.formatMessage({
        id: I18nKey.DEVICE_ORDER_TYPE_PAIRING_TITLE,
      }),
      isDisabled: false,
    },
  };

  const callPlaceOrder = () => {
    setIsAutomationDialogOpen(true);
  };

  const onClickPairDevice = () => getPairedUser(value.imei);

  const handleAutomationDialogClose = () => setIsAutomationDialogOpen(false);

  const handleAutomationAccept = () => {
    placeOrder();
    setIsAutomationDialogOpen(false);
  };

  return (
    <Dialog open={openDevicePairing} onClose={handleDevicePairing} fullWidth>
      {isLoadingplaceOrder && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
      <DialogTitle>
        {userData.device_pairing
          ? `Unpair Device ${userData.device_pairing}`
          : intl.formatMessage({ id: I18nKey.DEVICE_PAIRING_TITLE })}
        {!userData.device_pairing &&
          sessionStorage.getItem('deviceAssignment') == 'Both' && (
            <>
              <br />
              <br />
              <SelectField fieldItem={devicePairingFields.deviceType} />
            </>
          )}
      </DialogTitle>
      <>
        {type != 'Automated' && (
          <>
            {userData.device_pairing ? (
              <Grid>
                <LocalizationProvider
                  dateAdapter={AdapterLuxon}
                  adapterLocale="en"
                >
                  <DatePicker
                    disableFuture
                    label={<FormattedMessage id={I18nKey.DEVICE_UNPAIR_DATE} />}
                    inputFormat="MM/dd/yyyy"
                    views={['year', 'month', 'day']}
                    value={unpairDate}
                    minDate={DateTime.fromFormat(
                      userData.start_date!.split(' ')[0],
                      'MM/dd/yyyy',
                    )}
                    maxDate={DateTime.now()}
                    onChange={(newValue: DateTime | null) => {
                      setUnpairDate(newValue!);
                    }}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        sx={{ marginLeft: 3, width: '92%' }}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              <>
                <Autocomplete
                  clearOnEscape
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue!);
                    setMinPairDate(
                      newValue?.end_date
                        ? DateTime.fromFormat(
                            newValue?.end_date!,
                            PAIRING_DATE_FORMAT,
                          )
                        : DateTime.now(),
                    );
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    if (newInputValue !== '' || newInputValue !== null) {
                      onIMEIInputChange(newInputValue);
                    }
                  }}
                  noOptionsText="No Devices Available"
                  getOptionLabel={option => option.imei}
                  options={options}
                  loading={isGettingUnpairedDevices}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      sx={{ marginLeft: 3, width: '92%' }}
                      label={
                        <FormattedMessage id={I18nKey.DEVICE_IMEI_ENTRY} />
                      }
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isGettingUnpairedDevices ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <Grid>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      disableFuture
                      inputFormat="MM/dd/yyyy"
                      views={['year', 'month', 'day']}
                      maxDate={DateTime.now()}
                      minDate={minPairDate}
                      value={pairDate}
                      onChange={newValue => {
                        setPairDate(newValue);
                      }}
                      renderInput={(params: TextFieldProps) => (
                        <TextField
                          sx={{ marginLeft: 3, marginTop: 3, width: '92%' }}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}
          </>
        )}
        {type == 'Automated' && <></>}
      </>
      <br />
      <Dialog open={unpairDialogOpen}>
        <Alert severity="warning">
          <AlertTitle>
            <FormattedMessage id={I18nKey.WARNING} />
          </AlertTitle>
          {`This action will prevent re-pairing of this device until ${unpairDate
            .plus({ days: 1 })
            .toFormat('MM-dd-yyyy')}. Do you want to continue?`}
        </Alert>
        <DialogActions sx={{ backgroundColor: '#FFF4E5' }}>
          <Button onClick={cancelUnpairing}>
            {intl.formatMessage({ id: I18nKey.CANCEL })}
          </Button>
          <Button onClick={unpairDevice}>
            {isUnpairingDevice ? (
              <CircularProgress size={30} />
            ) : (
              intl.formatMessage({ id: I18nKey.OK })
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={pairedDialog}>
        <Alert severity="warning">
          <AlertTitle>
            <FormattedMessage id={I18nKey.WARNING} />
          </AlertTitle>
          {`This device is already paired to ${pairedPatient?.last_name} ${pairedPatient?.first_name}`}
        </Alert>
        <DialogActions sx={{ backgroundColor: '#FFF4E5' }}>
          <Button onClick={cancelPairing}>
            {intl.formatMessage({ id: I18nKey.OK })}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogActions>
        {type == 'Automated' && !userData.device_pairing && (
          <Button
            variant="contained"
            onClick={callPlaceOrder}
            sx={{ marginRight: 1 }}
            color={'primary'}
          >
            {intl.formatMessage({
              id: I18nKey.PLACE_ORDER,
            })}
          </Button>
        )}
        {type != 'Automated' && (
          <Button
            variant="contained"
            disabled={isGettingPairedUser || isPairingDevice}
            onClick={
              userData.device_pairing
                ? onClickunpairDevice
                : value?.imei
                ? onClickPairDevice
                : clearForm
            }
            sx={{ marginRight: 1 }}
            color={userData.device_pairing ? 'error' : 'success'}
          >
            {isGettingPairedUser || isPairingDevice ? (
              <CircularProgress size={30} />
            ) : (
              intl.formatMessage({
                id: userData.device_pairing
                  ? I18nKey.PATIENT_STOP_PAIRING
                  : I18nKey.PATIENT_START_PAIRING,
              })
            )}
          </Button>
        )}
        {type != 'Automated' && !userData.device_pairing && (
          <Button
            variant="contained"
            onClick={clearForm}
            sx={{ marginRight: 1 }}
            color={'primary'}
          >
            {intl.formatMessage({
              id: I18nKey.RESET,
            })}
          </Button>
        )}
        <Button onClick={handleDevicePairing}>
          {intl.formatMessage({ id: I18nKey.CANCEL })}
        </Button>
      </DialogActions>
      <AutomationDialog
        open={isAutomationDialogOpen}
        handleAutomationAccept={handleAutomationAccept}
        handleClose={handleAutomationDialogClose}
        patientUserData={userData}
      />
    </Dialog>
  );
};

export default DevicePairing;

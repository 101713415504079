import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Snackbar,
  TextField,
} from '@mui/material';
import UserForm, { FormFieldObject } from 'components/UserForm';
import { createApiClient } from 'api/apiClient';
import {
  CreateUserResponse,
  GetUserListResponse,
  SetThresholdBody,
  User,
} from 'api/SharedTypes';
import { SubmitFormData, UserRole } from 'services/Utils';
import { ExceptionDialog } from 'components/ExceptionDialog';
import './TabTopButton.css';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { useMutation } from 'react-query';
import { UserContext, UserContextState } from 'contexts/userContext';
import axios from 'axios';
import { error } from 'console';

interface TabTopButtonProps {
  readonly role: UserRole;
  readonly getFormSubmitData: () => SubmitFormData;
  readonly clearForm: () => void;
  readonly formFields: FormFieldObject;
  readonly fetchNewDataList: (loadUpdatedData?: boolean) => void;
  readonly handleRemoteMonitoring?: (
    patientNetwork: GetUserListResponse[],
  ) => void;
  readonly remoteMonitoring?: string;
}

enum bp_status {
  statusOk = 'Threshold stored for the patient',
}

const TabTopButton: React.FC<TabTopButtonProps> = ({
  role,
  getFormSubmitData,
  clearForm,
  formFields,
  fetchNewDataList,
  handleRemoteMonitoring,
  remoteMonitoring,
}) => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const [open, setOpen] = useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<boolean>(false);
  const [isExceptionDialogOpen, setIsExceptionDialogOpen] =
    useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [existingUserData, setExistingUserData] = useState<User>({});

  const accessToken = localStorage.getItem('access_token');
  const apiClient = createApiClient(accessToken!, new AbortController());

  const [highSystolic, setHighSystolic] = useState<string>('');
  const [highDiastolic, setHighDiastolic] = useState<string>('');
  const [lowSystolic, setLowSystolic] = useState<string>('');
  const [lowDiastolic, setLowDiastolic] = useState<string>('');

  const intl = useIntl();
  const { userData } = useContext(UserContext);
  const OrgID = userData?.userOrg;
  const OrgId: string =
    OrgID !== undefined ? OrgID.toString() : 'default value';
  const { isLoading, mutate: createNewUser } = useMutation(
    'create-new-user',
    ({ details, role }: { details: SubmitFormData; role: UserRole }) =>
      apiClient.createNewUser(details, role),
    {
      onSuccess: response => {
        if (response?.detail?.errorCode) {
          setExceptionDialogData(response);
        } else {
          handleSnackBar();
          fetchNewDataList(true);
          handleClose();
        }
      },
    },
  );

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const handleClickOpen = () => setOpen(true);

  const handleSnackBar = () => setSnackBar(true);

  const handleSnackBarClose = () => setSnackBar(false);

  const handleExceptionDialogClose = () => setIsExceptionDialogOpen(false);

  const handleExceptionDialogOpen = () => setIsExceptionDialogOpen(true);

  const setExceptionDialogData = (response: CreateUserResponse) => {
    handleExceptionDialogOpen();
    setErrorCode(response?.detail?.errorCode!);
    setExistingUserData(response?.detail?.existing_data!);
  };

  const getRole = (role: UserRole) => {
    if (role === UserRole.patient || role === UserRole.caregiver) {
      return role;
    }
    return UserRole.provider;
  };

  const handleFormSubmit = async () => {
    const data = { ...getFormSubmitData(), role };
    createNewUser({ details: data, role: getRole(role) });
  };

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClickOpenModal = () => {
    handelGetThresholdData();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const data_body = {
    lower_systolic: parseInt(lowSystolic),
    upper_systolic: parseInt(highSystolic),
    lower_diastolic: parseInt(lowDiastolic),
    upper_diastolic: parseInt(highDiastolic),
  };

  const handleThresholdFormSubmit = async () => {
    try {
      const threshold_data = await apiClient.setThresholdData(data_body);
      if (threshold_data === null) {
        handleCloseModal();
      }
      // Close modal on success
    } catch (error) {
      alert('Please fill all the fields!');
    }
  };

  const handelGetThresholdData = async () => {
    try {
      const retrive_threshold_data = await apiClient.getThresholdData(OrgId);
      // console.log('filtred Data: ', retrive_threshold_data);
      retrive_threshold_data.forEach(
        (data: {
          upper_diastolic: any;
          lower_diastolic: any;
          lower_systolic: any;
          upper_systolic: any;
        }) => {
          // console.log('lower_systolic:', data.lower_systolic);
          // console.log('lower_systolic:', data.upper_systolic);
          // console.log('lower_systolic:', data.lower_diastolic);
          // console.log('lower_systolic:', data.upper_diastolic);
          setLowSystolic(data.lower_systolic.toString());
          setLowDiastolic(data.lower_diastolic.toString());
          setHighSystolic(data.upper_systolic.toString());
          setHighDiastolic(data.upper_diastolic.toString());
        },
      );
    } catch {
      console.log('error');
    }
  };
  useEffect(() => {
    handelGetThresholdData();
  }, []);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <div className="topButtonContainer">
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ backgroundColor: '#90caf9' }}
          disabled={isReadOnly}
        >
          {intl.formatMessage({ id: I18nKey.ADD_NEW })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpenModal}
          sx={{ backgroundColor: '#90caf9', marginLeft: '735px' }}
        >
          Set Threshold
        </Button>
        <div>
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle style={{ height: '70px' }}>Set Threshold</DialogTitle>
            <DialogContent>
              <FormControl component="fieldset">
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div>
                    <FormLabel
                      component="legend"
                      sx={{ paddingLeft: '90px', fontWeight: '600' }}
                    >
                      High
                    </FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        label="Systolic High"
                        margin="normal"
                        variant="outlined"
                        value={highSystolic}
                        onChange={(e: { target: { value: any } }) =>
                          setHighSystolic(e.target.value)
                        }
                      />
                      <TextField
                        label="Diastolic High"
                        margin="normal"
                        variant="outlined"
                        value={highDiastolic}
                        onChange={e => setHighDiastolic(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <FormLabel
                      component="legend"
                      sx={{ paddingLeft: '90px', fontWeight: '600' }}
                    >
                      Low
                    </FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        label="Systolic Low"
                        margin="normal"
                        variant="outlined"
                        value={lowSystolic}
                        onChange={e => setLowSystolic(e.target.value)}
                      />
                      <TextField
                        label="Diastolic Low"
                        margin="normal"
                        variant="outlined"
                        value={lowDiastolic}
                        onChange={e => setLowDiastolic(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleThresholdFormSubmit}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Snackbar
          open={snackBar}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handleSnackBarClose}
          message={`${role.toUpperCase()} added successfully!!!`}
        />
        <UserForm
          open={open}
          handleClose={handleClose}
          handleFormSubmit={handleFormSubmit}
          formFields={formFields}
          role={role}
          isLoading={isLoading}
          handleRemoteMonitoring={handleRemoteMonitoring}
          remoteMonitoring={remoteMonitoring}
        />

        <ExceptionDialog
          open={isExceptionDialogOpen}
          handleClose={handleExceptionDialogClose}
          errorCode={errorCode}
          handleAddUserDialogClose={handleClose}
          enteredFormData={getFormSubmitData()}
          existingUserData={existingUserData}
          role={role}
          fetchNewDataList={fetchNewDataList}
        />
      </div>
    </div>
  );
};

export default TabTopButton;

import includes from 'lodash/includes';
import values from 'lodash/values';

enum I18nKey {
  NAVBAR_LABEL_PATIENTS = 'react.components.navbar.patients',
  NAVBAR_LABEL_PHYSICIANS = 'react.components.navbar.physicians',
  NAVBAR_LABEL_NURSES = 'react.components.navbar.nurses',
  NAVBAR_LABEL_CAREGIVERS = 'react.components.navbar.caregivers',
  NAVBAR_LABEL_CASEMANAGERS = 'react.components.navbar.casemanagers',
  NAVBAR_LABEL_ARCHIVED = 'react.components.navbar.archived',
  NAVBAR_LABEL_DIETICIAN = 'react.components.navbar.dietician',
  NAVBAR_LABEL_SOCIAL_WORKERS = 'react.components.navbar.socialworkers',
  NAVBAR_LABEL_OTHERS = 'react.components.navbar.others',

  USER_FORM_FIELD_NAME = 'react.user.form.field.name',
  USER_FORM_FIELD_USERNAME = 'react.user.form.field.username',
  USER_FORM_FIELD_PASSWORD = 'react.user.form.field.password',
  USER_FORM_FIELD_NEW_PASSWORD = 'react.user.form.field.newPassword',
  USER_FORM_FIELD_CONFIRM_PASSWORD = 'react.user.form.field.confirmPassword',
  USER_FORM_FIELD_FIRSTNAME = 'react.user.form.field.firstname',
  USER_FORM_FIELD_MIDDLENAME = 'react.user.form.field.middlename',
  USER_FORM_FIELD_LASTNAME = 'react.user.form.field.lastname',
  USER_FORM_FIELD_EMAIL = 'react.user.form.field.email',
  USER_FORM_FIELD_VERIFY_EMAIL = 'react.user.form.field.verifyEmail',
  USER_FORM_FIELD_VERIFY_EMAIL_TOOLTIP = 'react.user.form.field.verifyEmailTooltip',
  USER_FORM_FIELD_DATE_OF_BIRTH = 'react.user.form.field.dateofbirth',
  USER_FORM_FIELD_CELL_COUNTRY_CODE = 'react.user.form.field.cellcountrycode',
  USER_FORM_FIELD_TELEPHONE_COUNTRY_CODE = 'react.user.form.field.telephonecountrycode',
  USER_FORM_FIELD_GENDER = 'react.user.form.field.gender',
  USER_FORM_FIELD_SEX = 'react.user.form.field.sex',
  USER_FORM_FIELD_TELEPHONE = 'react.user.form.field.telephone',
  USER_FORM_FIELD_WORK_PHONE = 'react.user.form.field.workTel',
  USER_FORM_FIELD_MOBILE_NUMBER = 'react.user.form.field.mobile_number',
  USER_FORM_FIELD_CELL = 'react.user.form.field.cell',
  USER_FORM_FIELD_HOME_ADDRESS_1 = 'react.user.form.field.home_address_1',
  USER_FORM_FIELD_HOME_ADDRESS_2 = 'react.user.form.field.home_address_2',
  USER_FORM_FIELD_OFFICE_ADDRESS_1 = 'react.user.form.field.office_address_1',
  USER_FORM_FIELD_OFFICE_ADDRESS_2 = 'react.user.form.field.office_address_2',
  USER_FORM_FIELD_WORK_ADDRESS_1 = 'react.user.form.field.work_addr_1',
  USER_FORM_FIELD_WORK_ADDRESS_2 = 'react.user.form.field.work_addr_2',
  USER_FORM_FIELD_WORK_ADDRESS_CITY = 'react.user.form.field.work_address_city',
  USER_FORM_FIELD_ADDRESS_CITY = 'react.user.form.field.address_city',
  USER_FORM_FIELD_STATE = 'react.user.form.field.state',
  USER_FORM_FIELD_ZIP_CODE = 'react.user.form.field.zipcode',
  USER_FORM_FIELD_SSN = 'react.user.form.field.ssn',
  USER_FORM_FIELD_LICENSE_NUMBER = 'react.user.form.field.license_number',
  USER_FORM_FIELD_DEVICE_PAIRED = 'react.user.form.field.device_paired',
  USER_FORM_FIELD_REF_UID = 'react.user.form.field.ref_uid',
  USER_FORM_FIELD_SPECIALITY = 'react.user.form.field.speciality',
  USER_FORM_FIELD_GROUP_NAME = 'react.user.form.field.group_name',
  USER_FORM_FIELD_NURSING_LICENSE_NUMBER = 'react.user.form.field.nursing_license_number',
  USER_FORM_FIELD_GRADUATION_YEAR = 'react.user.form.field.graduation_year',
  USER_FORM_FIELD_DEA_NUMBER = 'react.user.form.field.dea_number',
  USER_FORM_FIELD_NPI = 'react.user.form.field.npi',
  USER_FORM_FIELD_DEGREE = 'react.user.form.field.degree',
  USER_FORM_FIELD_PHONE_NUMBER_1 = 'react.user.form.field.phone1',
  USER_FORM_FIELD_PHONE_NUMBER_2 = 'react.user.form.field.phone2',
  USER_FORM_FIELD_ADDRESS = 'react.user.form.field.address',
  USER_FORM_FIELD_ADMIN_READ_ONLY = 'react.user.form.field.adminReadOnly',
  USER_FORM_FIELD_START_DATE = 'react.user.form.field.start_date',
  USER_FORM_FIELD_END_DATE = 'react.user.form.field.end_date',
  USER_FORM_FIELD_VBC = 'react.user.form.field.vbc',

  USER_NETWORK_ACTION_REMOVE = 'react.user.network.action.remove',
  USER_NETWORK_ACTION_ACTIVATE_ALERTS = 'react.user.network.action.activateAlerts',
  USER_NETWORK_ACTION_DEACTIVATE_ALERTS = 'react.user.network.action.deactivateAlerts',
  PATIENT_DIAGNOSIS_SELECT_LABEL = 'react.patient.diagnosis.select.label',
  PATIENT_DIAGNOSIS_ADD_LABEL = 'react.patient.diagnosis.add.label',
  PATIENT_DIAGNOSIS_REMOVE_LABEL = 'react.patient.diagnosis.remove.label',
  PATIENT_START_PAIRING = 'react.patient.imei.start_pairing',
  PATIENT_STOP_PAIRING = 'react.patient.imei.stop_pairing',

  DEVICE_UNPAIR_DATE = 'react.patient.device_unpair_date',
  DEVICE_IMEI_ENTRY = 'react.patient.device_imei_list',
  DEVICE_PAIRING_TITLE = 'react.patient.device_pairing_title',
  DEVICE_ORDER_TYPE_PAIRING_TITLE = 'react.patient.device_order_type_title',
  PLACE_ORDER = 'react.patient.place_order',

  EXCEPTIONS_ACTION_RESOLVE_LABEL = 'react.exception.action.resolve.label',
  EXCEPTIONS_ACTION_EDIT_LABEL = 'react.exception.action.edit.label',
  EXCEPTIONS_ACTION_REMOVE_LABEL = 'react.exception.action.remove.label',
  NO_DATA_CALL_RECORDS = 'nodata.call_records',
  NO_DATA_CALL_LOGS = 'nodata.call_logs',
  ERROR_MESSAGE = 'react.error',
  INFO = 'info',
  WARNING = 'warning',
  OK = 'ok',
  SEND_INVITATION = 'sendInvitation',

  EXCEPTION_DIALOG_BUTTON_ADD_NEW = 'react.exceptionDialog.button.addNew',
  EXCEPTION_DIALOG_BUTTON_UPDATE_EXISTING = 'react.exceptionDialog.button.updateExisting',
  EXCEPTION_DIALOG_BUTTON_CREATE_NEW = 'react.exceptionDialog.button.createNew',
  EXCEPTION_DIALOG_BUTTON_ADD_TO_NEW_ORG = 'react.exceptionDialog.button.addToNewOrg',
  EXCEPTION_DIALOG_ACTION_NOT_ALLOWED = 'react.exceptionDialog.content.actionNotAllowed',
  EXCEPTION_DIALOG_LABEL_EXISTING = 'react.exceptionDialog.label.existing',
  EXCEPTION_DIALOG_LABEL_FIELD = 'react.exceptionDialog.label.field',
  EXCEPTION_DIALOG_LABEL_ENTERED = 'react.exceptionDialog.label.entered',
  EXCEPTION_DIALOG_LABEL_FIRST_NAME = 'react.exceptionDialog.label.firstName',
  EXCEPTION_DIALOG_LABEL_LAST_NAME = 'react.exceptionDialog.label.lastName',
  EXCEPTION_DIALOG_LABEL_DOB = 'react.exceptionDialog.label.dob',
  EXCEPTION_DIALOG_LABEL_SSN = 'react.exceptionDialog.label.ssn',
  EXCEPTION_DIALOG_LABEL_MOBILE_NUMBER = 'react.exceptionDialog.label.cell',
  EXCEPTION_DIALOG_LABEL_EMAIL = 'react.exceptionDialog.label.email',

  EXCEPTION_DIALOG_EMAIL_EXISTS_TITLE = 'react.exceptionDialog.emailExists.title',
  EXCEPTION_DIALOG_PATIENT_EMAIL_EXISTS = 'react.exceptionDialog.patient.emailExists',
  EXCEPTION_DIALOG_NURSE_EMAIL_EXISTS = 'react.exceptionDialog.nurse.emailExists',
  EXCEPTION_DIALOG_PHYSICIAN_EMAIL_EXISTS = 'react.exceptionDialog.physician.emailExists',
  EXCEPTION_DIALOG_CASE_MANAGER_EMAIL_EXISTS = 'react.exceptionDialog.caseManager.emailExists',
  EXCEPTION_DIALOG_CAREGIVER_EMAIL_EXISTS = 'react.exceptionDialog.caregiver.emailExists',

  EXCEPTION_DIALOG_EXISTING_PATIENT_FOUND_TITLE = 'react.exceptionDialog.existing.patient.found',
  EXCEPTION_DIALOG_EXISTING_CAREGIVER_FOUND_TITLE = 'react.exceptionDialog.existing.caregiver.found',
  EXCEPTION_DIALOG_EXISTING_PHYSICIAN_FOUND_TITLE = 'react.exceptionDialog.existing.physician.found',
  EXCEPTION_DIALOG_EXISTING_NURSE_FOUND_TITLE = 'react.exceptionDialog.existing.nurse.found',
  EXCEPTION_DIALOG_EXISTING_CASE_MANAGER_FOUND_TITLE = 'react.exceptionDialog.existing.caseManager.found',

  EXCEPTION_DIALOG_PATIENT_FN_LN_AND_DOB_OR_SSN_FOUND = 'react.exceptionDialog.patient.firstNameLastNameAndDOBOrSSN.found',
  EXCEPTION_DIALOG_CAREGIVER_FN_LN_AND_DOB_OR_SSN_FOUND = 'react.exceptionDialog.caregiver.firstNameLastNameAndDOBOrSSN.found',
  EXCEPTION_DIALOG_PHYSICIAN_FN_LN_AND_DOB_OR_SSN_FOUND = 'react.exceptionDialog.physician.firstNameLastNameAndDOBOrSSN.found',
  EXCEPTION_DIALOG_NURSE_FN_LN_AND_DOB_OR_SSN_FOUND = 'react.exceptionDialog.nurse.firstNameLastNameAndDOBOrSSN.found',
  EXCEPTION_DIALOG_CASE_MANAGER_FN_LN_AND_DOB_OR_SSN_FOUND = 'react.exceptionDialog.caseManager.firstNameLastNameAndDOBOrSSN.found',

  EXCEPTION_DIALOG_PATIENT_DOB_AND_SSN_FOUND = 'react.exceptionDialog.patient.DOBAndSSN.found',
  EXCEPTION_DIALOG_CAREGIVER_DOB_AND_SSN_FOUND = 'react.exceptionDialog.caregiver.DOBAndSSN.found',
  EXCEPTION_DIALOG_PHYSICIAN_DOB_AND_SSN_FOUND = 'react.exceptionDialog.physician.DOBAndSSN.found',
  EXCEPTION_DIALOG_NURSE_DOB_AND_SSN_FOUND = 'react.exceptionDialog.nurse.DOBAndSSN.found',
  EXCEPTION_DIALOG_CASEMANAGER_DOB_AND_SSN_FOUND = 'react.exceptionDialog.caseManager.DOBAndSSN.found',

  EXCEPTION_DIALOG_PATIENT_FN_LN_AND_DOB_ORGANISATION_FOUND = 'react.exceptionDialog.patient.firstNameLastNameAndDOB.org.found',
  EXCEPTION_DIALOG_CAREGIVER_FN_LN_AND_DOB_ORGANISATION_FOUND = 'react.exceptionDialog.caregiver.firstNameLastNameAndDOB.org.found',
  EXCEPTION_DIALOG_PHYSICIAN_FN_LN_AND_DOB_ORGANISATION_FOUND = 'react.exceptionDialog.physician.firstNameLastNameAndDOB.org.found',
  EXCEPTION_DIALOG_NURSE_FN_LN_AND_DOB_ORGANISATION_FOUND = 'react.exceptionDialog.nurse.firstNameLastNameAndDOB.org.found',
  EXCEPTION_DIALOG_CASE_MANAGER_FN_LN_AND_DOB_ORGANISATION_FOUND = 'react.exceptionDialog.caseManager.firstNameLastNameAndDOB.org.found',

  EXCEPTION_DIALOG_PATIENT_DOB_AND_SSN_ORGANISATION_FOUND = 'react.exceptionDialog.patient.DOBandSSN.org.found',
  EXCEPTION_DIALOG_CAREGIVER_DOB_AND_SSN_ORGANISATION_FOUND = 'react.exceptionDialog.caregiver.DOBandSSN.org.found',
  EXCEPTION_DIALOG_PHYSICIAN_DOB_AND_SSN_ORGANISATION_FOUND = 'react.exceptionDialog.physician.DOBandSSN.org.found',
  EXCEPTION_DIALOG_NURSE_DOB_AND_SSN_ORGANISATION_FOUND = 'react.exceptionDialog.nurse.DOBandSSN.org.found',
  EXCEPTION_DIALOG_CASE_MANAGER_DOB_AND_SSN_ORGANISATION_FOUND = 'react.exceptionDialog.caseManager.DOBandSSN.org.found',

  EXCEPTION_DIALOG_PATIENT_DOB_AND_EMAIL_ORGANISATION_FOUND = 'react.exceptionDialog.patient.DOBandEmail.org.found',
  EXCEPTION_DIALOG_CAREGIVER_DOB_AND_EMAIL_ORGANISATION_FOUND = 'react.exceptionDialog.caregiver.DOBandEmail.org.found',
  EXCEPTION_DIALOG_PHYSICIAN_DOB_AND_EMAIL_ORGANISATION_FOUND = 'react.exceptionDialog.physician.DOBandEmail.org.found',
  EXCEPTION_DIALOG_NURSE_DOB_AND_EMAIL_ORGANISATION_FOUND = 'react.exceptionDialog.nurse.DOBandEmail.org.found',
  EXCEPTION_DIALOG_CASE_MANAGER_DOB_AND_EMAIL_ORGANISATION_FOUND = 'react.exceptionDialog.caseManager.DOBandEmail.org.found',

  EXCEPTION_DIALOG_PATIENT_ADDTOORG = 'react.exceptionDialog.patient.addtoOrg',
  EXCEPTION_DIALOG_CAREGIVER_ADDTOORG = 'react.exceptionDialog.caregiver.addtoOrg',
  EXCEPTION_DIALOG_PHYSICIAN_ADDTOORG = 'react.exceptionDialog.physician.addtoOrg',
  EXCEPTION_DIALOG_NURSE_ADDTOORG = 'react.exceptionDialog.nurse.addtoOrg',
  EXCEPTION_DIALOG_CASE_MANAGER_ADDTOORG = 'react.exceptionDialog.caseManager.addtoOrg',

  EXCEPTION_DIALOG_ADD_TO_NEW_ORG_NOTE = 'react.exceptionDialog.addToNewOrg.note',

  EXCEPTION_DIALOG_PATIENT_FN_LN_AND_DOB_FOUND = 'react.exceptionDialog.patient.firstNameLastNameAndDOB.found',
  EXCEPTION_DIALOG_CAREGIVER_FN_LN_AND_DOB_FOUND = 'react.exceptionDialog.caregiver.firstNameLastNameAndDOB.found',
  EXCEPTION_DIALOG_PHYSICIAN_FN_LN_AND_DOB_FOUND = 'react.exceptionDialog.physician.firstNameLastNameAndDOB.found',
  EXCEPTION_DIALOG_NURSE_FN_LN_AND_DOB_FOUND = 'react.exceptionDialog.nurse.firstNameLastNameAndDOB.found',
  EXCEPTION_DIALOG_CASE_MANAGER_FN_LN_AND_DOB_FOUND = 'react.exceptionDialog.caseManager.firstNameLastNameAndDOB.found',
  EXCEPTION_DIALOG_EMAIL_ID_NOT_VALID = 'react.exceptionDialog.mail.validation',

  SNACKBAR_PATIENT_UPDATE_SUCCESSFUL = 'react.snackbar.patient.update.successful',
  SNACKBAR_PATIENT_UPDATE_FAILED = 'react.snackbar.patient.update.failed',

  SNACKBAR_CAREGIVER_UPDATE_SUCCESSFUL = 'react.snackbar.caregiver.update.successful',
  SNACKBAR_CAREGIVER_UPDATE_FAILED = 'react.snackbar.caregiver.update.failed',

  SNACKBAR_PROVIDER_UPDATE_SUCCESSFUL = 'react.snackbar.provider.update.successful',
  SNACKBAR_PROVIDER_UPDATE_FAILED = 'react.snackbar.provider.update.failed',

  SNACKBAR_PATIENT_ADDITION_SUCCESSFUL = 'react.snackbar.patient.addition.successful',
  SNACKBAR_PATIENT_ADDITION_FAILED = 'react.snackbar.patient.addition.failed',

  SNACKBAR_ORG_PATIENT_ADDITION_SUCCESSFUL = 'react.snackbar.organisation.patient.addition.successful',
  SNACKBAR_ORG_PATIENT_ADDITION_FAILED = 'react.snackbar.organisation.patient.addition.failed',

  MESSAGES_LOG_CHAT_ID_LABEL = 'react.messagesLog.chatId.label',
  MESSAGES_LOG_PRIVATE_CHAT = 'react.messagesLog.privateChat.label',
  MESSAGES_LOG_RELATED_TO_PATIENT = 'react.messagesLog.relatedToPatient.label',
  MESSAGES_LOG_PARTICIPANTS_LABEL = 'react.messagesLog.participants.label',
  MESSAGES_LOG_NAME_LABEL = 'react.messagesLog.name.label',
  MESSAGES_LOG_MESSAGES_LABEL = 'react.messagesLog.messages.label',
  MESSAGES_LOG_ERROR = 'react.messagesLog.error',
  MESSAGES_LOG_NO_MESSAGES = 'react.messagesLog.noMessages',

  CHANGES_LOG_UPDATED_BY = 'react.changesLog.title.updated_by',
  CHANGES_LOG_ORGANIZATION = 'react.changesLog.title.organization',
  CHANGES_LOG_DATE = 'react.changesLog.title.date',
  CHANGES_LOG_LOG_ID = 'react.changesLog.title.log_id',
  CHANGES_LOG_HEADER_ATTRIBUTES = 'react.changesLog.header.attributes',
  CHANGES_LOG_HEADER_OLD_STATE = 'react.changesLog.header.old_state',
  CHANGES_LOG_HEADER_NEW_STATE = 'react.changesLog.header.new_state',
  CHANGES_LOG_ERROR_NO_CHANGES = 'react.changesLog.no_changes',

  ARCHIVED_USER_CONFIRMATION_TITLE = 'react.archived.confirmation.title',
  ARCHIVED_USER_CONFIRMATION_CONTENT = 'react.archived.confirmation.content',

  FORM_OPEN_FORM_DIALOG = 'react.form.openFormDialog',
  FORM_SUBSCRIBE = 'react.form.subscribe',
  FORM_DIALOG_CONTENT = 'react.form.formDialog.content',

  NETWORK_INFO_MEMBERS_TITLE = 'react.networkInfo.members.title',
  NETWORK_INFO_OPTIONS = 'react.networkInfo.options',

  ARCHIVE_USER = 'archiveUser',
  ARCHIVE_USER_CONFIRM_DIALOG_TITLE = 'archiveUser.confirm.dialog.title',
  ARCHIVE_USER_CONFIRM_DIALOG_CONTENT = 'archiveUser.confirm.dialog.content',

  ALERT_RECEIVER_DIALOG_TITLE = 'alertReceiver.change.dialog.title',
  ALERT_RECEIVER_DIALOG_CONTENT = 'alertReceiver.change.dialog.content',

  UPDATE_DIALOG_NETWORK_NO_MEMBERS_LABEL = 'updateDialog.network.noMembers.label',

  RESET_PASSWORD_SUCCESS = 'reset.password.success.message',
  RESET_PASSWORD = 'reset.password',

  WRONG_USER_DETAILS_ERROR = 'login.wrongDetails',
  ACCESS_DENIED = 'login.accessDenied',
  PASSWORDS_DONT_MATCH = 'login.password.not.match',
  WELCOME_TEXT = 'login.welcomeText',
  CHANGE_PASSWORD_TEXT = 'login.changePassword.text',

  CHANGING_PASSWORD_DISCLAIMER_MESSAGE = 'change.password.disclaimer.message',

  REMOTE_MONITORING_SELECT_PROVIDERS = 'remote.monitoring.select.provider',
  REMOTE_MONITORING_SELECT_PROVIDERS_DATE_RANGE = 'remote.monitoring.select.provider.dateRange',
  REMOTE_MONITORING_NO_REPORTS = 'remote.monitoring.report.no.reports',
  REMOTE_MONITORING_DAYS_OF_RECORDING = 'remote.monitoring.days.of.recording',
  REMOTE_MONITORING_MINUTES_OF_TALKTIME = 'remote.monitoring.minutes.of.talktime',
  REMOTE_MONITORING_ZERO_MINUTES_TALKTIME = 'remote.monitoring.zero.minutes',
  REMOTE_MONITORING_DEVICE_ID = 'remote.monitoring.device.id',

  SUPER_ADMIN_LOGIN_TITLE = 'superAdmin.login.title',
  SUPER_ADMIN_LOGIN_BUTTON_LABEL = 'superAdmin.login.button.label',

  ORG_PORTAL_VERSION_LABEL = 'org.portal.version.label',
  ADMIN_PORTAL_VERSION_LABEL = 'admin.portal.version.label',

  RESTORE_USERS = 'restoreUsers',
  DATE = 'date',
  PROVIDER = 'provider',
  DURATION_MIN = 'durationMin',
  TYPE = 'type',
  TOTAL_CALL_TIME = 'totalCallTime',
  ENTERED_BY = 'enteredBy',
  DURATION = 'duration',
  ITEMS_DISCUSSED = 'itemsDiscussed',
  PRINT = 'print',

  ADD_ORGANIZATION = 'addOrg',
  ADD_CUST_ADMIN = 'custAdmin.add',
  UPDATE_CUST_ADMIN = 'custAdmin.update',

  ORG_UPDATE_SUCESSFUL = 'superAdmin.org.update.successful.message',
  ORG_UPDATE_FAILED = 'superAdmin.org.update.failed.message',
  ORG_ADDITION_SUCESSFUL = 'superAdmin.org.addition.successful.message',
  ORG_ADDITION_FAILED = 'superAdmin.org.addition.failed.message',

  CUST_ADMIN_TITLE_TEXT = 'superAdmin.custAdmin.title',

  CUST_ADMIN_DELETE_CONFIRMATION_TITLE = 'custAdmin.delete.confirmation.title',
  CUST_ADMIN_DELETE_CONFIRMATION_CONTENT = 'custAdmin.delete.confirmation.content',

  CUST_ADMIN_ADDITION_SUCCESSFUL = 'custAdmin.addition.successful.message',
  CUST_ADMIN_ADDITION_FAILED = 'custAdmin.addition.failed.message',

  CUST_ADMIN_DELETION_SUCCESSFUL = 'custAdmin.delete.successful.message',
  CUST_ADMIN_DELETION_FAILED = 'custAdmin.delete.failed.message',

  CUST_ADMIN_UPDATE_SUCCESSFUL = 'custAdmin.update.successful.message',
  CUST_ADMIN_UPDATE_FAILED = 'custAdmin.update.failed.message',

  SUPER_ADMIN_ACCESS_DENIED = 'superAdmin.accessDenied',
  SUPER_ADMIN_WRONG_DETAILS = 'superAdmin.wrong.details',
  SUPER_ADMIN_PASSWORDS_DONT_MATCH = 'superAdmin.password.not.matched',
  BILLING_LOGS_TITLE = 'react.billingLog.header.title',
  DATE_OF_SERVICE = 'react.billingLog.column.date_of_service',
  DESCRIPTION = 'react.billingLog.column.description',
  CODE = 'react.billingLog.column.code',
  PATIENT = 'react.billingLog.column.patient',
  PROVIDERS = 'react.billingLog.column.provider',
  AGREE = 'agree',
  ADD_NEW = 'addNew',
  SAVE = 'save',
  CREATE = 'create',
  LOGS = 'logs',
  SIGN_IN = 'signin',
  SUBMIT = 'submit',
  LOGOUT = 'logout',
  UPDATE = 'update',
  CANCEL = 'cancel',
  RESET = 'reset',
  CONFIRM = 'confirm',
  SEND = 'send',
}

export const isI18Key = (value: I18nKey | string): value is I18nKey =>
  includes(values(I18nKey), value);

export default I18nKey;

import { Button } from '@mui/material';
import { IntlShape } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import './ExceptionDialog.css';

interface DialogTitleType {
  readonly [code: string]: string;
}

interface DialogActionType {
  readonly [code: string]: JSX.Element;
}

export const ExceptionDialogTitle = (): DialogTitleType => ({
  '102': 'Exception Created',
});

export const ExceptionDialogContent = (): DialogActionType => ({
  '102': (
    <>
      <div>This action has created an Exception </div>
      <div>
        Please check the Exceptions screen for further operations on this user
      </div>
    </>
  ),
});

export const ExceptionDialogAction = (
  handleClose: () => void,
  intl: IntlShape,
  handleAddUserDialogClose: () => void,
): DialogActionType => ({
  '102': (
    <Button
      onClick={() => {
        handleClose();
        handleAddUserDialogClose();
      }}
    >
      {intl.formatMessage({ id: I18nKey.OK })}
    </Button>
  ),
});

import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
interface PdfViewerProps {
  fileUrl: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ fileUrl }): JSX.Element => {
  //   const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [pageNo, setPageNo] = useState<number>(0);

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
        <Viewer
          fileUrl={fileUrl}
          //   plugins={[defaultLayoutPluginInstance]}
          initialPage={pageNo}
          onPageChange={() => setPageNo(pageNo + 1)}
        />
      </Worker>
    </>
  );
};

export default PdfViewer;

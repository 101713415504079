import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import isArray from 'lodash/isArray';
import { createApiClient } from 'api/apiClient';
import { GetUserListResponse } from 'api/SharedTypes';
import './Archived.css';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import ArchivedList from './ArchivedList';
import './Archived.css';
import { sortByName } from 'services/utilsFunctions';
import { useMutation } from 'react-query';

const Archived: React.FC = () => {
  const [isConfirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [retrievableUser, setRetrievableUser] = useState<GetUserListResponse>();

  const intl = useIntl();

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const {
    isLoading,
    mutate: getArchivedList,
    isError,
    data: archivedListResp,
  } = useMutation(
    'archived-list',
    async (loadUpdatedData?: boolean) =>
      apiClient.getArchivedList(loadUpdatedData),
    {
      onError: error => {
        console.log('error ', error);
      },
    },
  );

  const { isLoading: isRetrivingArchivedUser, mutate: retriveArchivedUser } =
    useMutation(
      'retrive-archived-user',
      async (params: { id: number; role: string }) =>
        apiClient.updateArchiveList(params.id, params.role),
      {
        onSuccess: () => {
          fetchArchived(true);
          setConfirmDialog(false);
        },
        onError: () => setConfirmDialog(false),
      },
    );

  const archived = useMemo(() => {
    if (archivedListResp) {
      if (isArray(archivedListResp)) {
        return sortByName(archivedListResp) as GetUserListResponse[];
      }
    }
    return [];
  }, [archivedListResp]);

  const fetchArchived = (loadUpdatedData?: boolean) =>
    getArchivedList(loadUpdatedData);

  const retrieved = (item: GetUserListResponse) => {
    setConfirmDialog(true);
    setRetrievableUser(item);
  };

  const cancelRetrieve = () => {
    setConfirmDialog(false);
  };

  const confirmRetrieve = () => {
    if (retrievableUser) {
      retriveArchivedUser({
        id: retrievableUser.id,
        role: retrievableUser.role,
      });
    }
  };

  useEffect(() => {
    fetchArchived();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className="archivedContent">
      {isLoading ? (
        <div className="archivedListCenterLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="archivedListCenterLoading">
          <div className="red">Failed to Fetch Archived List</div>
        </div>
      ) : (
        <>
          <ArchivedList users={archived} onUserRestore={retrieved} />
          <Dialog open={isConfirmDialog} onClose={cancelRetrieve} fullWidth>
            <DialogTitle>
              {intl.formatMessage({
                id: I18nKey.ARCHIVED_USER_CONFIRMATION_TITLE,
              })}
            </DialogTitle>
            <div className="confirmationContent">
              {intl.formatMessage({
                id: I18nKey.ARCHIVED_USER_CONFIRMATION_CONTENT,
              })}
            </div>
            <DialogActions>
              <Button onClick={cancelRetrieve}>
                {intl.formatMessage({ id: I18nKey.CANCEL })}
              </Button>
              <Button
                onClick={confirmRetrieve}
                disabled={isRetrivingArchivedUser}
              >
                {isRetrivingArchivedUser ? (
                  <CircularProgress size={30} />
                ) : (
                  intl.formatMessage({ id: I18nKey.CONFIRM })
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default Archived;

import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { MessageType } from './index';
import { ChatData, MessageData } from 'api/SharedTypes';
import DoneIcon from '@mui/icons-material/Done';
import { useIntl } from 'react-intl';
import 'pages/MessagesLog/MessagesLog.css';
import I18nKey from 'lib/I18nKeys';
import { createApiClient } from 'api/apiClient';
import isEmpty from 'lodash/isEmpty';
import { CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';

interface ChatTableProps {
  readonly chatData: ChatData;
  readonly patientName: string;
}

const CHAT_TABLE_TIME_FORMAT = 'MM-dd-yyyy, hh:mm:ss a';

const ChatTable: React.FC<ChatTableProps> = ({ chatData, patientName }) => {
  const intl = useIntl();
  const [messages, setChatMessages] = useState<MessageData[]>(
    chatData.messages,
  );

  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const { isLoading } = useQuery(
    ['get-chat-messages', chatData.id],
    () => apiClient.getChats(chatData.id, '', 100),
    {
      enabled: chatData ? true : false,
      onSuccess: response => {
        setChatMessages(response);
      },
    },
  );

  return (
    <table className="tableContainer">
      <tbody>
        <tr>
          <td>{`${intl.formatMessage({
            id: I18nKey.MESSAGES_LOG_CHAT_ID_LABEL,
          })} ${chatData.id}`}</td>
          <td>
            {chatData.type === MessageType.private
              ? intl.formatMessage({ id: I18nKey.MESSAGES_LOG_PRIVATE_CHAT })
              : `${intl.formatMessage({
                  id: I18nKey.MESSAGES_LOG_RELATED_TO_PATIENT,
                })} ${patientName}`}
          </td>
          <td />
        </tr>
        <tr>
          <td>
            {intl.formatMessage({
              id: I18nKey.MESSAGES_LOG_PARTICIPANTS_LABEL,
            })}
          </td>

          {chatData.participants.map(participant => (
            <td>
              {intl.formatMessage({
                id: I18nKey.MESSAGES_LOG_NAME_LABEL,
              })}
              <b>
                {' '}
                {participant.name}
                {' - '}
                {participant.role}
              </b>
            </td>
          ))}
        </tr>
        <tr className="centerText">
          <td colSpan={1} className="no-border">
            {intl.formatMessage({ id: I18nKey.MESSAGES_LOG_MESSAGES_LABEL })}
          </td>
          <td className="no-border"></td>
          <td className="no-border"></td>
        </tr>

        {isLoading ? (
          <tr>
            <td colSpan={3}>
              <CircularProgress className="centerLoading" />
            </td>
          </tr>
        ) : isEmpty(messages) ? (
          <tr>
            <td colSpan={3} className="textCenterAlign">
              No Messages Found
            </td>
          </tr>
        ) : (
          messages?.map((message: MessageData) => {
            const sender = chatData.participants.find(
              participant =>
                participant.internal_id === Number(message.sender_id),
            );
            return (
              <tr>
                <td key={message?.sender_id || message?.content}>
                  {`${sender?.name} at ${DateTime.fromMillis(
                    Number(message?.timestamp),
                  ).toFormat(CHAT_TABLE_TIME_FORMAT)}`}
                  {message.read && <DoneIcon sx={{ color: 'green' }} />}:
                </td>
                <td>{message.content}</td>
                <td />
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};
export default ChatTable;

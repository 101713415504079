import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import enMessages from './translations/en.json';
import { UserProvider } from './contexts/userContext';

import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      staleTime: 0,
    },
  },
});

ReactDOM.render(
  <IntlProvider locale="en" defaultLocale="en" messages={enMessages}>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </UserProvider>
  </IntlProvider>,
  document.getElementById('root'),
);
